import { useQuery } from "@apollo/client";
import { GET_BOARDS } from "../../../lib/apollo/gql/board";

function useBoardTypeSelector() {
  const { data, loading } = useQuery(GET_BOARDS, { variables: { maxPage: 100, page: 1 } });
  return {
    models: {
      loading,
      data: data?.getAllBoards,
    },
  };
}

export default useBoardTypeSelector;
