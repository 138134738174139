import { useMutation, useQuery } from "@apollo/client";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useState } from "react";
import { GET_FIELDS } from "../../../../../lib/apollo/gql/field";
import {
  GET_CLASS,
  UPDATE_CLASS_FIELDS,
} from "../../../../../lib/apollo/gql/class";
import { SCREENS } from "../../../../../routes/screens";

function useUpdateClassFieldsContainer() {
  const { navigate } = useNavigation();
  const { params } = useRoute();

  const [selectedFieldIds, setSelectedFieldsIds] = useState(new Set());

  const { data: fieldsData, loading: fieldsLoading } = useQuery(GET_FIELDS);

  const { data, loading, error } = useQuery(GET_CLASS, {
    variables: {
      classId: params?.classId,
    },
    onCompleted: ({ getClass }) => {
      setSelectedFieldsIds(new Set(getClass?.fields?.map(field => field?.id)));
    },
  });

  const [updateClassFields, { loading: updateClassFieldsLoading }] =
    useMutation(UPDATE_CLASS_FIELDS, {
      onCompleted: ({ updateClassFields }) => {
        navigate(SCREENS.UPDATE_CLASS_DONE, { classId: updateClassFields?.id });
      },
    });

  function onFieldSelect(fieldId) {
    const currentSelectedFieldIds = new Set(selectedFieldIds);

    if (currentSelectedFieldIds.has(fieldId)) {
      currentSelectedFieldIds.delete(fieldId);
      setSelectedFieldsIds(currentSelectedFieldIds);
    } else {
      currentSelectedFieldIds.add(fieldId);
      setSelectedFieldsIds(currentSelectedFieldIds);
    }
  }

  function onSubmit() {
    const fieldIds = Array.from(selectedFieldIds);

    updateClassFields({
      variables: {
        classId: params?.classId,
        fieldIds,
      },
    });
  }

  return {
    models: {
      fields: fieldsData?.getFields,
      selectedFieldIds,
      loading: fieldsLoading || loading,
      updateLoading: updateClassFieldsLoading,
    },
    operations: {
      onFieldSelect,
      onSubmit,
    },
  };
}

export default useUpdateClassFieldsContainer;
