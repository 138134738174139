import { useState } from "react";
import { Dimensions, Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

function useCustomKeyBoardAvoidingView() {
  const [keyboardVerticalOffset, setKeyboardVerticalOffset] = useState(0);

  const { bottom } = useSafeAreaInsets();

  function onLayout({ nativeEvent }) {
    if (Platform.OS === "android") {
      setKeyboardVerticalOffset(-500);
      return;
    }
    setKeyboardVerticalOffset(
      Dimensions.get("window").height - nativeEvent.layout.height - bottom,
    );
  }

  return {
    models: {
      keyboardVerticalOffset,
    },
    operations: {
      onLayout,
    },
  };
}

export default useCustomKeyBoardAvoidingView;
