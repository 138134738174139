import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_MY_USER_PROFILE,
  IS_UNIQUE_USERNAME,
  UPDATE_MY_MENTOR_BASIC_PROFILE,
  UPDATE_MY_TUTOR_BASIC_PROFILE,
  UPDATE_MY_USER_PROFILE,
} from "../../../../lib/apollo/gql/user";
import { useState } from "react";
import { format } from "date-fns";

function useUpdateProfileContainer() {
  const [state, setState] = useState({
    username: "",
    name: "",
    dateOfBirth: format(new Date(), "yyyy-MM-dd"),
    gender: null,
    phone: "",
    introduction: "",

    mentor: {
      id: "",
      introduction: "",
      experience: "",
    },
    tutor: {
      id: "",
      introduction: "",
      experience: "",
      areaIds: [],
    },
    usernameError: "",
    nameError: "",
    introductionError: "",
    experienceError: "",
  });

  const basicRequiredValues = ["username", "dateOfBirth", "fields"];

  const requiredValues = state.tutor?.id
    ? [
        ...basicRequiredValues,
        "name",
        "areas",
        "title",
        "introduction",
        "experience",
      ]
    : state.mentor?.id
    ? [...basicRequiredValues, "name", "title", "introduction", "experience"]
    : basicRequiredValues;

  const { loading, data } = useQuery(GET_MY_USER_PROFILE, {
    onCompleted: ({ getMyUserProfile }) => {
      if (getMyUserProfile) {
        const {
          username,
          name,
          dateOfBirth,
          gender,
          phone,
          introduction,
          mentor,
          tutor,
        } = getMyUserProfile;

        if (tutor) {
          setState(prev => ({
            ...prev,
            username,
            name: name || "",
            dateOfBirth: dateOfBirth || format(new Date(), "yyyy-MM-dd"),
            gender: gender || null,
            phone: phone || "",
            introduction: introduction || "",
            tutor: {
              id: tutor?.id,
              introduction: tutor?.introduction,
              experience: tutor?.experience,
              areaIds: tutor?.areas
                ? [{ label: tutor?.areas[0]?.name, value: tutor?.areas[0]?.id }]
                : [],
            },
          }));
        } else if (mentor) {
          setState(prev => ({
            ...prev,
            username,
            name: name || "",
            dateOfBirth: dateOfBirth || format(new Date(), "yyyy-MM-dd"),
            gender: gender || null,
            phone: phone || "",
            introduction: introduction || "",
            mentor: {
              id: mentor?.id,
              introduction: mentor?.introduction,
              experience: mentor?.experience,
            },
          }));
        } else {
          setState(prev => ({
            ...prev,
            username,
            name: name || "",
            dateOfBirth: dateOfBirth || format(new Date(), "yyyy-MM-dd"),
            gender: gender || null,
            phone: phone || "",
            introduction: introduction || "",
          }));
        }
      }
    },
  });

  const [updateMyUserProfile, { loading: updateProfileLoading }] = useMutation(
    UPDATE_MY_USER_PROFILE,
  );

  const [updateMyMentorBasicProfile, { loading: updateMentorLoading }] =
    useMutation(UPDATE_MY_MENTOR_BASIC_PROFILE);

  const [updateMyTutorBasicProfile, { loading: updateTutorLoading }] =
    useMutation(UPDATE_MY_TUTOR_BASIC_PROFILE);

  const [isUniqueUsername, { loading: usernameLoading }] =
    useLazyQuery(IS_UNIQUE_USERNAME);

  function onBasicInputChange(text, key) {
    if (key === "phone" && isNaN(Number(text))) {
      return;
    }

    if (
      key === "introduction" &&
      text.replaceAll(/\s/g, "").trim().length > 20
    ) {
      return;
    }

    setState(prev => ({
      ...prev,
      [key]: text,
    }));
  }

  function onBirthChange(date) {
    setState(prev => ({
      ...prev,
      dateOfBirth: date,
    }));
  }

  function onGenderChange(gender) {
    setState(prev => ({
      ...prev,
      gender,
    }));
  }

  function onMentorInputChange(text, key) {
    if (
      key === "introduction" &&
      text.replaceAll(/\s/g, "").trim().length > 20
    ) {
      return;
    }

    setState(prev => ({
      ...prev,
      mentor: {
        ...prev.mentor,
        [key]: text,
      },
    }));
  }

  function onTutorInputChange(text, key) {
    if (
      key === "introduction" &&
      text.replaceAll(/\s/g, "").trim().length > 20
    ) {
      return;
    }

    setState(prev => ({
      ...prev,
      tutor: {
        ...prev.tutor,
        [key]: text,
      },
    }));
  }

  function onAreaSelect(areaId) {
    setState(prev => ({
      ...prev,
      tutor: {
        ...prev.tutor,
        areaIds: [areaId],
      },
    }));
  }

  function onUsernameFocus() {
    if (state.usernameError) {
      setState(prev => ({ ...prev, usernameError: "" }));
    }
  }

  async function onUsernameBlur() {
    if (state.username?.length < 2) {
      setState(prev => ({
        ...prev,
        usernameError: "닉네임은 최소 2자이상입니다",
      }));
    }
    if (state?.username !== data?.getMyUserProfile?.username) {
      const { data } = await isUniqueUsername({
        variables: {
          username: state.username,
        },
      });

      if (!data?.isUniqueUsername) {
        setState(prev => ({
          ...prev,
          usernameError: "이미 사용중인 닉네임입니다",
        }));
      }
    }
  }

  function onNameFocus() {
    if (state.nameError) {
      setState(prev => ({
        ...prev,
        nameError: "",
      }));
    }
  }

  function onNameBlur() {
    if (
      (data?.getMyUserProfile?.mentor || data?.getMyUserProfile?.tutor) &&
      state?.name?.length < 1
    ) {
      setState(prev => ({
        ...prev,
        nameError: "이름을 입력해 주세요",
      }));
    }
  }

  function onIntroductionFocus() {
    if (state.introductionError) {
      setState(prev => ({
        ...prev,
        introductionError: "",
      }));
    }
  }

  function onIntroductionBlur() {
    if (
      !data?.getMyUserProfile?.tutor &&
      data?.getMyUserProfile?.mentor &&
      state?.mentor?.introduction?.length < 1
    ) {
      setState(prev => ({
        ...prev,
        introductionError: "멘토 소개를 입력해 주세요",
      }));
    }

    if (
      data?.getMyUserProfile?.tutor &&
      state?.tutor?.introduction?.length < 1
    ) {
      setState(prev => ({
        ...prev,
        introductionError: "강사 소개를 입력해 주세요",
      }));
    }
  }

  function onExperienceFocus() {
    if (state.experienceError) {
      setState(prev => ({
        ...prev,
        experienceError: "",
      }));
    }
  }

  function onExperienceBlur() {
    if (
      !data?.getMyUserProfile?.tutor &&
      data?.getMyUserProfile?.mentor &&
      state?.mentor?.experience?.length < 1
    ) {
      setState(prev => ({
        ...prev,
        experienceError: "멘토 경력을 입력해 주세요",
      }));
    }

    if (data?.getMyUserProfile?.tutor && state?.tutor?.experience?.length < 1) {
      setState(prev => ({
        ...prev,
        experienceError: "강사 경력을 입력해 주세요",
      }));
    }
  }

  function isSubmitDisabled() {
    const { usernameError, nameError, experienceError, introductionError } =
      state;

    const noError =
      !usernameError && !nameError && !experienceError && !introductionError;

    return (
      !noError ||
      updateProfileLoading ||
      updateMentorLoading ||
      updateTutorLoading
    );
  }

  async function onSubmit() {
    const {
      username,
      name,
      dateOfBirth,
      phone,
      gender,
      introduction,
      tutor,
      mentor,
    } = state;

    await updateMyUserProfile({
      variables: {
        userInput: {
          username,
          name,
          email: data?.getMyUserProfile?.email,
          dateOfBirth,
          phone,
          gender,
          introduction,
          fieldIds: data?.getMyUserProfile?.fields?.map(field => field?.id),
        },
      },
    });

    if (!!tutor?.id) {
      await updateMyTutorBasicProfile({
        variables: {
          introduction: tutor?.introduction,
          experience: tutor?.experience,
          areaIds: [tutor?.areaIds[0]?.value],
        },
      });
    }

    if (!tutor?.id && !!mentor?.id) {
      await updateMyMentorBasicProfile({
        variables: {
          introduction: mentor?.introduction,
          experience: mentor?.experience,
        },
      });
    }
  }

  return {
    models: {
      loading,
      updateLoading:
        updateProfileLoading || updateMentorLoading || updateTutorLoading,
      state,
      avatar: data?.getMyUserProfile?.avatar,
      fields: data?.getMyUserProfile?.fields,
      mentorFields: data?.getMyUserProfile?.mentor?.fields,
      tutorFields: data?.getMyUserProfile?.tutor?.fields,
      requiredValues,
    },
    operations: {
      onBasicInputChange,
      onBirthChange,
      onGenderChange,
      onMentorInputChange,
      onTutorInputChange,
      onAreaSelect,
      onUsernameFocus,
      onUsernameBlur,
      onNameFocus,
      onNameBlur,
      onIntroductionFocus,
      onIntroductionBlur,
      onExperienceFocus,
      onExperienceBlur,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateProfileContainer;
