import React from "react";
import Layout from "../../../common/components/Layout";
import SearchTabContainer from "../container/SearchTabContainer";

function SearchTabScreen() {
  return (
    <Layout isTabScreen>
      <SearchTabContainer />
    </Layout>
  );
}

export default SearchTabScreen;
