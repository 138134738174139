import { gql } from "@apollo/client";

export const GET_CLASS = gql`
  query GetClass($classId: ID!) {
    getClass(classId: $classId) {
      id
      tutor {
        id
        introduction
        fields {
          id
          name
          sort
        }
        user {
          id
          username
        }
        areas {
          id
          name
          sort
        }
      }
      title
      description
      duration
      coverPhoto {
        id
        uri
      }
      fields {
        id
        name
        sort
      }
      active
      createdAt
      updatedAt
    }
  }
`;

export const GET_MY_CLASSES = gql`
  query GetMyClasses($active: Boolean!, $first: Int, $after: String) {
    getMyClasses(active: $active, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        tutor {
          id
          introduction
          verified
          active
          areas {
            id
            name
          }
          user {
            id
            username
            avatar {
              id
              uri
            }
          }
        }
        title
        description
        duration
        coverPhoto {
          id
          uri
        }
        fields {
          id
          name
          sort
        }
        active
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_CLASS_BASIC = gql`
  mutation UpdateClassBasic($classInput: ClassInput!) {
    updateClassBasic(classInput: $classInput) {
      id
      tutor {
        id
      }
      title
      description
      duration
      coverPhoto {
        id
        uri
      }
      fields {
        id
        name
        sort
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CLASS_FIELDS = gql`
  mutation UpdateClassFields($classId: ID!, $fieldIds: [ID]!) {
    updateClassFields(classId: $classId, fieldIds: $fieldIds) {
      id
      tutor {
        id
      }
      title
      description
      duration
      coverPhoto {
        id
        uri
      }
      fields {
        id
        name
        sort
      }
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_CLASS_COVER_PHOTO = gql`
  mutation UpdateClassCoverPhoto($classId: ID!, $mediaInput: MediaInput!) {
    updateClassCoverPhoto(classId: $classId, mediaInput: $mediaInput) {
      id
      coverPhoto {
        id
        uri
      }
    }
  }
`;

export const TOGGLE_CLASS_ACTIVE = gql`
  mutation ToggleClassActive($classId: ID!, $active: Boolean!) {
    toggleClassActive(classId: $classId, active: $active) {
      id
      active
    }
  }
`;
