import React from "react";
import { useRoute } from "@react-navigation/native";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { SCREENS } from "../../../../routes/screens";
import TopProgressBar from "../../../common/components/TopProgressBar";
import RequestBasicInfoContainer from "../container/RequestBasicInfoContainer";

function RequestBasicInfoScreen() {
  const { name } = useRoute();

  return (
    <Layout>
      <NavigationBar
        title={
          name === SCREENS.REQUEST_MENTOR_BASIC
            ? "이타멘토 지원하기"
            : "이타강사 지원하기"
        }
        isModal
      />
      <TopProgressBar progress={1} total={3} />
      <RequestBasicInfoContainer />
    </Layout>
  );
}

export default RequestBasicInfoScreen;
