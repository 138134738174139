import { gql } from "@apollo/client";

export const WRITE_REVIEW = gql`
mutation Mutation($classId: String, $userId: String, $content: String, $score: Int) {
  createReview(classId: $classId, userId: $userId, content: $content, score: $score)
}
`;

export const GET_ALL_REVIEWS = gql`
query GetAllReviews($classId: String, $page: Int, $maxPage: Int) {
  getAllReviews(page: $page, maxPage: $maxPage, classId: $classId) {
    classId
    content
    score
    class {
      id
      title
    }
    user {
      avatar {
        name
        size
        uri
        thumbnailUri
      }
      username
      name
    }
    createdAt
    id
    userId
  }
}
`;
