import { useLazyQuery } from "@apollo/client";
import { IS_UNIQUE_EMAIL } from "../../../../lib/apollo/gql/user";
import { SCREENS } from "../../../../routes/screens";
import { useNavigation } from "@react-navigation/native";
import { useSignUpContext } from "../provider/SignUpProvider";
import { useState } from "react";
import validator from "validator";

function useSignUpContainer() {
  const { navigate } = useNavigation();
  const [state, setState] = useState({
    emailError: "",
    passwordError: "",
    confirmPasswordError: "",
  });

  const { email, password, confirmPassword, onInputChange } =
    useSignUpContext();

  const [isUniqueEmail, { loading }] = useLazyQuery(IS_UNIQUE_EMAIL);

  function onInputFocus(key) {
    if (key === "email" && state.emailError) {
      setState(prev => ({
        ...prev,
        emailError: "",
      }));
    } else if (key === "password" && state.passwordError) {
      setState(prev => ({
        ...prev,
        passwordError: "",
      }));
    } else if (key === "confirmPassword" && state.confirmPasswordError) {
      setState(prev => ({
        ...prev,
        confirmPasswordError: "",
      }));
    }
  }

  async function onEmailBlur() {
    if (!validator.isEmail(email)) {
      setState(prev => ({
        ...prev,
        emailError: "올바른 이메일 주소를 입력하세요",
      }));
    } else {
      const { data } = await isUniqueEmail({
        variables: {
          email,
        },
      });

      if (!data?.isUniqueEmail) {
        setState(prev => ({
          ...prev,
          emailError: "이미 사용중인 이메일입니다",
        }));
      }
    }
  }

  function onPasswordBlur() {
    if (password?.length < 6) {
      setState(prev => ({
        ...prev,
        passwordError: "비밀번호는 최소 6자 이상입니다",
      }));
    }
  }

  function onConfirmPasswordBlur() {
    if (confirmPassword?.length < 6) {
      setState(prev => ({
        ...prev,
        confirmPasswordError: "비밀번호는 최소 6자 이상입니다",
      }));
    }

    if (password !== confirmPassword) {
      setState(prev => ({
        ...prev,
        confirmPasswordError: "비밀번호가 일치하지 않습니다",
      }));
    }
  }

  function isSubmitDisabled() {
    const isValidEmail = !state.emailError && email;
    const isValidPassword = !state.passwordError && password.length > 5;
    const isValidConfirmPassword =
      !state.confirmPasswordError &&
      confirmPassword.length > 5 &&
      password === confirmPassword;

    return (
      !(isValidEmail && isValidPassword && isValidConfirmPassword) || loading
    );
  }

  function onSubmit() {
    navigate(SCREENS.CREATE_BASIC_PROFILE);
  }

  return {
    models: {
      email,
      password,
      confirmPassword,
      state,
    },
    operations: {
      isUniqueEmail,
      onInputChange,
      onInputFocus,
      onEmailBlur,
      onPasswordBlur,
      onConfirmPasswordBlur,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useSignUpContainer;
