import { useMutation, useQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-native-get-random-values";
import { v4 } from "uuid";
import {
  GET_CHAT,
  GET_CHAT_MESSAGES,
  NEW_MESSAGE,
  SEND_MESSAGE,
  UPDATE_LAST_SEEN_AT,
} from "../../../../lib/apollo/gql/chat";
import { useRoute } from "@react-navigation/native";
import { useAuthContext } from "../../../providers/AuthProvider";
import { MESSAGE_STATUS } from "../../../../utilities/constants";
import { isAfter } from "date-fns";

const NUMBER_OF_MESSAGES_TO_FETCH = 20;

function useChatContainer() {
  const {
    params: { chatId },
  } = useRoute();
  const { user } = useAuthContext();

  const myId = user?.id;

  const [text, setText] = useState("");

  const [updateLastSeenAt] = useMutation(UPDATE_LAST_SEEN_AT);
  const [sendMessage] = useMutation(SEND_MESSAGE);
  const isFetchingMore = useRef(false);
  const {
    loading: loadingMessages,
    error: errorMessages,
    data: completeMessageData,
    fetchMore,
    subscribeToMore,
    client,
    refetch,
  } = useQuery(GET_CHAT_MESSAGES, {
    variables: {
      chatId,
      first: NUMBER_OF_MESSAGES_TO_FETCH,
    },
  });

  useEffect(() => {
    //NOTE: Using refetch to renew data everytime screen gets opened.
    //      We should be able to avoid this pattern by using fetchPolicy: networkOnly.
    //      However, currently there is a bug such that adding networkOnly attribute triggers merge function twice, which second call contains
    //      incorrect information. Therefore, we are using refetch here to circumvent.
    refetch();
  }, []);

  const incompleteMessages =
    completeMessageData?.getChatMessages?.incompleteMessages;

  const {
    loading: loadingChat,
    error: errorChat,
    data: chatData,
  } = useQuery(GET_CHAT, {
    variables: {
      chatId,
    },
  });

  /**
   * Send message
   * @param {Message} message message to be sent
   */
  const send = useCallback(
    async message => {
      const { text } = message;
      try {
        const { data } = await sendMessage({
          variables: {
            chatId,
            text,
          },
        });
        try {
          // Filter incomplete message
          const newMessage = data.sendMessage;

          // Add new message into GET_CHAT_MESSAGES
          const getChatMessages = client.readQuery({
            query: GET_CHAT_MESSAGES,
            variables: {
              chatId,
              first: NUMBER_OF_MESSAGES_TO_FETCH,
            },
          })?.getChatMessages;

          const prevEdges = getChatMessages?.edges || [];
          const incompleteMessages = getChatMessages?.incompleteMessages || [];

          client.writeQuery({
            query: GET_CHAT_MESSAGES,
            variables: {
              chatId,
              first: NUMBER_OF_MESSAGES_TO_FETCH,
            },
            data: {
              getChatMessages: {
                ...getChatMessages,
                edges: [newMessage, ...prevEdges],
                incompleteMessages: incompleteMessages.filter(
                  incompleteMessage => incompleteMessage.id !== message.id,
                ),
              },
            },
          });
        } catch (err) {
          console.error("Error while reading/writing to getChatMessages", err);
        }
      } catch (err) {
        // Change the failed incomplete message's status to failed from in progress

        client.writeQuery({
          query: GET_CHAT_MESSAGES,
          variables: {
            chatId,
            first: NUMBER_OF_MESSAGES_TO_FETCH,
          },
          data: {
            getChatMessages: {
              ...getChatMessages,
              edges: [newMessage, ...prevEdges],
              incompleteMessages: incompleteMessages?.map(incompleteMessage => {
                if (incompleteMessage.id === message.id) {
                  return {
                    ...incompleteMessage,
                    status: MESSAGE_STATUS.SENDING_FAILED,
                  };
                }
                return incompleteMessage;
              }),
            },
          },
        });
      }
    },
    [incompleteMessages],
  );

  // Message subscription
  useEffect(() => {
    const unsubscribeGetChatMessages = subscribeToMore({
      document: NEW_MESSAGE,
      variables: {
        chatId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        try {
          const newMessage = subscriptionData?.data?.newMessage;
          if (!newMessage) {
            return prev;
          }

          const previousMessages = prev?.getChatMessages?.edges || [];
          return {
            getChatMessages: {
              ...prev.getChatMessages,
              edges: mergeMessagesInChronologicalOrder(previousMessages, [
                newMessage,
              ]),
            },
          };
        } catch (err) {
          console.error(
            "Error while processing subscribed chat message: ",
            err,
          );
        } finally {
          updateLastSeenAt({
            variables: {
              chatId,
            },
          });
        }
      },
    });

    return () => {
      unsubscribeGetChatMessages();
    };
  }, []);

  // Call back on flat list reached top. Fetch previous messages
  function getPreviousMessages() {
    if (
      !isFetchingMore.current &&
      completeMessageData?.getChatMessages?.pageInfo?.hasNextPage
    ) {
      isFetchingMore.current = true;
      fetchMore({
        variables: {
          chatId,
          after: completeMessageData?.getChatMessages?.pageInfo?.endCursor,
          first: NUMBER_OF_MESSAGES_TO_FETCH,
        },
      }).finally(() => {
        isFetchingMore.current = false;
      });
    }
  }

  const createAndSendInProgressMessage = useCallback(
    (myId, messageText) => {
      const tempAuthor = {
        id: myId,
        username: "",
        __typename: "User",
      };

      const newIncompleteMessage = {
        id: v4(),
        text: messageText,
        status: MESSAGE_STATUS.SENDING_IN_PROGRESS,
        author: tempAuthor,
        __typename: "Message",
      };

      const getChatMessages = client.readQuery({
        query: GET_CHAT_MESSAGES,
        variables: {
          chatId,
          first: NUMBER_OF_MESSAGES_TO_FETCH,
        },
      }).getChatMessages;

      client.writeQuery({
        query: GET_CHAT_MESSAGES,
        variables: {
          chatId,
          first: NUMBER_OF_MESSAGES_TO_FETCH,
        },
        data: {
          getChatMessages: {
            ...getChatMessages,
            incompleteMessages: [
              newIncompleteMessage,
              // ...getChatMessages.incompleteMessages,
            ],
          },
        },
      });

      send(newIncompleteMessage);
    },
    [send],
  );

  /**
   * Find incomplete message with failed status form cache
   * and then resend the message.
   * @param {Message} message
   */
  const resendFailedMessage = useCallback(
    message => {
      let resendingMessage;
      const filteredIncompleteMessages = incompleteMessages.filter(
        incompleteMessage => {
          if (incompleteMessage.id === message.id) {
            resendingMessage = {
              ...incompleteMessage,
              status: MESSAGE_STATUS.SENDING_IN_PROGRESS,
            };
            return false;
          }
          return true;
        },
      );

      client.writeQuery({
        query: GET_CHAT_MESSAGES,
        variables: {
          chatId,
          first: NUMBER_OF_MESSAGES_TO_FETCH,
        },
        data: {
          getChatMessages: {
            ...getChatMessages,
            incompleteMessages: [
              resendingMessage,
              ...filteredIncompleteMessages,
            ],
          },
        },
      });

      send(resendingMessage);
    },
    [send, incompleteMessages],
  );

  /**
   * Find incomplete message with given message's id and then
   * delete the message from cache
   * @param {Message} message
   */
  const deleteMessage = useCallback(
    message => {
      client.writeQuery({
        query: GET_CHAT_MESSAGES,
        variables: {
          chatId,
          first: NUMBER_OF_MESSAGES_TO_FETCH,
        },
        data: {
          getChatMessages: {
            ...getChatMessages,
            edges: [newMessage, ...prevEdges],
            incompleteMessages: incompleteMessages.filter(
              incompleteMessage => incompleteMessage.id !== message.id,
            ),
          },
        },
      });
    },
    [incompleteMessages],
  );

  // get message data
  const chat = useMemo(() => {
    return chatData?.getChat;
  }, [
    chatData?.getChat?.sharesLastSeenAt,
    chatData?.getChat?.peersLastSeenAt,
    chatData?.getChat?.createdAt,
    chatData?.getChat?.consultation?.status,
  ]);

  const messages =
    !loadingMessages && !loadingChat
      ? getMessageData(
          myId,
          incompleteMessages || [],
          completeMessageData?.getChatMessages?.edges,
          chat,
        )
      : [];

  return {
    loading: loadingMessages || loadingChat,
    errorMessages,
    errorChat,
    messages,
    chat,
    myId,
    text,
    messageMutator: {
      createAndSendInProgressMessage,
      resendFailedMessage,
      deleteMessage,
      getPreviousMessages,
    },
    setText,
  };
}

/**
 * Merge incomplete and completed messages together.
 * It also append property of read / unread icon
 * @param {*} incompleteMessages
 * @param {*} messages
 */
function getMessageData(myId, incompleteMessages, messages, chat) {
  let result = [];
  let incompleteMessageLength = incompleteMessages?.length
    ? incompleteMessages.length
    : 0;
  let messagesLength = messages?.length ? messages.length : 0;
  for (let i = 0; i < incompleteMessageLength; i++) {
    const msg = {
      ...incompleteMessages[i],
      role: getRole(incompleteMessages[i], chat),
    };
    result.push(msg);
  }

  let foundReadMessage = false;
  for (let i = 0; i < messagesLength; i++) {
    const msg = {
      ...messages[i],
      role: getRole(messages[i], chat),
    };

    result.push(msg);
  }

  return result;
}

function getRole(message, chat) {
  const authorId = message?.author?.id;
  const {
    tutor: { id: tutorId },
    user: { id: userId },
  } = chat;

  if (authorId === tutorId && authorId !== userId) {
    return "TUTOR";
  } else if (authorId === userId && authorId !== tutorId) {
    return "USER";
  } else {
    return "UNKNOWN";
  }
}

// function getReadUnreadIcon(myId, message, foundReadMessage, chat) {
//   if (myId !== message?.author?.id || foundReadMessage) {
//     return null;
//   }

//   switch (myId) {
//     // Message needs to be rerendered if Message is in unread status
//     // NOTE: dont change Date to moment as it slows down React memo more than 10 times then using new Date
//     case chat?.tutor?.id:
//       if (myId === message?.author?.id) {
//         if (new Date(message?.createdAt) > new Date(chat?.userLastSeenAt)) {
//           return CEHCK_MARK.UNREAD;
//         }
//       }
//       break;
//     case chat?.user?.id:
//       if (myId === message?.author?.id) {
//         if (new Date(message?.createdAt) > new Date(chat?.tutorLastSeenAt)) {
//           return CEHCK_MARK.UNREAD;
//         }
//       }
//       break;
//     default:
//       return null;
//   }
//   return null;
// }

/**
 * Merge messages in chronological order
 * @param {[Message]} messages1
 * @param {[Message]} messages2
 */
function mergeMessagesInChronologicalOrder(messages1, messages2) {
  let ptr1 = 0;
  let ptr2 = 0;
  let mergedMessages = [];
  while (ptr1 < messages1.length && ptr2 < messages2.length) {
    const message1 = messages1[ptr1];
    const message2 = messages2[ptr2];

    if (isAfter(new Date(message1.createdAt), new Date(message2.createdAt))) {
      mergedMessages.push(message1);
      ptr1 += 1;
    } else {
      mergedMessages.push(message2);
      ptr2 += 1;
    }
  }

  while (ptr1 < messages1.length) {
    mergedMessages.push(messages1[ptr1]);
    ptr1 += 1;
  }

  while (ptr2 < messages2.length) {
    mergedMessages.push(messages2[ptr2]);
    ptr2 += 1;
  }

  return mergedMessages;
}

export default useChatContainer;
