import React from "react";
import Layout from "../../../../common/components/Layout";
import UpdateClassDoneContainer from "../container/UpdateClassDoneContainer";

function UpdateClassDoneScreen() {
  return (
    <Layout>
      <UpdateClassDoneContainer />
    </Layout>
  );
}

export default UpdateClassDoneScreen;
