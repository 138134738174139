import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import ManageClassContainer from "../container/ManageClassContainer";

function ManageClassScreen() {
  return (
    <Layout>
      <NavigationBar title="강의 관리" />
      <ManageClassContainer />
    </Layout>
  );
}

export default ManageClassScreen;
