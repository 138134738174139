import React, { useMemo } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import InputWithLabel from "../../../common/components/InputWithLabel";
import DateInput from "../../../common/components/DateInput";
import GenderInput from "../../../common/components/GenderInput";
import TextButton from "../../../common/components/TextButton";
import useCreateBasicProfileContainer from "./CreateBasicProfileContainer.hook";
import CustomKeyBoardAvoidingView from "../../../common/components/CustomKeyBoardAvoidingView";

function CreateBasicProfileContainer() {
  const {
    models: {
      username,
      name,
      dateOfBirth,
      gender,
      phone,
      introduction,
      usernameError,
    },
    operations: {
      onInputChange,
      onBirthChange,
      onGenderChange,
      onUsernameFocus,
      onUsernameBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useCreateBasicProfileContainer();

  const memoizedUsernameInput = useMemo(
    () => (
      <InputWithLabel
        label="닉네임"
        isRequired
        placeholder="2자 이상"
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={username}
        errorMessage={usernameError}
        onChangeText={text => onInputChange(text, "username")}
        onFocus={onUsernameFocus}
        onBlur={onUsernameBlur}
      />
    ),
    [username, usernameError, onInputChange, onUsernameFocus, onUsernameBlur],
  );

  const memoizedNameInput = useMemo(
    () => (
      <InputWithLabel
        label="이름"
        placeholder="실명"
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={name}
        onChangeText={text => onInputChange(text, "name")}
      />
    ),
    [name, usernameError, onInputChange],
  );

  const memoizedBirthInput = useMemo(
    () => (
      <DateInput
        label="생년월일"
        isRequired
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={dateOfBirth}
        onConfirm={date => onBirthChange(date)}
      />
    ),
    [dateOfBirth, onBirthChange],
  );

  const memoizedGenderInput = useMemo(
    () => (
      <GenderInput
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="성별"
        gender={gender}
        onGenderSelect={gender => onGenderChange(gender)}
      />
    ),
    [gender, onGenderChange],
  );

  const memoizedPhoneInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="휴대폰번호"
        placeholder="‘-’ 빼고 숫자만"
        inputMode="numeric"
        maxLength={11}
        value={phone}
        onChangeText={text => onInputChange(text, "phone")}
      />
    ),
    [phone, onInputChange],
  );

  const memoizedIntroductionInput = useMemo(
    () => (
      <InputWithLabel
        inputStyle={{
          height: 80,
          textAlignVertical: "top",
          marginBottom: theme.spacing.section,
        }}
        label="소개(20자 이내)"
        placeholder="자신에 대한 소개글을 적어주세요"
        multiline
        value={introduction}
        onChangeText={text => onInputChange(text, "introduction")}
        onSubmitEditing={() => {
          if (!isSubmitDisabled()) {
            onSubmit();
          }
        }}
      />
    ),
    [introduction, onInputChange],
  );

  return (
    <CustomKeyBoardAvoidingView>
      <ScrollView
        style={styles.wrapper}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <View style={styles.titleRow}>
          <Text style={styles.title}>기본 정보</Text>
          <Text style={styles.requirementText}>*필수입력</Text>
        </View>
        {memoizedUsernameInput}
        {memoizedNameInput}
        {memoizedBirthInput}
        {memoizedGenderInput}
        {memoizedPhoneInput}
        {memoizedIntroductionInput}
        <TextButton
          text="다음"
          disabled={isSubmitDisabled()}
          onPress={onSubmit}
        />
      </ScrollView>
    </CustomKeyBoardAvoidingView>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    position: "relative",
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.middle,
    paddingHorizontal: theme.spacing.regular,
  },
  titleRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing.default,
  },
  title: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  requirementText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.error,
  },
});

export default CreateBasicProfileContainer;
