import { useMutation, useQuery } from "@apollo/client";
import { GET_FIELDS } from "../../../../lib/apollo/gql/field";
import { useState } from "react";
import {
  GET_MY_USER_PROFILE,
  UPDATE_MY_MENTOR_FIELDS,
  UPDATE_MY_TUTOR_FIELDS,
} from "../../../../lib/apollo/gql/user";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function useRequestFieldsContainer() {
  const { navigate } = useNavigation();
  const { name, params } = useRoute();

  const [selectedFieldIds, setSelectedFieldsIds] = useState(new Set());

  const { data: fieldsData, loading: fieldsLoading } = useQuery(GET_FIELDS);

  const { data, loading, error } = useQuery(GET_MY_USER_PROFILE, {
    onCompleted: ({ getMyUserProfile }) => {
      if (
        name === SCREENS.REQUEST_MENTOR_FIELDS &&
        getMyUserProfile?.mentor?.id
      ) {
        setSelectedFieldsIds(
          new Set(getMyUserProfile?.mentor?.fields?.map(field => field?.id)),
        );
      }
    },
  });

  const [updateMyMentorFields, { loading: updateMentorLoading }] = useMutation(
    UPDATE_MY_MENTOR_FIELDS,
    {
      onCompleted: onMutationCompleted,
      refetchQueries: () => [
        { query: GET_MY_USER_PROFILE, fetchPolicy: "network-only" },
      ],
    },
  );
  const [updateMyTutorFields, { loading: updateTutorLoading }] = useMutation(
    UPDATE_MY_TUTOR_FIELDS,
    {
      onCompleted: onMutationCompleted,
      refetchQueries: () => [
        { query: GET_MY_USER_PROFILE, fetchPolicy: "network-only" },
      ],
    },
  );

  function onMutationCompleted() {
    if (name === SCREENS.REQUEST_MENTOR_FIELDS) {
      navigate(SCREENS.REQUEST_MENTOR_DONE, { ...params });
    } else {
      navigate(SCREENS.REQUEST_TUTOR_DONE, { ...params });
    }
  }

  function onFieldSelect(fieldId) {
    const currentSelectedFieldIds = new Set(selectedFieldIds);

    if (currentSelectedFieldIds.has(fieldId)) {
      currentSelectedFieldIds.delete(fieldId);
      setSelectedFieldsIds(currentSelectedFieldIds);
    } else {
      currentSelectedFieldIds.add(fieldId);
      setSelectedFieldsIds(currentSelectedFieldIds);
    }
  }

  function onSubmit() {
    const fieldIds = Array.from(selectedFieldIds);
    if (name === SCREENS.REQUEST_MENTOR_FIELDS) {
      updateMyMentorFields({
        variables: {
          fieldIds,
        },
      });
    } else {
      updateMyTutorFields({
        variables: {
          fieldIds,
        },
      });
    }
  }

  return {
    models: {
      fields: fieldsData?.getFields,
      selectedFieldIds,
      loading: fieldsLoading || loading,
      updateLoading: updateMentorLoading || updateTutorLoading,
    },
    operations: {
      onFieldSelect,
      onSubmit,
    },
  };
}

export default useRequestFieldsContainer;
