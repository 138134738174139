import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function HomeSectionTitle({ title, field, type }) {
  const { navigate } = useNavigation();
  return (
    <View style={styles.wrapper}>
      <View style={styles.titleRow}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.fieldWrapper}>
          <Text style={styles.fieldText}>{field?.name}</Text>
        </View>
      </View>
      <Pressable
        onPress={() =>
          navigate(SCREENS.VIEW_ALL, {
            fieldId: field?.id,
            fieldName: field?.name,
            type,
          })
        }
      >
        <Text style={styles.viewAll}>전체보기</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing.default,
  },
  titleRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginRight: theme.spacing.small,
  },
  fieldWrapper: {
    paddingHorizontal: theme.spacing.small,
    paddingVertical: theme.spacing.xxSmall,
    backgroundColor: theme.color.gray5,
    borderRadius: 4,
  },
  fieldText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
  },
  viewAll: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.gray1,
  },
});

export default HomeSectionTitle;
