import React from "react";
import { StyleSheet, Text } from "react-native";
import { theme } from "../utilities/theme";

function TabBarLabel({ focused, label }) {
  return (
    <Text
      style={[
        styles.bottomText,
        {
          color: focused ? theme.color.primary : theme.color.gray3,
        },
      ]}
    >
      {label}
    </Text>
  );
}

const styles = StyleSheet.create({
  bottomText: {
    fontFamily: "Pretendard-Regular",
    fontSize: 10,
  },
});

export default TabBarLabel;
