import { useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { SCREENS } from "../../../../routes/screens";
import UpdateFieldsContainer from "../container/UpdateFieldsContainer";

function UpdateFieldsScreen() {
  const { navigate } = useNavigation();
  const { params } = useRoute();

  return (
    <Layout>
      <NavigationBar
        title={params?.type === "user" ? "관심분야 변경" : "전문분야 변경"}
        onBackButtonPress={() => navigate(SCREENS.UPDATE_PROFILE)}
      />
      <UpdateFieldsContainer />
    </Layout>
  );
}

export default UpdateFieldsScreen;
