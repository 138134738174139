import React from "react";
import { View, Text, StyleSheet, Image, Pressable } from "react-native";
import { theme } from "../../../utilities/theme";
import TextButton from "./TextButton";
import defaultProfile from "../../../assets/images/default_profile_image.png";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../routes/screens";

function SmallThumbnail({ wrapperStyle, isTutor, data }) {
  const { navigate } = useNavigation();

  const topBackgroundColor = isTutor
    ? theme.color.tertiaryOpacity
    : theme.color.secondaryOpacity;
  const topTextColor = isTutor ? theme.color.black : theme.color.white;

  return (
    <Pressable
      style={[styles.wrapper, wrapperStyle]}
      onPress={() =>
        navigate(
          SCREENS.PROFILE_DETAIL,
          isTutor ? { tutorId: data?.id } : { mentorId: data?.id },
        )
      }
    >
      <View style={[styles.top, { backgroundColor: topBackgroundColor }]}>
        <View style={styles.topRow}>
          <View style={{
            flex:1,
          }}>
            <Text style={[styles.usertypeText, { color: topTextColor }]}>
              {isTutor ? "Tutor" : "Mentor"}
            </Text>
            <Text style={[styles.username, { color: topTextColor }]}>
              {data?.user?.username}
            </Text>
          </View>
          <View>
            <Image
              style={styles.avatar}
              source={
                data?.user?.avatar
                  ? { uri: data?.user?.avatar?.uri }
                  : defaultProfile
              }
              resizeMode="cover"
            />
          </View>
        </View>
        {isTutor && (
          <Text style={styles.areaText}>📍{data?.areas?.[0]?.name}</Text>
        )}
      </View>
      <View style={styles.bottom}>
        <Text style={styles.introduction}>{data?.introduction}</Text>
        <Text style={styles.fieldsText}>
          {data?.fields?.map(field => "#" + field?.name + " ")}
        </Text>
        <TextButton
          text={isTutor ? "강의 신청" : "멘토링 신청"}
          buttonStyle={styles.button}
          textStyle={styles.buttonText}
        />
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    margin: theme.spacing.xxSmall2,
  },
  top: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    padding: theme.spacing.normal,
  },
  topRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  usertypeText: {
    fontSize: theme.font.caption3,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  username: {
    fontFamily: "Pretendard-Bold",
    fontSize: theme.font.body1,
    color: theme.color.black,
  },
  avatar: {
    width: 54,
    height: 54,
    borderRadius: 54 / 2,
  },
  areaText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
  },
  bottom: {
    borderWidth: 1,
    borderColor: theme.color.gray5,
    backgroundColor: theme.color.white,
    padding: theme.spacing.normal,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  introduction: {
    fontSize: theme.font.body2,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
    marginBottom: theme.spacing.small,
    height: 42,
  },
  fieldsText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.gray1,
    marginBottom: theme.spacing.middle,
    height: 38,
  },
  button: {
    paddingVertical: theme.spacing.small,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.color.gray5,
    backgroundColor: theme.color.white,
  },
  buttonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
    fontWeight: "normal",
  },
});

export default SmallThumbnail;
