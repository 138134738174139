import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import arrowIcon from "../../../../assets/images/arrow_right_line.png";

function ProfileTabMenuButton({ noBorder = false, title, rightText, onPress }) {
  return (
    <Pressable
      style={[styles.wrapper, { borderBottomWidth: noBorder ? 0 : 1 }]}
      onPress={onPress}
    >
      <Text style={styles.title}>{title}</Text>
      <View style={styles.row}>
        {!!rightText && <Text style={styles.rightText}>{rightText}</Text>}
        <Image style={styles.arrowIcon} source={arrowIcon} />
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    padding: theme.spacing.default,
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent",
    borderBottomColor: theme.color.grayBox,
  },
  title: {
    fontFamily: "Pretendard-Regular",
    color: theme.color.black,
    fontSize: theme.font.body1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  rightText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.gray1,
  },
  arrowIcon: {
    width: 20,
    height: 20,
  },
});

export default ProfileTabMenuButton;
