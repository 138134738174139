import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import TopProgressBar from "../../../common/components/TopProgressBar";
import CreateBasicProfileContainer from "../container/CreateBasicProfileContainer";

function CreateBasicProfileScreen() {
  const { navigate } = useNavigation();

  return (
    <Layout>
      <NavigationBar
        title="회원가입"
        onBackButtonPress={() => navigate(SCREENS.SIGN_UP)}
      />
      <TopProgressBar progress={2} total={3} />
      <CreateBasicProfileContainer />
    </Layout>
  );
}

export default CreateBasicProfileScreen;
