import React from "react";
import Layout from "../../../common/components/Layout";
import RequestDoneContainer from "../container/RequestDoneContainer";

function RequestDoneScreen() {
  return (
    <Layout>
      <RequestDoneContainer />
    </Layout>
  );
}

export default RequestDoneScreen;
