import { ApolloClient } from "@apollo/client";
import cache from "./cache";

import link from "./link";
import { resolvers, typeDefs } from "./schema";

const client = new ApolloClient({
  link,
  cache,
  typeDefs,
  resolvers,
});

client.onClearStore(async () => {
  // setDefaultCache(cache);
});

export default client;
