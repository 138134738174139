import React , {useState} from "react";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import { theme } from "../../../../utilities/theme";
import useClassDetailContainer from "./ClassDetailContainer.hook";
import defaultProfile from "../../../../assets/images/default_profile_image.png";
import presentationIcon from "../../../../assets/images/presentation_fill_white.png";
import { useAuthContext } from "../../../providers/AuthProvider";
import defaultCoverPhoto from "../../../../assets/images/default_cover_photo.png";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_REVIEWS, WRITE_REVIEW } from "../../../../lib/apollo/gql/review";
import StarRating from "react-native-star-svg-rating";

function ClassDetailContainer() {
  const { canGoBack, navigate, goBack } = useNavigation();
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');

  const {
    models: { loading, data },
    operations: { onFooterButtonPress },
  } = useClassDetailContainer();

  const { user } = useAuthContext();



  const { data: reviewsdata, refetch } =
    useQuery(GET_ALL_REVIEWS, { variables: { page: 1, maxPage: 5000, classId: data?.id } });


  
    const [writeReview] = useMutation(WRITE_REVIEW, {
      onCompleted: ({ ok }) => {
        setTimeout(() => {
          refetch();
        }, 300);
        setReviewText('');
      },
    });


  if (loading) return <View />;

  return (
    <View style={{ flex: 1, backgroundColor: theme.color.background }}>
      <NavigationBar
        title="강의 정보"
        backButtonText=""
        onBackButtonPress={() =>
          canGoBack() ? goBack() : navigate(SCREENS.HOME)
        }
      />
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <Image
          style={styles.coverPhoto}s
          source={
            data?.coverPhoto
              ? { uri: data?.coverPhoto?.uri }
              : defaultCoverPhoto
          }
        />
        <Text style={styles.title}>{data?.title}</Text>
        <View style={styles.areaDurationWrapper}>
          <View style={styles.areaDuration}>
            <Text style={styles.areaDurationText}>
              📍강의지역{" "}
              <Text style={{ fontFamily: "Pretendard-Bold" }}>
                {data?.tutor?.areas?.[0]?.name}
              </Text>
            </Text>
          </View>
          <View style={styles.areaDuration}>
            <Text style={styles.areaDurationText}>
              ⏳소요시간{" "}
              <Text style={{ fontFamily: "Pretendard-Bold" }}>
                {data?.duration}시간
              </Text>
            </Text>
          </View>
        </View>
        <Text style={styles.description}>{data?.description}</Text>
        <View style={styles.fieldRow}>
          {data?.fields?.map(field => (
            <View style={styles.fieldTag} key={field?.id}>
              <Text style={styles.fieldText}>{field?.name}</Text>
            </View>
          ))}
        </View>

        <View style={styles.tutorCard}>
          <Text style={styles.tutorCardTitle}>강사 소개</Text>
          <View style={styles.tutorCardRow}>
            <View style={styles.avatarRow}>
              <Image
                style={styles.avatar}
                source={
                  data?.tutor?.user?.avatar
                    ? { uri: data?.tutor?.user?.avatar?.uri }
                    : defaultProfile
                }
              />
              <View>
                <Text style={styles.tutorText}>Tutor</Text>
                <Text style={styles.username}>
                  {data?.tutor?.user?.username}
                </Text>
              </View>
            </View>
            <Pressable
              style={styles.viewProfileButton}
              onPress={() =>
                navigate(SCREENS.PROFILE_DETAIL, { tutorId: data?.tutor?.id })
              }
            >
              <Text style={styles.viewProfileText}>프로필 보기</Text>
            </Pressable>
          </View>
          <Text style={styles.tutorIntoruduction}>
            {data?.tutor?.introduction}
          </Text>
          <View style={[styles.fieldRow, { marginBottom: 0 }]}>
            {data?.tutor?.fields?.map(field => (
              <View style={styles.fieldTag} key={field?.id + "tutor"}>
                <Text style={styles.fieldText}>{field?.name}</Text>
              </View>
            ))}
          </View>
        </View>



        <View style={{marginTop: 15}}>

          <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingHorizontal: theme.spacing.default }}>
            <View style={{ borderBottomColor: '#cdcdcd', borderBottomWidth: 1, paddingBottom: 10, marginBottom: 10, width: '100%' }}>
              <Text style={[styles.fieldText, {fontWeight:'bold'}]}>리뷰작성</Text>
            </View>

            <StarRating
              rating={rating || 5}
              enableHalfStar={false}
              onChange={(e) => {
                setRating(e);
              }}
            />
            <View style={[styles.classesWrapper, { width: '100%' }]}>
              <TextInput
                multiline={true}
                numberOfLines={4}
                style={{ width: '100%', height: 100, borderColor: '#cdcdcd', color: 'black', borderWidth: 1, borderRadius: 5, padding: 10 }}
                value={reviewText} onChange={(e) => {
                  setReviewText(e.nativeEvent.text);
                }} />
            </View>
            <Pressable style={[styles.mentorButton, { paddingHorizontal: 20, paddingVertical: 5, width: '100%', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }]} onPress={async () => {
              if (reviewText.length < 1) {
                setAlertMessage('리뷰를 입력해주세요.');
                return;
              }
              console.log("리뷰쓰기", data?.id, rating, reviewText, user?.id);

              writeReview({
                variables: {
                  classId: data?.id,
                  score: parseInt(rating),
                  content: reviewText,
                  userId: user?.id
                }
              })
            }}><Text style={[styles.mentorButtonText]}>작성하기</Text></Pressable>
          </View>

          <View style={styles.classesWrapper}>

            {reviewsdata?.getAllReviews?.map((
              (r, i) => {
                return (
                  <View style={{ borderBottomColor: '#cdcdcd', borderBottomWidth: 1, paddingBottom: 10, paddingTop: 10 }} key={'review' + i}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Image style={{ width: 30, height: 30, borderRadius: 15, marginRight: 10 }}
                          resizeMode="cover" source={r?.user?.avatar ? { uri: r?.user?.avatar?.uri } : defaultProfile} />
                        <Text style={styles.fieldText}>
                          {r?.user?.username}님
                        </Text>


                      </View>
                      <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                        <Text style={{ fontSize: theme.font.caption1, fontFamily: "Pretendard-Regular", color: theme.color.black, }}>{new Date(r?.createdAt).toLocaleString('ko-kr')}</Text>

                        <StarRating
                          starSize={19}
                          onChange={() => { }}
                          style={{ marginHorizontal: 1, marginVertical: 1 }}
                          starStyle={{ marginHorizontal: 1, marginVertical: 1 }}
                          rating={r?.score || 5}
                        />
                      </View>
                    </View>
                    <Text style={{
                      fontSize: theme.font.caption1,
                      fontFamily: "Pretendard-Regular",
                      color: theme.color.black,
                    }}>[{r?.class?.title || ''}] {r?.content}</Text>
                  </View>)

              })
            )}
          </View>

        </View>






      </ScrollView>
      <View style={styles.footer}>
        <Pressable
          style={[
            styles.bottomButton,
            { opacity: user?.id === data?.tutor?.id ? 0.5 : 1 },
          ]}
          disabled={user?.id === data?.tutor?.id}
          onPress={async () => await onFooterButtonPress(data?.tutor?.id)}
        >
          <Image style={styles.bottomButtonIcon} source={presentationIcon} />
          <Text style={styles.bottomButtonText}>강의 요청</Text>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({

  classesWrapper: {
    paddingHorizontal: theme.spacing.default,
    paddingVertical: theme.spacing.normal,
  },
  classTitle: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.default,
  },
  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    position: "relative",
    padding: theme.spacing.default,
  },
  coverPhoto: {
    width: "100%",
    height: 110,
    borderRadius: 8,
    marginBottom: theme.spacing.middle,
  },
  title: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.large,
  },
  areaDurationWrapper: {
    paddingVertical: theme.spacing.small,
    borderRadius: 8,
    backgroundColor: theme.color.grayBox,
    marginBottom: theme.spacing.middle,
    flexDirection: "row",
    alignItems: "center",
  },
  areaDuration: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  areaDurationText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: "#000000",
  },
  description: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: "#000000",
    marginBottom: theme.spacing.middle,
  },
  fieldRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: -theme.spacing.xxSmall2,
    marginBottom: theme.spacing.section,
  },
  fieldTag: {
    borderRadius: 43,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: theme.spacing.xxSmall2,
    borderWidth: 1,
    borderColor: theme.color.border,
    backgroundColor: theme.color.white,
    margin: theme.spacing.xxSmall,
  },
  fieldText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
  },

  tutorCard: {
    padding: theme.spacing.default,
    borderRadius: 8,
    backgroundColor: theme.color.white,
    borderWidth: 1,
    borderColor: theme.color.border,
    flexDirection: "column",
    alignItems: "flex-start",
  },
  tutorCardTitle: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
    marginBottom: theme.spacing.default,
  },
  tutorCardRow: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing.default,
  },
  avatarRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    width: 70,
    height: 70,
    borderRadius: 70 / 2,
    marginRight: theme.spacing.middle,
  },
  tutorText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
  },
  username: {
    fontSize: theme.font.body1,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
  },
  viewProfileButton: {
    paddingHorizontal: theme.spacing.small,
    paddingVertical: theme.spacing.xxSmall2,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.color.border,
    backgroundColor: theme.color.white,
  },
  viewProfileText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.gray2,
  },
  tutorIntoruduction: {
    fontSize: theme.font.body1,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
    marginBottom: theme.spacing.default,
  },
  footer: {
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing.default,
    backgroundColor: theme.color.white,
  },
  bottomButton: {
    flex: 1,
    marginHorizontal: theme.spacing.xxSmall2,
    borderRadius: 8,
    paddingVertical: theme.spacing.default,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.color.primary,
  },
  bottomButtonIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing.xxSmall2,
  },
  bottomButtonText: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.white,
  },
  mentorButton: {
    backgroundColor: theme.color.primary,
  },
  mentorButtonText: {
    color: theme.color.white,
  },
  tutorButton: {
    borderWidth: 1,
    borderColor: theme.color.primary,
    backgroundColor: theme.color.white,
  },
  tutorButtonText: {
    color: theme.color.primary,
  },
});

export default ClassDetailContainer;
