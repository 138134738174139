export async function getCurrentDeviceTokenFromStorage() {}

/**
 * store device token to async storage,
 * if token is null, remove device token from async storage
 * @param {string} type - store or remove
 * @param {string} token
 */
export async function setCurrentDeviceTokenToStorage(type, token) {}

/**
 * add messaging token to server to manage device tokens for user
 */
export async function updateMessagingTokenToServer(client) {}

/**
 * delete messaging token to server
 */
export async function deleteMessagingTokenToServer(client) {}
