import React from "react";
import {
  Image,
  Linking,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { theme } from "../../../../utilities/theme";
import useProfileTabContainer from "./ProfileTabContainer.hook";
import TextButton from "../../../common/components/TextButton";
import ProfileTabMenuButton from "../components/ProfileTabMenuButton";
import { useApolloClient } from "@apollo/client";
import { firebaseSignOut } from "../../../../lib/firebase/auth/firebaseAuth";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import defaultProfile from "../../../../assets/images/default_profile_image.png";
import arrowBlackIcon from "../../../../assets/images/arrow_right_line_black.png";
import TabTitle from "../../../common/components/TabTitle";
import ProfileTabToggleOverlay from "../components/ProfileTabToggleOverlay";
import { isWeb } from "../../../../utilities/constants";
import { deleteMessagingTokenToServer } from "../../../../lib/firebase/pushNotification/pushNotificationHelper";
import { getVersion } from "../../../../utilities/getVersion";

function ProfileTabContainer() {
  const client = useApolloClient();
  const { navigate } = useNavigation();
  const { name } = useRoute();

  const {
    models: { loading, data, showMentorOverlay, showTutorOverlay },
    operations: { toggleMentorOverlay, toggleTutorOverlay },
  } = useProfileTabContainer();

  if (loading) return <View />;

  const menus = [
    {
      title: "계정 정보",
      onPress: () => {
        navigate(SCREENS.MANAGE_ACCOUNT);
      },
    },
    {
      title: "문의하기",
      rightText: "k-carphq@daum.net",
      onPress: () => {
        Linking.canOpenURL("mailto:k-carphq@daum.net").then(possible => {
          if (possible) {
            return Linking.openURL("mailto:k-carphq@daum.net");
          }
        });
      },
    },
    {
      title: "이용약관",
      onPress: () => {
        navigate(SCREENS.TERMS);
      },
    },
    {
      title: "개인정보 보호정책",
      onPress: () => {
        navigate(SCREENS.PRIVACY_POLICY);
      },
    },
  ];

  const isMentor = data?.mentor?.verified;
  const isTutor = data?.tutor?.verified;

  return (
    <View style={{ flex: 1, position: "relative" }}>
      <TabTitle title="프로필" />

      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyles}
      >
        <View style={styles.profileCard}>
          <View style={styles.avatarWrapper}>
            <Image
              style={styles.avatar}
              source={
                data?.avatar?.uri ? { uri: data?.avatar?.uri } : defaultProfile
              }
            />
            {(isMentor || isTutor) && (
              <View
                style={[
                  styles.userTypeTag,
                  {
                    borderColor: isTutor
                      ? theme.color.primary
                      : theme.color.secondary,
                  },
                ]}
              >
                <Text
                  style={[
                    styles.userTypeText,
                    {
                      color: isTutor
                        ? theme.color.primary
                        : theme.color.secondary,
                    },
                  ]}
                >
                  {isTutor ? "Tutor" : "Mentor"}
                </Text>
              </View>
            )}
          </View>

          <Pressable
            style={styles.usernameRow}
            onPress={() => navigate(SCREENS.UPDATE_PROFILE)}
          >
            <Text style={styles.username}>{data?.username}</Text>
            <Image style={styles.arrowIcon} source={arrowBlackIcon} />
          </Pressable>
          <Text style={styles.introduction}>
            {isTutor
              ? data?.tutor?.introduction
              : isMentor
              ? data?.mentor?.introduction
              : data?.introduction || ""}
          </Text>
          {(isMentor || isTutor) && (
            <View style={styles.toggleActiveWrapper}>
              <View style={styles.toggleActive}>
                <View style={styles.toggleActiveTop}>
                  <View
                    style={[
                      styles.toggleActiveStatus,
                      {
                        backgroundColor: isTutor
                          ? data?.tutor?.mentorActive
                            ? theme.color.primary
                            : theme.color.error
                          : data?.mentor?.active
                          ? theme.color.primary
                          : theme.color.error,
                      },
                    ]}
                  />
                  <Text style={styles.toggleActiveButtonText}>
                    이타멘토링 활동
                    {isTutor
                      ? data?.tutor?.mentorActive
                        ? " 중"
                        : "중단"
                      : data?.mentor?.active
                      ? " 중"
                      : "중단"}
                  </Text>
                </View>
                <Pressable
                  style={styles.toggleActiveButton}
                  onPress={() => toggleMentorOverlay(true)}
                >
                  <Text style={styles.toggleActiveButtonText}>
                    {isTutor
                      ? data?.tutor?.mentorActive
                        ? "활동중단"
                        : "활동시작"
                      : data?.mentor?.active
                      ? "활동중단"
                      : "활동시작"}
                  </Text>
                </Pressable>
              </View>
              {isTutor && (
                <View style={[styles.toggleActive, { borderLeftWidth: 1 }]}>
                  <View style={styles.toggleActiveTop}>
                    <View
                      style={[
                        styles.toggleActiveStatus,
                        {
                          backgroundColor: data?.tutor?.active
                            ? theme.color.primary
                            : theme.color.error,
                        },
                      ]}
                    />
                    <Text style={styles.toggleActiveButtonText}>
                      강의 활동{data?.tutor?.active ? " 중" : "중단"}
                    </Text>
                  </View>
                  <Pressable
                    style={styles.toggleActiveButton}
                    onPress={() => toggleTutorOverlay(true)}
                  >
                    <Text style={styles.toggleActiveButtonText}>
                      {data?.tutor?.active ? "활동중단" : "활동시작"}
                    </Text>
                  </Pressable>
                </View>
              )}
            </View>
          )}
        </View>

        <View style={styles.registerButtonsRow}>
          {data?.tutor?.id ? (
            <TextButton
              text={isTutor ? "강의 관리" : "강사 승인중"}
              buttonStyle={[styles.registerButton]}
              disabled={!data?.tutor?.verified}
              onPress={() => navigate(SCREENS.MANAGE_CLASS_ACTIVE)}
            />
          ) : data?.mentor?.id ? (
            <>
              {!isMentor && (
                <TextButton
                  text="멘토 승인중"
                  textStyle={{ color: theme.color.black }}
                  buttonStyle={[
                    styles.registerButton,
                    {
                      marginRight: theme.spacing.small,
                      backgroundColor: "#ffd966",
                    },
                  ]}
                  disabled
                />
              )}
              <TextButton
                text="강사 지원하기"
                textStyle={{ color: theme.color.black }}
                buttonStyle={[
                  styles.registerButton,
                  { backgroundColor: "#ffa559" },
                ]}
                onPress={() =>
                  navigate(SCREENS.REQUEST_TUTOR, { entryScreenName: name })
                }
              />
            </>
          ) : (
            <>
              <TextButton
                text="멘토 지원하기"
                textStyle={{ color: theme.color.black }}
                buttonStyle={[
                  styles.registerButton,
                  {
                    marginRight: theme.spacing.small,
                    backgroundColor: "#ffd966",
                  },
                ]}
                onPress={() =>
                  navigate(SCREENS.REQUEST_MENTOR, { entryScreenName: name })
                }
              />
              <TextButton
                text="강사 지원하기"
                textStyle={{ color: theme.color.black }}
                buttonStyle={[
                  styles.registerButton,
                  { backgroundColor: "#ffa559" },
                ]}
                onPress={() =>
                  navigate(SCREENS.REQUEST_TUTOR, { entryScreenName: name })
                }
              />
            </>
          )}
        </View>

        <View style={styles.menuWrapper}>
          {menus.map(menu => (
            <ProfileTabMenuButton
              key={menu.title}
              title={menu.title}
              rightText={menu.rightText}
              onPress={menu.onPress}
            />
          ))}
        </View>

        <TextButton
          text="로그아웃"
          buttonStyle={{
            backgroundColor: theme.color.grayBox,
            borderWidth: 1,
            borderColor: theme.color.border,
            marginBottom: theme.spacing.large,
          }}
          textStyle={{
            color: theme.color.gray1,
            fontSize: theme.font.body2,
            fontWeight: "normal",
          }}
          onPress={async () => {
            if (!isWeb) {
              await deleteMessagingTokenToServer(client);
            }
            await client.clearStore();
            await firebaseSignOut();
          }}
        />

        <Text style={styles.version}>버전 {getVersion()}</Text>
      </ScrollView>
      {showMentorOverlay && (
        <ProfileTabToggleOverlay
          data={data}
          isTutor={isTutor}
          closeOverlay={() => toggleMentorOverlay(false)}
        />
      )}
      {showTutorOverlay && (
        <ProfileTabToggleOverlay
          data={data}
          isTutoring={true}
          isTutor={isTutor}
          closeOverlay={() => toggleTutorOverlay(false)}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  contentContainerStyles: {
    paddingHorizontal: theme.spacing.regular,
    flexDirection: "column",
    alignItems: "center",
  },
  profileCard: {
    width: "100%",
    paddingTop: theme.spacing.middle,
    paddingBottom: 0,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.color.border,
    backgroundColor: theme.color.white,
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing.default,
  },
  avatarWrapper: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing.default,
  },
  avatar: {
    width: 80,
    height: 80,
    borderRadius: 40,
  },
  userTypeTag: {
    position: "absolute",
    bottom: -8,
    borderRadius: 45,
    paddingHorizontal: theme.spacing.small,
    paddingVertical: theme.spacing.xxSmall2,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.color.white,
  },
  userTypeText: {
    fontSize: theme.font.caption2,
    fontFamily: "Pretendard-Bold",
  },
  usernameRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing.xxSmall2,
  },
  username: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  arrowIcon: {
    width: 24,
    height: 24,
  },

  introduction: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    textAlign: "center",
    marginBottom: theme.spacing.middle,
  },

  toggleActiveWrapper: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: theme.color.border,
  },
  toggleActive: {
    borderColor: theme.color.border,
    flex: 1,
    paddingVertical: theme.spacing.default,
    flexDirection: "column",
    alignItems: "center",
  },
  toggleActiveTop: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing.small,
  },
  toggleActiveStatus: {
    width: 12,
    height: 12,
    borderRadius: 12 / 2,
    marginRight: theme.spacing.xxSmall2,
  },

  toggleActiveText: {
    fontFamily: "Pretendard-Regular",
    color: theme.color.black,
    fontSize: theme.font.body2,
  },
  toggleActiveButton: {
    borderRadius: 52,
    paddingVertical: theme.spacing.xxSmall2,
    paddingHorizontal: theme.spacing.middle,
    borderWidth: 1,
    borderColor: theme.color.gray3,
  },
  toggleActiveButtonText: {
    fontFamily: "Pretendard-Regular",
    color: theme.color.gray2,
    fontSize: theme.font.caption1,
  },

  registerButtonsRow: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing.default,
  },
  registerButton: {
    flex: 1,
  },
  menuWrapper: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.color.grayBox,
    width: "100%",
    backgroundColor: theme.color.white,
    marginBottom: theme.spacing.default,
  },
  version: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.gray3,
  },
});

export default ProfileTabContainer;
