import React, { useState, useEffect } from "react";
import {
  Alert,
  Image,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TextInput
} from "react-native";
import useProfileDetailContainer from "./ProfileDetailContainer.hook";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import { theme } from "../../../../utilities/theme";
import defaultProfile from "../../../../assets/images/default_profile_image.png";
import messageIcon from "../../../../assets/images/message_line.png";
import presentationIcon from "../../../../assets/images/presentation_fill.png";
import LargeThumbnail from "../../../common/components/LargeThumbnail";
import { useAuthContext } from "../../../providers/AuthProvider";
import StarRating from "react-native-star-svg-rating";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_ALL_REVIEWS, WRITE_REVIEW } from "../../../../lib/apollo/gql/review";

function ProfileDetailContainer() {
  const { canGoBack, navigate, goBack } = useNavigation();
  const [tab, setTab] = useState(0);
  const [rating, setRating] = useState(0);
  const [reviewsdata, setReviewsdata] = useState([]);
  const [reviewText, setReviewText] = useState('');
  const [reviceclass, setReviceclass] = useState('');

  const [refresh, setRefresh] = useState(false);

  const {
    models: { loading, data, isMentor },
    operations: { onFooterButtonPress },
  } = useProfileDetailContainer();


  const [getAllReviews, { data: reviews, loading: reviewsLoading, refetch }] =
    useLazyQuery(GET_ALL_REVIEWS, { variables: { page: 1, maxPage: 5000, classId: 0 } });


  const [createReview] = useMutation(WRITE_REVIEW, {
    onCompleted: ({ ok }) => {
      setReviewText('');
      setRating(5);
      refetch();
      setRefresh(!refresh);
    },
  });


  useEffect(() => {
    if (reviceclass != '') {
      setTimeout(async () => {
        const { data } = await getAllReviews({ variables: { classId: reviceclass } })
        setReviewsdata([...data?.getAllReviews]);
      }, 300);
    } else {
      setReviewsdata([]);
      data?.classes?.map(
        async (tutorClass) => {
          const { data } = await getAllReviews({ variables: { classId: tutorClass?.id } })
          setReviewsdata([...reviewsdata, ...data?.getAllReviews]);
        }
      )
    }
  }, [data?.classes, reviceclass, refresh])


  const { user } = useAuthContext();

  const disabled = user?.id === data?.id;

  if (loading) return <View />;

  return (
    <View style={{ flex: 1, backgroundColor: theme.color.background }}>
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <NavigationBar
          title={isMentor ? "이타멘토 프로필" : "이타강사 프로필"}
          backButtonText=""
          onBackButtonPress={() =>
            canGoBack() ? goBack() : navigate(SCREENS.HOME)
          }
        />
        <View
          style={[
            styles.topBackground,
            {
              backgroundColor: isMentor
                ? theme.color.secondaryOpacity
                : theme.color.tertiaryOpacity,
            },
          ]}
        />
        <View style={styles.avatarWrapper}>
          <Image
            style={styles.avatar}
            source={
              data?.user?.avatar
                ? { uri: data?.user?.avatar?.uri }
                : defaultProfile
            }
          />
          <View
            style={[
              styles.userTypeTag,
              {
                borderColor: isMentor
                  ? theme.color.secondaryOpacity
                  : theme.color.tertiaryOpacity,
              },
            ]}
          >
            <Text
              style={[
                styles.userTypeText,
                {
                  color: isMentor
                    ? theme.color.secondaryOpacity
                    : theme.color.tertiary,
                },
              ]}
            >
              {isMentor ? "Mentor" : "Tutor"}
            </Text>
          </View>
        </View>

        <Text style={styles.username}>{data?.user?.username}</Text>
        {/* <Text style={styles.personalInfoText}>
          {!!data?.user?.dateOfBirth &&
            `${differenceInYears(
              new Date(),
              new Date(data?.user?.dateOfBirth),
            )}세 | `}
          {!!data?.user?.gender
            ? data?.user?.gender === GENDERS.FEMALE
              ? "여성"
              : "남성"
            : ""}
        </Text> */}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>소개</Text>
          <Text style={styles.sectionValue}>{data?.introduction}</Text>
        </View>
        {!isMentor && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>출강지역</Text>
            <Text style={styles.sectionValue}>{data?.areas?.[0]?.name}</Text>
          </View>
        )}
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>전문분야</Text>
          <View style={styles.fieldRow}>
            {data?.fields?.map(field => (
              <View key={field?.id} style={styles.fieldTag}>
                <Text style={styles.fieldText}>{field?.name}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>경력</Text>
          <Text style={styles.sectionValue}>{data?.experience}</Text>
        </View>


        {!isMentor && (
          <View style={styles.section2}>
            <Pressable onPress={() => { setTab(0) }} style={styles.section2button}><Text style={tab == 0 ? styles.section2activeTitle : styles.sectionTitle}>강의목록</Text></Pressable>
            <Pressable onPress={() => { setTab(1) }} style={styles.section2button}><Text style={tab == 1 ? styles.section2activeTitle : styles.sectionTitle}>리뷰</Text></Pressable>
          </View>
        )}

        {tab == 0 && (
          <View>

            {/* CLASSES */}
            {!isMentor && (
              <View style={styles.classesWrapper}>
                <Text style={styles.classTitle}>
                  ✅ {data?.user?.username} 강사의 강의
                </Text>
                {data?.classes?.map(
                  tutorClass =>
                    tutorClass?.active && (
                      <LargeThumbnail key={tutorClass?.id} data={tutorClass} />
                    ),
                )}
              </View>
            )}
          </View>
        )}

        {tab == 1 && (
          <View>

            <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingHorizontal: theme.spacing.default }}>
              <View style={{ borderBottomColor: '#cdcdcd', borderBottomWidth: 1, paddingBottom: 10, marginBottom: 10, width: '100%' }}>
                <Text style={styles.fieldText}>리뷰작성</Text>
              </View>

              <View style={{ flexDirection: 'row', borderBottomColor: "#cdcdcd", borderBottomWidth: 1, width: '100%', padding: 0, height: 'auto', marginBottom: 10 }}>
                {/* CLASSES */}
                {!isMentor && (
                  <View style={{ flexDirection: 'row' }}>
                    {data?.classes?.map(
                      tutorClass => {
                        console.log(tutorClass);
                        if (reviceclass == '') {
                          setReviceclass(tutorClass?.id);
                        }
                        return (
                          tutorClass?.active && (
                            <Pressable key={'cat' + tutorClass?.id} style={[styles.avatarWrapper, { padding: 5, paddingTop: 0 }]} onPress={() => {
                              setReviceclass(tutorClass?.id);
                            }}>
                              <Text style={[styles.bottomButtonText, { color: 'black' }, reviceclass == tutorClass?.id && {
                                color:
                                  theme.color.primary
                              }]}>{tutorClass?.title}</Text>
                            </Pressable>
                          ))
                      }
                    )}
                  </View>
                )}
              </View>

              <StarRating
                rating={rating || 5}
                enableHalfStar={false}
                onChange={(e) => {
                  setRating(e);
                }}
              />
              <View style={[styles.classesWrapper, { width: '100%' }]}>
                <TextInput
                  multiline={true}
                  numberOfLines={4}
                  style={{ width: '100%', height: 100, borderColor: '#cdcdcd', color: 'black', borderWidth: 1, borderRadius: 5, padding: 10 }}
                  value={reviewText} onChange={(e) => {
                    setReviewText(e.nativeEvent.text);
                  }} />
              </View>
              <Pressable style={[styles.mentorButton, { paddingHorizontal: 20, paddingVertical: 5, width: '100%', alignItems: 'center', justifyContent: 'center', alignContent: 'center' }]} onPress={async () => {
                if (reviewText.length < 1) {
                  Alert.alert('리뷰를 입력해주세요.');
                  return;
                }
                createReview({
                  variables: {
                    classId: reviceclass,
                    score: parseInt(rating),
                    content: reviewText,
                    userId: user?.id
                  }
                })
              }}><Text style={[styles.mentorButtonText]}>작성하기</Text></Pressable>
            </View>

            {/* CLASSES */}
            {!isMentor && (
              <View style={styles.classesWrapper}>

                {reviewsdata?.map((
                  (r, i) => {
                    return (
                      <View style={{ borderBottomColor: '#cdcdcd', borderBottomWidth: 1, paddingBottom: 10, paddingTop: 10 }} key={'review' + i}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Image style={{ width: 30, height: 30, borderRadius: 15, marginRight: 10 }}
                              resizeMode="cover" source={r?.user?.avatar ? { uri: r?.user?.avatar?.uri } : defaultProfile} />
                            <Text style={styles.fieldText}>
                              {r?.user?.username}님
                            </Text>


                          </View>
                          <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
                            <Text style={{ fontSize: theme.font.caption1, fontFamily: "Pretendard-Regular", color: theme.color.black, }}>{new Date(r?.createdAt).toLocaleString('ko-kr')}</Text>

                            <StarRating
                              starSize={19}
                              onChange={() => { }}
                              style={{ marginHorizontal: 1, marginVertical: 1 }}
                              starStyle={{ marginHorizontal: 1, marginVertical: 1 }}
                              rating={r?.score || 5}
                            />
                          </View>
                        </View>
                        <Text style={{
                          fontSize: theme.font.caption1,
                          fontFamily: "Pretendard-Regular",
                          color: theme.color.black,
                        }}>[{r?.class?.title || ''}] {r?.content}</Text>
                      </View>)

                  })
                )}
              </View>
            )}

          </View>
        )}



      </ScrollView>
      <View style={styles.footer}>
        {!isMentor && (
          <Pressable
            style={[
              styles.bottomButton,
              styles.tutorButton,
              { opacity: disabled ? 0.5 : 1 },
            ]}
            disabled={disabled}
            onPress={async () => await onFooterButtonPress(data?.id)}
          >
            <Image style={styles.bottomButtonIcon} source={presentationIcon} />
            <Text style={[styles.bottomButtonText, styles.tutorButtonText]}>
              강의 요청
            </Text>
          </Pressable>
        )}
        <Pressable
          style={[
            styles.bottomButton,
            styles.mentorButton,
            { opacity: disabled ? 0.5 : 1 },
          ]}
          disabled={disabled}
          onPress={async () => await onFooterButtonPress(data?.id)}
        >
          <Image style={styles.bottomButtonIcon} source={messageIcon} />
          <Text style={[styles.bottomButtonText, styles.mentorButtonText]}>
            멘토링 신청
          </Text>
        </Pressable>
      </View>
    </View>
  );
}

export const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    position: "relative",

    padding: 0,
  },
  topBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: Platform.OS !== "ios" ? 150 : 200,
    backgroundColor: theme.color.secondary,
    zIndex: -2,
  },
  avatarWrapper: {
    marginTop: theme.spacing.middle,
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
  },
  avatar: {
    width: 160,
    height: 160,
    borderRadius: 160 / 2,
  },
  userTypeTag: {
    position: "absolute",
    bottom: -8,
    borderRadius: 45,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: theme.spacing.xxSmall2,
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.color.white,
  },
  userTypeText: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
  },
  username: {
    fontSize: theme.font.subtitle1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    textAlign: "center",
    marginBottom: theme.spacing.middle,
  },
  personalInfoText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.gray3,
    textAlign: "center",
  },
  section: {
    borderRadius: 8,
    backgroundColor: theme.color.white,
    padding: theme.spacing.default,
    flexDirection: "column",
    alignItems: "flex-start",
    marginHorizontal: theme.spacing.default,
    marginBottom: theme.spacing.normal,
  },
  section2: {
    backgroundColor: theme.color.white,
    borderRadius: 0,
    borderColor: '#efefef',
    borderBottomWidth: 1,
    padding: theme.spacing.default,
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginHorizontal: theme.spacing.default,
    marginBottom: theme.spacing.normal,
  },
  section3: {
    backgroundColor: theme.color.white,
    borderRadius: 0,
    borderColor: '#efefef',
    borderBottomWidth: 1,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: 0,
    flexDirection: "row",
  },
  section4: {
    backgroundColor: 'black',
    borderRadius: 5,
    border: 0,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: 0,
    flexDirection: "row",
  },
  section5: {
    backgroundColor: '#f0f0f0',
    borderRadius: 5,
    border: 0,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: 0,
    flexDirection: "row",
  },
  section2button: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  section2active: {
    fontWeight: 'bold',
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    color: theme.color.primary,
    height: '100%',
  },
  sectionTitle: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },

  section2activeTitle: {
    fontFamily: "Pretendard-Bold",
    fontSize: 13,
    color: theme.color.secondary,
    marginBottom: theme.spacing.xxSmall2,
  },

  section4activeTitle: {
    fontFamily: "Pretendard-Bold",
    fontSize: 13,
    color: 'white',
    marginBottom: theme.spacing.xxSmall2,
  },

  sectionValue: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  fieldRow: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginHorizontal: -theme.spacing.xxSmall2,
  },
  fieldTag: {
    borderRadius: 43,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: theme.spacing.xxSmall2,
    borderWidth: 1,
    borderColor: theme.color.border,
    backgroundColor: theme.color.white,
    margin: theme.spacing.xxSmall,
  },
  fieldText: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },

  classesWrapper: {
    paddingHorizontal: theme.spacing.default,
    paddingVertical: theme.spacing.normal,
  },
  classTitle: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.default,
  },

  footer: {
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing.default,
    backgroundColor: theme.color.white,
  },
  bottomButton: {
    flex: 1,
    marginHorizontal: theme.spacing.xxSmall2,
    borderRadius: 8,
    paddingVertical: theme.spacing.default,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  bottomButtonIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing.xxSmall2,
  },
  bottomButtonText: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
  },
  mentorButton: {
    backgroundColor: theme.color.primary,
  },
  mentorButtonText: {
    color: theme.color.white,
  },
  tutorButton: {
    borderWidth: 1,
    borderColor: theme.color.primary,
    backgroundColor: theme.color.white,
  },
  tutorButtonText: {
    color: theme.color.primary,
  },
  button: {
    width: "auto",
    paddingVertical: theme.spacing.normal / 2,
    paddingHorizontal: theme.spacing.normal,
    borderRadius: 4,
  },
  buttonText: {
    fontSize: theme.font.caption1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  editor: {
    height: "200px",
    padding: 0,
    borderColor: 'gray',
    borderWidth: 1,
    marginHorizontal: 30,
    marginVertical: 5,
    backgroundColor: 'white',
  },
});

export default ProfileDetailContainer;
