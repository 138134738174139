import { useEffect, useState } from "react";

/**
 *  Request user permission for IOS when user first launches the app
 *
 *  TODO: ADD PERMISSION MESSAGE IN info.plist
 */
export async function requestUserPermission() {}

/**
 * Event listener for refreshing device token
 */
export function listenDeviceTokenRefresh() {}

export async function registerBackgroundPushHandler() {}

/**
 * Add event listener to handle foregound messages
 */
export function useForegroundMessages() {
  // 푸시가 들어오면 푸시의 정보를 담는 상태입니다.
  const [initialRoute, setInitialRoute] = useState(null);

  useEffect(() => {}, []);

  return {
    models: {
      initialRoute,
    },
    operations: {
      setInitialRoute,
    },
  };
}

// /**
//  * Toggle subscription to topic of remote notification
//  * @param {boolean} isSubscribing - if user's push setting for notification setting is true, isSubscribing is true
//  */
// export async function setPushSubscription(isSubscribing) {
//   for await (let key of PUSH_TOPICS) {
//     if (isSubscribing) {
//       messaging().subscribeToTopic(key);
//     } else {
//       console.log("unsubscribe");
//       messaging().unsubscribeFromTopic(key);
//     }
//   }
// }
