import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../utilities/theme";
import uploadIcon from "../../../assets/images/upload_line.png";
import useAvatarInput from "./AvatarInput.hook";
import { isWeb } from "../../../utilities/constants";

function AvatarInput({ avatar, showBottomTag = false }) {
  const {
    refs: { inputRef },

    operations: { onAvatarPress, onInputChange },
  } = useAvatarInput();

  return (
    <View style={styles.wrapper}>
      <Pressable style={styles.circle} onPress={onAvatarPress}>
        {!avatar && (
          <>
            <Image style={styles.uploadIcon} source={uploadIcon} />
            <Text style={styles.uploadText}>업로드</Text>
          </>
        )}
        {avatar && (
          <Image
            style={styles.avatar}
            source={{ uri: avatar?.uri }}
            resizeMode="cover"
          />
        )}
        {isWeb ? (
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={onInputChange}
          />
        ) : null}
        {showBottomTag && (
          <View style={styles.bottomTag}>
            <Text style={styles.bottomTagText}>사진변경</Text>
          </View>
        )}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  circle: {
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 120,
    height: 120,
    borderRadius: 60,
    backgroundColor: theme.color.grayBox,
  },
  uploadIcon: {
    width: 24,
    height: 24,
    marginBottom: theme.spacing.xxSmall2,
  },
  uploadText: {
    color: theme.color.black,
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 60,
  },
  bottomTag: {
    paddingHorizontal: theme.spacing.small,
    paddingVertical: theme.spacing.xxSmall2,
    borderRadius: 53,
    borderWidth: 1,
    borderColor: theme.color.gray2,
    backgroundColor: theme.color.white,
    position: "absolute",
    bottom: 0,
    transform: [{ translateY: 12 }],
  },
  bottomTagText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.gray2,
  },
});

export default AvatarInput;
