import { useNavigation, useRoute } from "@react-navigation/native";
import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import { SCREENS } from "../../../../routes/screens";

function ManageClassTabs({ isActiveScreen }) {
  const { navigate } = useNavigation();
  const { name } = useRoute();

  return (
    <View style={styles.wrapper}>
      <Pressable
        style={[styles.button, isActiveScreen ? styles.buttonActive : {}]}
        onPress={() => {
          if (!isActiveScreen) {
            navigate(SCREENS.MANAGE_CLASS_ACTIVE);
          }
        }}
      >
        <Text
          style={[
            styles.buttonText,
            isActiveScreen ? styles.buttonTextActive : {},
          ]}
        >
          ✏️ 진행 중인 강의
        </Text>
      </Pressable>
      <Pressable
        style={[styles.button, !isActiveScreen ? styles.buttonActive : {}]}
        onPress={() => {
          if (isActiveScreen) {
            navigate(SCREENS.MANAGE_CLASS_INACTIVE);
          }
        }}
      >
        <Text
          style={[
            styles.buttonText,
            !isActiveScreen ? styles.buttonTextActive : {},
          ]}
        >
          중단한 강의
        </Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  button: {
    flex: 1,
    paddingVertical: theme.spacing.default,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
  },
  buttonActive: {
    borderBottomWidth: 3,
    borderColor: theme.color.primary,
  },
  buttonTextActive: {
    fontFamily: "Pretendard-Bold",
  },
});

export default ManageClassTabs;
