import React from "react";
import {
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import useCustomKeyBoardAvoidingView from "./CustomKeyBoardAvoidingView.hook";
import { isWeb } from "../../../utilities/constants";

/**
 * 키보드의 활성영역을 조절하고, 키보드 외의 다른 곳을 터치할 경우, 키보드를 비활성화시켜주는 공통 컴포넌트입니다.
 */
function CustomKeyBoardAvoidingView({ children, footer, verticalOffset = 80 }) {
  const { models, operations } = useCustomKeyBoardAvoidingView();
  const { keyboardVerticalOffset } = models;
  const { onLayout } = operations;

  if (isWeb) {
    return (
      <View style={{ flex: 1 }}>
        {children}
        {footer && <View>{footer}</View>}
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }} onLayout={onLayout}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={Platform.select({
          ios: keyboardVerticalOffset,
          android: -500,
        })}
      >
        <TouchableWithoutFeedback
          style={{ flex: 1 }}
          onPress={Keyboard.dismiss}
        >
          <View style={{ flex: 1 }}>{children}</View>
        </TouchableWithoutFeedback>
        {footer && <View>{footer}</View>}
      </KeyboardAvoidingView>
    </View>
  );
}

export default CustomKeyBoardAvoidingView;
