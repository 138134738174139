import { Platform } from "react-native";
import { SCREENS } from "../routes/screens";

import homeIcon from "../assets/images/home.png";
import homeActiveIcon from "../assets/images/home_active.png";
import searchIcon from "../assets/images/search.png";
import searchActiveIcon from "../assets/images/search_active.png";
import chatIcon from "../assets/images/chat.png";
import chatActiveIcon from "../assets/images/chat_active.png";
import profileIcon from "../assets/images/profile.png";
import profileActiveIcon from "../assets/images/profile_active.png";
import boardIcon from "../assets/images/board.png";
import boardActiveIcon from "../assets/images/board_active.png";

export const GENDERS = {
  MALE: "MALE",
  FEMALE: "FEMALE",
};

export const IMAGE_COMPRESSION_OPTIONS = {
  maxSizeMB: 0.5,
};

export const MOBILE_IMAGE_COMPRESSION_OPTIONS = {
  compressImageQuality: 0.7,
  forceJpg: true,
};

export const isWeb = Platform.OS === "web";

export const MESSAGE_STATUS = {
  SENDING_IN_PROGRESS: "SENDING_IN_PROGRESS",
  SENDING_FAILED: "SENDING_FAILED",
  SENT: "SENT",
};

export const SEARCH_USER_TYPES = {
  MENTOR: "mentor",
  TUTOR: "tutor",
  CLASS: "class",
};

export const SEARCH_USER_TYPE_TEXTS = {
  MENTOR: "멘토",
  TUTOR: "강사",
  CLASS: "강의",
};

export const BOTTOM_TABS = [
  {
    screen: SCREENS.HOME,
    name: "홈",
    activeIcon: homeActiveIcon,
    icon: homeIcon,
  },
  {
    screen: SCREENS.SEARCH_MENTOR,
    name: "검색",
    activeIcon: searchActiveIcon,
    icon: searchIcon,
  },
  {
    screen: SCREENS.BOARD_TAB,
    name: "커뮤니티",
    activeIcon: boardActiveIcon,
    icon: boardIcon,
  },
  {
    screen: SCREENS.CHAT_TAB,
    name: "채팅",
    activeIcon: chatActiveIcon,
    icon: chatIcon,
  },
  {
    screen: SCREENS.PROFILE_TAB,
    name: "프로필",
    activeIcon: profileActiveIcon,
    icon: profileIcon,
  },
];

export const BOTTOM_TABS_MOBILE = {
  HOME: {
    name: "홈",
    activeIcon: homeActiveIcon,
    icon: homeIcon,
  },
  SEARCH_TAB: {
    name: "검색",
    activeIcon: searchActiveIcon,
    icon: searchIcon,
  },
  BOARD_TAB: {
    name: "커뮤니티",
    activeIcon: boardActiveIcon,
    icon: boardIcon,
  },
  CHAT_TAB: {
    name: "채팅",
    activeIcon: chatActiveIcon,
    icon: chatIcon,
  },
  PROFILE_TAB: {
    name: "프로필",
    activeIcon: profileActiveIcon,
    icon: profileIcon,
  },
};
