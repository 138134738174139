import React from "react";
import {
  FlatList,
  Image,
  Pressable,
  RefreshControl,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { theme } from "../../../../utilities/theme";
import addIcon from "../../../../assets/images/add_line.png";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import ManageClassTabs from "../components/ManageClassTabs";
import useManageClassContainer from "./ManageClassContainer.hook";
import LargeThumbnail from "../../../common/components/LargeThumbnail";

function ManageClassContainer() {
  const { navigate } = useNavigation();
  const { name } = useRoute();

  const {
    models: { isActiveScreen, refreshing, fetchingMore, data, loading },
    operations: { onEndReached, onRefresh },
  } = useManageClassContainer();

  return (
    <View style={styles.wrapper}>
      <View style={styles.addButtonWrapper}>
        <Pressable
          style={styles.addButton}
          onPress={() => navigate(SCREENS.UPDATE_CLASS, { classId: "new" })}
        >
          <Image style={styles.addIcon} source={addIcon} />
          <Text style={styles.addButtonText}>강의 추가</Text>
        </Pressable>
      </View>
      <ManageClassTabs isActiveScreen={isActiveScreen} />
      {!loading &&
        (data?.length > 0 ? (
          <FlatList
            style={styles.flatList}
            contentContainerStyle={styles.contentContainerStyle}
            data={data}
            keyExtractor={item => item.id}
            renderItem={({ item }) => (
              <LargeThumbnail data={item} isMine={true} />
            )}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            onEndReached={onEndReached}
            onEndReachedThreshold={1}
          />
        ) : (
          <View style={styles.noneWrapper}>
            <Text style={styles.noneText}>
              {name === SCREENS.MANAGE_CLASS_ACTIVE
                ? `😬\n상단 강의 추가 버튼을 눌러\n강의를 개설해주세요`
                : "중단한 강의가 없습니다"}
            </Text>
          </View>
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  addButtonWrapper: {
    paddingHorizontal: theme.spacing.default,
    paddingVertical: 10,
  },
  addButton: {
    width: "100%",
    borderRadius: 8,
    backgroundColor: theme.color.primary,
    paddingVertical: theme.spacing.default,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  addIcon: {
    width: 20,
    height: 20,
  },
  addButtonText: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.white,
  },
  flatList: {
    flex: 1,
    backgroundColor: theme.color.background,
  },
  contentContainerStyle: {
    padding: theme.spacing.default,
  },

  noneWrapper: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 100,
  },
  noneText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.black,
    textAlign: "center",
  },
});

export default ManageClassContainer;
