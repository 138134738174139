import React from "react";
import { StyleSheet, Text, Pressable, View } from "react-native";
import InputWithLabel from "../../../common/components/InputWithLabel";
import { theme } from "../../../../utilities/theme";
import TextButton from "../../../common/components/TextButton";
import useSignInContainer from "./SignInContainer.hook";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function SignInContainer() {
  const { navigate } = useNavigation();

  const {
    models: {
      state: { email, password, emailError },
    },
    operations: {
      onInputChange,
      onFocus,
      onEmailBlur,
      onFindPasswordPress,
      isSubmitDisabled,
      onSubmit,
    },
  } = useSignInContainer();

  return (
    <View style={styles.wrapper}>
      <InputWithLabel
        wrapperStyle={styles.emailInputWrapper}
        label="이메일"
        placeholder="입력해주세요"
        returnKeyType="next"
        keyboardType="email-address"
        autoCapitalize="none"
        value={email}
        errorMessage={emailError}
        onChangeText={text => onInputChange(text, "email")}
        onFocus={onFocus}
        onBlur={onEmailBlur}
      />
      <InputWithLabel
        label="비밀번호"
        placeholder="비밀번호를 입력해주세요"
        secureTextEntry
        value={password}
        onChangeText={text => onInputChange(text, "password")}
        onFocus={onFocus}
        onSubmitEditing={() => {
          if (!isSubmitDisabled()) {
            onSubmit();
          }
        }}
      />
      <Pressable
        style={styles.findPasswordButton}
        onPress={onFindPasswordPress}
      >
        <Text style={styles.findPasswordText}>비밀번호 찾기</Text>
      </Pressable>
      <TextButton
        text="로그인"
        disabled={isSubmitDisabled()}
        onPress={onSubmit}
      />
      <View style={styles.bottomTextWrapper}>
        <Text style={styles.bottomText}>계정이 없으신가요? </Text>
        <Pressable onPress={() => navigate(SCREENS.SIGN_UP_QUIZ)}>
          <Text
            style={[styles.bottomText, { textDecorationLine: "underline" }]}
          >
            회원가입
          </Text>
        </Pressable>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: theme.spacing.regular,
    paddingTop: theme.spacing.section,
  },
  emailInputWrapper: {
    marginBottom: theme.spacing.default,
  },
  findPasswordButton: {
    alignSelf: "flex-end",
    marginVertical: theme.spacing.middle,
  },
  findPasswordText: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  bottomTextWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing.middle,
  },
  bottomText: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
  },
});

export default SignInContainer;
