import { gql } from "@apollo/client";

export const GET_RULES = gql`
  query GetRules {
    getRules {
      id
      mentorRule
      tutorRule
    }
  }
`;

export const GET_POLICIES = gql`
  query GetPolicies {
    getPolicies {
      id
      terms
      privacy
    }
    getCompanyInformation {
      id
      name
      address
      ceo
      registrationNumber
    }
  }
`;
