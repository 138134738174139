import React from "react";
import Select from "react-select";
import { theme } from "../../../utilities/theme";
import useAreaSelector from "./AreaSelector.hook";

function AreaSelector({
  wrapperStyle = {},
  label,
  labelStyle = {},
  isRequired = false,
  isSearch = false,
  value,
  onConfirm,
}) {
  const {
    models: { data, loading },
  } = useAreaSelector();

  if (loading) return <div />;

  const options = data?.map(area => ({ value: area?.id, label: area?.name }));

  if (isSearch) {
    options.unshift({ value: "all", label: "전체" });
  }

  return (
    <div style={{ ...styles.wrapper, ...wrapperStyle }}>
      <div style={{ ...styles.label, ...labelStyle }}>
        {label}
        {isRequired && <span style={styles.requiredSign}>*</span>}
      </div>
      <Select
        styles={{
          control: baseStyle => ({
            ...baseStyle,
            padding: theme.spacing.default,
            borderRadius: 8,
          }),
          valueContainer: baseStyle => ({
            ...baseStyle,
            padding: 0,
            margin: 0,
            fontFamily: "Pretendard-Regular",
            fontSize: theme.font.body1,
            color: theme.color.black,
            fontWeight: "normal",
          }),
          indicatorsContainer: baseStyle => ({
            ...baseStyle,
            padding: 0,
            margin: 0,
          }),
          indicatorSeparator: baseStyle => ({
            display: "none",
          }),
          dropdownIndicator: baseStyle => ({
            ...baseStyle,
            padding: 0,
          }),
          input: baseStyle => ({
            ...baseStyle,
            padding: 0,
            margin: 0,
          }),
          placeholder: baseStyle => ({
            ...baseStyle,
            color: theme.color.black,
            margin: 0,
            fontWeight: "normal",
          }),
          menuList: baseStyle => ({
            ...baseStyle,
            height: "auto",
            maxHeight: 200,
            padding: 0,
          }),

          option: baseStyle => ({
            ...baseStyle,
            fontFamily: "Pretendard-Regular",
            fontSize: theme.font.body1,
            padding: theme.spacing.default,
          }),
        }}
        value={value}
        options={options}
        placeholder="선택해 주세요"
        onChange={newValue => onConfirm(newValue)}
      />
    </div>
  );
}

const styles = {
  wrapper: {
    width: "100%",
    position: "relative",
  },
  label: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },
  requiredSign: {
    color: theme.color.error,
  },
  inputWrapper: {
    border: `1px solid ${theme.color.gray4}`,
    borderRadius: 8,
    padding: theme.spacing.default,
  },
  input: {
    color: theme.color.black,
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
  },
};

export default AreaSelector;
