import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import {
  SEARCH_USER_TYPES,
  SEARCH_USER_TYPE_TEXTS,
} from "../../../../utilities/constants";
import filterIcon from "../../../../assets/images/equalizer_line.png";

function SearchTabTopTab({ toggleShowSearchOverlay }) {
  const { navigate } = useNavigation();
  const { name } = useRoute();

  return (
    <View style={styles.wrapper}>
      <View style={styles.buttonsWrapper}>
        {Object.keys(SEARCH_USER_TYPES).map(key => {
          const isActive = name === "SEARCH_" + key;

          return (
            <Pressable
              key={key}
              style={[
                styles.topButton,
                {
                  backgroundColor: isActive ? theme.color.gray1 : "transparent",
                },
                key === "MENTOR"
                  ? styles.leftButton
                  : key === "TUTOR"
                  ? { borderLeftWidth: 0, borderRightWidth: 0 }
                  : styles.rightButton,
              ]}
              onPress={() => navigate(SCREENS[`SEARCH_${key}`])}
            >
              <Text
                style={[
                  styles.buttonText,
                  { color: isActive ? theme.color.white : theme.color.gray1 },
                ]}
              >
                {SEARCH_USER_TYPE_TEXTS[key]}
              </Text>
            </Pressable>
          );
        })}
      </View>

      <Pressable
        style={styles.filterButton}
        onPress={() => toggleShowSearchOverlay(true)}
      >
        <Image style={styles.filterIcon} source={filterIcon} />
        <Text style={styles.filterButtonText}>검색조건</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: theme.spacing.default,
  },
  buttonsWrapper: {
    flexDirection: "row",
    alignItems: "center",
    borderColor: theme.color.gray1,
    position: "relative",
    marginBottom: theme.spacing.default,
  },
  topButton: {
    flex: 1,
    paddingVertical: theme.spacing.small,
    borderColor: theme.color.gray1,
    borderWidth: 1,
  },
  leftButton: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  rightButton: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  buttonText: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.gray1,
    textAlign: "center",
  },

  filterButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  filterIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing.xxSmall,
  },
  filterButtonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.gray1,
  },
});

export default SearchTabTopTab;
