import { firebaseUpdatePassword } from "../../../../lib/firebase/auth/firebaseAuth";

const { useState } = require("react");

function useChangePasswordContainer() {
  const [state, setState] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
    passwordError: "",
    confirmPasswordError: "",
  });

  function onInputChange(text, key) {
    setState(prev => ({
      ...prev,
      [key]: text,
    }));
  }

  function onInputFocus(key) {
    if (key === "password" && state.passwordError) {
      setState(prev => ({
        ...prev,
        passwordError: "",
      }));
    } else if (key === "confirmPassword" && state.confirmPasswordError) {
      setState(prev => ({
        ...prev,
        confirmPasswordError: "",
      }));
    }
  }

  function onPasswordBlur() {
    if (state.password?.length < 6) {
      setState(prev => ({
        ...prev,
        passwordError: "비밀번호는 최소 6자 이상입니다",
      }));
    }
  }

  function onConfirmPasswordBlur() {
    if (state.confirmPassword?.length < 6) {
      setState(prev => ({
        ...prev,
        confirmPasswordError: "비밀번호는 최소 6자 이상입니다",
      }));
    }

    if (state.password !== state.confirmPassword) {
      setState(prev => ({
        ...prev,
        confirmPasswordError: "비밀번호가 일치하지 않습니다",
      }));
    }
  }

  function isSubmitDisabled() {
    const isValidCurrentPassword = state?.currentPassword?.length > 5;
    const isValidPassword = !state.passwordError && state.password.length > 5;
    const isValidConfirmPassword =
      !state.confirmPasswordError &&
      state.confirmPassword.length > 5 &&
      state.password === state.confirmPassword;

    return !(
      isValidCurrentPassword &&
      isValidPassword &&
      isValidConfirmPassword
    );
  }

  async function onSubmit() {
    const { currentPassword, password } = state;
    try {
      await firebaseUpdatePassword(currentPassword, password);
      alert("비밀번호가 변경되었습니다");
    } catch (error) {
      console.log(error);
    }
  }

  return {
    models: {
      state,
    },
    operations: {
      onInputChange,
      onInputFocus,
      onPasswordBlur,
      onConfirmPasswordBlur,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useChangePasswordContainer;
