import { useRoute } from "@react-navigation/native";
import React from "react";
import { FlatList, RefreshControl, StyleSheet, View } from "react-native";
import LargeThumbnail from "../../../common/components/LargeThumbnail";
import SmallThumbnail from "../../../common/components/SmallThumbnail";
import useViewAllContainer from "./ViewAllContainer.hook";
import { theme } from "../../../../utilities/theme";

function ViewAllContainer() {
  const { params } = useRoute();

  const {
    models: { loading, data, refreshing, fetchingMore },
    operations: { onEndReached, onRefresh },
  } = useViewAllContainer();

  function renderItem({ item }) {
    switch (params?.type) {
      case "CLASS": {
        return <LargeThumbnail wrapperStyle={{ flex: 1 }} data={item} />;
      }
      default: {
        return (
          <SmallThumbnail
            wrapperStyle={{ flex: 1 / 2 }}
            data={item}
            isTutor={params?.type === "TUTOR"}
          />
        );
      }
    }
  }

  if (loading) return <View />;

  return (
    <FlatList
      style={styles.flatList}
      contentContainerStyle={styles.contentContainerStyle}
      data={data}
      keyExtractor={item => item.id}
      numColumns={params?.type !== "CLASS" ? 2 : 1}
      renderItem={renderItem}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }
      onEndReached={onEndReached}
      onEndReachedThreshold={1}
    />
  );
}

const styles = StyleSheet.create({
  flatList: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.default,
    paddingHorizontal: theme.spacing.normal,
    paddingBottom: theme.spacing.section,
  },
});

export default ViewAllContainer;
