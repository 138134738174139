import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../utilities/theme";
import appleIcon from "../assets/images/download_appstore.png";
import googleIcon from "../assets/images/download_google_play.png";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "./screens";
import { navigate } from "./RoutesContainer";

const texts = [
  {
    text: "내게 맞는 이타멘토 찾기",
    screen: SCREENS.SEARCH_MENTOR,
  },
  {
    text: "우리 지역 이타강사 찾기",
    screen: SCREENS.SEARCH_TUTOR,
  },
  {
    text: "이타주의 커뮤니티",
    screen: SCREENS.BOARD_TAB,
  },
];

function WebLeftSection() {
  return (
    <View style={{ flex: 1, height: "100%" }}>
      <View style={styles.wrapper}>
        <View style={styles.inner}>
          <View style={[styles.section, { flex: 2 }]}>
            <Text
              style={styles.title}
            >{`당신의 가치있는\n경험을 공유하세요`}</Text>
            <Text
              style={styles.subtitle}
            >{`Share your valuable\nexperiences with people.`}</Text>
            <View style={styles.textCards}>
              {texts.map(({ text, screen }, index) => (
                <Pressable
                  style={styles.textCard}
                  key={text}
                  onPress={() => {
                    if (screen) {
                      navigate(screen);
                    }
                  }}
                >
                  <Text style={styles.textCardText}>{text}</Text>
                </Pressable>
              ))}
            </View>
          </View>

          <View style={[styles.section, { paddingBottom: "3vh" }]}>
            <Text style={styles.downloadTitle}>
              앱에서 더 편하게 이용하세요!
            </Text>
            <Text style={styles.downloadSubtitle}>다운로드로 이동합니다</Text>
            <View style={styles.downloadButtons}>
              <Pressable style={styles.downloadButton} onPress={() => {
                window.open("https://apps.apple.com/app/id6449804030", '_blank');
              }}>
                <Image
                  style={styles.downloadIcon}
                  source={appleIcon}
                  resizeMode="contain"
                />
              </Pressable>
              <Pressable style={styles.downloadButton} onPress={() => { 
                window.open("https://play.google.com/store/apps/details?id=com.carp.altruists", '_blank');
              }}>
                <Image
                  style={styles.downloadIcon}
                  source={googleIcon}
                  resizeMode="contain"
                />
              </Pressable>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    position: "relative",
  },
  inner: {
    flex: 1,
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "space-between",
    maxWidth: 255,
  },
  section: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  title: {
    fontFamily: "Pretendard-Bold",
    fontSize: theme.font.title2,
    color: theme.color.primary,
    marginBottom: theme.spacing.default,
  },
  subtitle: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.gray2,
    marginBottom: theme.spacing.section,
  },
  textCards: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginHorizontal: -theme.spacing.xxSmall2,
  },
  textCard: {
    paddingVertical: theme.spacing.small,
    paddingHorizontal: theme.spacing.normal,
    borderRadius: 4,
    backgroundColor: theme.color.primary,
    margin: theme.spacing.xxSmall2,
  },
  textCardText: {
    fontFamily: "Pretendard-Bold",
    fontSize: theme.font.body1,
    color: theme.color.white,
  },

  downloadTitle: {
    fontFamily: "Pretendard-Bold",
    fontSize: theme.font.body1,
    color: "#000000",
    marginBottom: theme.spacing.xxSmall2,
  },
  downloadSubtitle: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: "#000000",
    marginBottom: theme.spacing.default,
  },
  downloadButtons: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: -theme.spacing.xxSmall,
  },
  downloadButton: {
    flex: 1,
    margin: theme.spacing.xxSmall / 2,
  },
  downloadIcon: {
    width: 120,
    height: 36,
  },
});

export default WebLeftSection;
