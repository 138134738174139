import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import { config } from "../../../utilities/config/config";

/**
 * web firebaseAuth를 위한 코드입니다.
 * 1. firebase app을 initialize하고, firebase 함수들을 정의합니다.
 */
const firebaseConfig = {
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID,
  appId: config.FIREBASE_APP_ID,
  measurementId: config.FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export async function firebaseSignUp(email, password) {
  await createUserWithEmailAndPassword(auth, email, password);
}

export async function firebaseSignIn(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

export async function firebaseSignOut() {
  await signOut(auth);
}

export async function getFirebaseIdToken() {
  return await auth.currentUser?.getIdToken();
}

export async function firebaseSendPasswordResetEmail(email) {
  await sendPasswordResetEmail(auth, email);
}

export async function firebaseGetCurrentUser() {
  return auth.currentUser;
}

// 유저인증
export async function firebaseReAuthenticate(currentPassword) {
  const { currentUser } = auth;
  const credential = EmailAuthProvider.credential(
    currentUser.email,
    currentPassword,
  );

  await reauthenticateWithCredential(currentUser, credential);
}

export async function firebaseUpdatePassword(currentPassword, newPassword) {
  const user = auth.currentUser;
  await firebaseReAuthenticate(currentPassword);
  await updatePassword(user, newPassword);
}
