import {
  DefaultTheme,
  NavigationContainer,
  useNavigation,
  useRoute,
  createNavigationContainerRef,
  StackActions,
} from "@react-navigation/native";

import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React, { useEffect, useState } from "react";
import { SCREENS } from "./screens";
import LandingScreen from "../modules/landing/landing/screens/LandingScreen";
import { Image, StyleSheet, Text, View } from "react-native";
import SignInScreen from "../modules/landing/signIn/screens/SignInScreen";
import SignUpQuizScreen from "../modules/landing/signUpQuiz/screens/SignUpQuizScreen";
import SignUpScreen from "../modules/landing/signUp/screens/SignUpScreen";
import SignUpProvider from "../modules/landing/signUp/provider/SignUpProvider";
import CreateBasicProfileScreen from "../modules/landing/signUp/screens/CreateBasicProfileScreen";
import CreateProfileFieldsScreen from "../modules/landing/signUp/screens/CreateProfileFieldsScreen";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import HomeScreen from "../modules/home/home/screens/HomeScreen";
import { useAuthContext } from "../modules/providers/AuthProvider";
import RequestBasicInfoScreen from "../modules/request/requestBasicInfo/screens/RequestBasicInfoScreen";
import RequestIntroductionScreen from "../modules/request/requestIntroduction/screens/RequestIntroductionScreen";
import RequestFieldsScreen from "../modules/request/requestFields/screens/RequestFieldsScreen";
import RequestDoneScreen from "../modules/request/requestDone/screens/RequestDoneScreen";
import ProfileTabScreen from "../modules/profileTab/profileTab/screens/ProfileTabScreen";
import { useScreenWidthContext } from "../modules/providers/ScreenWidthProvider";
import { theme } from "../utilities/theme";
import UpdateClassBasicScreen from "../modules/class/updateClass/updateClassBasic/screens/UpdateClassBasicScreen";
import UpdateClassFieldsScreen from "../modules/class/updateClass/updateClassFields/screens/UpdateClassFieldsScreen";
import UpdateClassDoneScreen from "../modules/class/updateClass/updateClassDone/screens/UpdateClassDoneScreen";
import UpdateProfileScreen from "../modules/profileTab/updateProfile/screen/UpdateProfileScreen";
import { BOTTOM_TABS_MOBILE, isWeb } from "../utilities/constants";
import UpdateFieldsScreen from "../modules/profileTab/updateFields/screens/UpdateFieldsScreen";
import ProfileDetailScreen from "../modules/details/profileDetail/screens/ProfileDetailScreen";
import ClassDetailScreen from "../modules/details/classDetail/screens/ClassDetailScreen";
import ChatTabScreen from "../modules/chat/chatTab/screens/ChatTabScreen";
import ChatScreen from "../modules/chat/chat/screens/ChatScreen";
import SearchTabScreen from "../modules/search/searchTab/screens/SearchTabScreen";
import RequestRuleScreen from "../modules/request/requestRule/screens/ReuqestRuleScreen";
import ManageClassScreen from "../modules/profileTab/manageClass/screens/ManageClassScreen";
import PolicyScreen from "../modules/profileTab/policy/screens/PolicyScreen";
import ManageAccountScreen from "../modules/profileTab/manageAccount/screens/ManageAccountScreen";
import ChangePasswordScreen from "../modules/profileTab/manageAccount/screens/ChangePasswordScreen";
import { useForegroundMessages } from "../lib/firebase/pushNotification/pushNotification";
import { pushNotificationHandler } from "../lib/firebase/pushNotification/pushNotificationHandler";
import TabBarIcon from "./TabBarIcon";
import TabBarLabel from "./TabBarLabel";
import WebNavBar from "./WebNavBar.web";
import WebLeftSection from "./WebLeftSection";

import splash from "../assets/images/splash.png";
import ResignScreen from "../modules/profileTab/resign/screens/ResignScreen";
import ViewAllScreen from "../modules/home/viewAll/screens/ViewAllScreen";
import { useQuery } from "@apollo/client";
import { GET_MY_USER_PROFILE } from "../lib/apollo/gql/user";
import BoardTabScreen from "../modules/board/boardTab/screens/BoardTabScreen";
import BoardScreen from "../modules/board/board/screens/BoardScreen";
import BoardWriteScreen from "../modules/board/board/screens/BoardWriteScreen";
import BoardEditScreen from "../modules/board/board/screens/BoardEditScreen";

const config = {
  screens: {
    [SCREENS.LANDING]: "/landing",
    [SCREENS.SIGN_IN]: "/sign_in",
    [SCREENS.SIGN_UP]: "/sign_up",
    [SCREENS.SIGN_UP_QUIZ]: "/sign_up_quiz",
    [SCREENS.CREATE_BASIC_PROFILE]: "/basic_info",
    [SCREENS.CREATE_PROFILE_FIELDS]: "/create_profile_fields",

    [SCREENS.MAIN_TABS]: "/",
    [SCREENS.HOME]: "/home",
    [SCREENS.VIEW_ALL]: "/more",

    [SCREENS.SEARCH_TAB]: "/search",
    [SCREENS.SEARCH_MENTOR]: "/search_mentor",
    [SCREENS.SEARCH_TUTOR]: "/search_tutor",
    [SCREENS.SEARCH_CLASS]: "/search_class",

    [SCREENS.CHAT_TAB]: "/chats",
    [SCREENS.CHAT]: "/chat",


    [SCREENS.BOARD_TAB]: "/boards",
    [SCREENS.BOARD]: "/board",
    [SCREENS.BOARD_WRITE]: "/board_write",
    [SCREENS.BOARD_EDIT]: "/board_edit",

    [SCREENS.PROFILE_TAB]: "/profile",
    [SCREENS.MANAGE_CLASS_ACTIVE]: "/manage_class",
    [SCREENS.MANAGE_CLASS_INACTIVE]: "/manage_inactive_class",
    [SCREENS.UPDATE_PROFILE]: "/myProfile",
    [SCREENS.UPDATE_FIELDS]: "/update_fields",
    [SCREENS.PRIVACY_POLICY]: "/privacy_policy",
    [SCREENS.TERMS]: "/terms_of_service",
    [SCREENS.RESIGN]: "/resign",

    [SCREENS.PROFILE_DETAIL]: "/detail",
    [SCREENS.CLASS_DETAIL]: "/class",
    [SCREENS.MANAGE_ACCOUNT]: "/account",
    [SCREENS.CHANGE_PASSWORD]: "/password",

    [SCREENS.REQUEST_MENTOR_RULE]: "/mentor_rule",
    [SCREENS.REQUEST_MENTOR]: "/request_mentor",
    [SCREENS.REQUEST_MENTOR_BASIC]: "/mentor_info",
    [SCREENS.REQUEST_MENTOR_INTRODUCTION]: "/mentor_introduction",
    [SCREENS.REQUEST_MENTOR_FIELDS]: "/mentor_fields",
    [SCREENS.REQUEST_MENTOR_DONE]: "/mentor_done",

    [SCREENS.REQUEST_TUTOR_RULE]: "/tutor_rule",
    [SCREENS.REQUEST_TUTOR]: "/request_tutor",
    [SCREENS.REQUEST_TUTOR_BASIC]: "/tutor_info",
    [SCREENS.REQUEST_TUTOR_INTRODUCTION]: "/tutor_introduction",
    [SCREENS.REQUEST_TUTOR_FIELDS]: "/tutor_fields",
    [SCREENS.REQUEST_TUTOR_DONE]: "/tutor_done",

    [SCREENS.UPDATE_CLASS]: "/update_class/",
    [SCREENS.UPDATE_CLASS_BASIC]: "/basic",
    [SCREENS.UPDATE_CLASS_FIELDS]: "/fields",
    [SCREENS.UPDATE_CLASS_DONE]: "/done",
  },
};

export const navigationRef = createNavigationContainerRef();

/**
 * 앱의 현재 스크린을 교체하는 함수입니다.
 */
export function replace(screenName, params) {
  if (navigationRef?.isReady()) {
    navigationRef?.current?.dispatch(StackActions.replace(screenName, params));
  }
}

/**
 * 앱의 최 상단으로 이동하는 함수입니다.
 */
export function popToRoot() {
  if (navigationRef?.isReady()) {
    navigationRef?.current?.dispatch(StackActions.popToTop());
  }
}

/**
 * 현재 스크린에 이후 스크린을 추가하는 함수입니다(native)
 * navigate와는 다르게 무조건 위에 추가합니다.
 */
export function push(screenName, params) {
  if (navigationRef?.isReady()) {
    navigationRef?.current?.dispatch(StackActions.push(screenName, params));
  }
}

export function navigate(name, params) {
  if (navigationRef?.isReady()) {
    navigationRef?.current?.navigate(name, params);
  }
}

const linking = {
  prefixes: [],
  config,
};

const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "white",
  },
};

function RoutesContainer() {
  const { isPc, isTablet } = useScreenWidthContext();
  const { firebaseUser, user, loading } = useAuthContext();

  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    if (showSplash) {
      setTimeout(() => {
        setShowSplash(false);
      }, 2000);
    }
  }, [showSplash]);

  return (
    <View style={{ flex: 1 }}>
      {isPc && <WebNavBar />}
      <View style={styles.container}>
        <View style={styles.wrapper}>
          {isPc && <WebLeftSection />}
          <View style={isPc ? styles.sectionPc : styles.section}>
            <View
              style={
                isWeb || (!isWeb && (isPc || isTablet))
                  ? styles.sectionInner
                  : { height: "100%", width: "100%" }
              }
            >
              <NavigationContainer
                ref={navigationRef}
                linking={linking}
                fallback={<View />}
                theme={navigationTheme}
                documentTitle={{
                  formatter: (options, route) => "이타주의자들",
                }}
              >
                {showSplash ? (
                  <View style={{ flex: 1 }}>
                    <Image
                      style={{ width: "100%", height: "100%" }}
                      source={splash}
                      resizeMode="cover"
                    />
                  </View>
                ) : loading ? (
                  <View />
                ) : !!firebaseUser ? (
                  <MainStacks />
                ) : (
                  <LandingStacks />
                )}
              </NavigationContainer>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
  },
  wrapper: {
    flex: 1,
    flexDirection: "row",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 1000,
  },
  section: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  sectionPc: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
  },
  sectionInner: {
    maxWidth: theme.containerMaxWidth,
    height: "100%",
    width: "100%",
    borderWidth: 1,
    borderColor: theme.color.border,
  },
});

export default RoutesContainer;

const LandingStack = createNativeStackNavigator();

function LandingStacks() {
  return (
    <SignUpProvider>
      <LandingStack.Navigator
        screenOptions={{
          headerShown: false,
          contentStyle: {
            height: "100%",
          },
        }}
      >
        <LandingStack.Screen name={SCREENS.LANDING} component={LandingScreen} />
        <LandingStack.Screen name={SCREENS.SIGN_IN} component={SignInScreen} />
        <LandingStack.Screen
          name={SCREENS.SIGN_UP_QUIZ}
          component={SignUpQuizScreen}
        />
        <LandingStack.Screen name={SCREENS.SIGN_UP} component={SignUpScreen} />
        <LandingStack.Screen
          name={SCREENS.CREATE_BASIC_PROFILE}
          component={CreateBasicProfileScreen}
        />
        <LandingStack.Screen
          name={SCREENS.CREATE_PROFILE_FIELDS}
          component={CreateProfileFieldsScreen}
        />
      </LandingStack.Navigator>
    </SignUpProvider>
  );
}

const MainStack = createNativeStackNavigator();

function MainStacks() {
  const { models, operations } = useForegroundMessages();

  const { data, loading } = useQuery(GET_MY_USER_PROFILE);

  useEffect(() => {
    if (!isWeb && models?.initialRoute) {
      pushNotificationHandler(models?.initialRoute);
    }
  }, [models?.initialRoute]);

  return (
    <MainStack.Navigator screenOptions={{ headerShown: false }}>
      {!isWeb ? (
        <MainStack.Screen name={SCREENS.MAIN_TABS} component={MainTabs} />
      ) : (
        <>
          <MainStack.Screen name={SCREENS.HOME} component={HomeScreen} />
          <MainStack.Screen
            name={SCREENS.SEARCH_MENTOR}
            component={SearchTabScreen}
          />
          <MainStack.Screen
            name={SCREENS.SEARCH_TUTOR}
            component={SearchTabScreen}
          />
          <MainStack.Screen
            name={SCREENS.SEARCH_CLASS}
            component={SearchTabScreen}
          />
          <MainStack.Screen name={SCREENS.BOARD_TAB} component={BoardTabScreen} />
          <MainStack.Screen name={SCREENS.CHAT_TAB} component={ChatTabScreen} />
          <MainStack.Screen
            name={SCREENS.PROFILE_TAB}
            component={ProfileTabScreen}
          />
        </>
      )}
      <MainStack.Screen name={SCREENS.VIEW_ALL} component={ViewAllScreen} />

      <MainStack.Screen
        name={SCREENS.MANAGE_CLASS_ACTIVE}
        component={ManageClassScreen}
      />
      <MainStack.Screen
        name={SCREENS.MANAGE_CLASS_INACTIVE}
        component={ManageClassScreen}
      />

      <MainStack.Screen name={SCREENS.CHAT} component={ChatScreen} />
      <MainStack.Screen name={SCREENS.BOARD} component={BoardScreen} />
      <MainStack.Screen name={SCREENS.BOARD_WRITE} component={BoardWriteScreen} />
      <MainStack.Screen name={SCREENS.BOARD_EDIT} component={BoardEditScreen} />


      <MainStack.Screen
        name={SCREENS.UPDATE_PROFILE}
        component={UpdateProfileScreen}
      />

      <MainStack.Screen
        name={SCREENS.UPDATE_FIELDS}
        component={UpdateFieldsScreen}
      />

      <MainStack.Screen
        name={SCREENS.PROFILE_DETAIL}
        component={ProfileDetailScreen}
      />

      <MainStack.Screen
        name={SCREENS.MANAGE_ACCOUNT}
        component={ManageAccountScreen}
      />

      <MainStack.Screen
        name={SCREENS.CHANGE_PASSWORD}
        component={ChangePasswordScreen}
      />

      <MainStack.Screen
        name={SCREENS.CLASS_DETAIL}
        component={ClassDetailScreen}
      />

      <MainStack.Screen
        name={SCREENS.PRIVACY_POLICY}
        component={PolicyScreen}
      />

      <MainStack.Screen name={SCREENS.TERMS} component={PolicyScreen} />
      <MainStack.Screen name={SCREENS.RESIGN} component={ResignScreen} />

      {/* MODALS */}

      <MainStack.Screen
        name={SCREENS.REQUEST_MENTOR}
        component={RequestModalStacks}
        options={{
          animation: "slide_from_bottom",
          presentation: "fullScreenModal",
        }}
      />
      <MainStack.Screen
        name={SCREENS.REQUEST_TUTOR}
        component={RequestModalStacks}
        options={{
          animation: "slide_from_bottom",
          presentation: "fullScreenModal",
        }}
      />
      <MainStack.Screen
        name={SCREENS.UPDATE_CLASS}
        component={UpdateClassModalStacks}
        options={{
          animation: "slide_from_bottom",
          presentation: "fullScreenModal",
        }}
      />
    </MainStack.Navigator>
  );
}

const MainTab = createBottomTabNavigator();

function MainTabs() {
  return (
    <MainTab.Navigator screenOptions={{ headerShown: false }}>
      <MainTab.Screen
        name={SCREENS.HOME}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon focused={focused} source={BOTTOM_TABS_MOBILE.HOME} />
          ),
          tabBarLabel: ({ focused }) => (
            <TabBarLabel
              focused={focused}
              label={BOTTOM_TABS_MOBILE.HOME.name}
            />
          ),
        }}
        component={HomeScreen}
      />
      <MainTab.Screen
        name={SCREENS.SEARCH_TAB}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              source={BOTTOM_TABS_MOBILE.SEARCH_TAB}
            />
          ),
          tabBarLabel: ({ focused }) => (
            <TabBarLabel
              focused={focused}
              label={BOTTOM_TABS_MOBILE.SEARCH_TAB.name}
            />
          ),
        }}
        component={SearchTabs}
      />

      <MainTab.Screen
        name={SCREENS.BOARD_TAB}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              source={BOTTOM_TABS_MOBILE.BOARD_TAB}
            />
          ),
          tabBarLabel: ({ focused }) => (
            <TabBarLabel
              focused={focused}
              label={BOTTOM_TABS_MOBILE.BOARD_TAB.name}
            />
          ),
        }}
        component={BoardTabScreen}
      />


      <MainTab.Screen
        name={SCREENS.CHAT_TAB}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              source={BOTTOM_TABS_MOBILE.CHAT_TAB}
            />
          ),
          tabBarLabel: ({ focused }) => (
            <TabBarLabel
              focused={focused}
              label={BOTTOM_TABS_MOBILE.CHAT_TAB.name}
            />
          ),
        }}
        component={ChatTabScreen}
      />

      <MainTab.Screen
        name={SCREENS.PROFILE_TAB}
        options={{
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              source={BOTTOM_TABS_MOBILE.PROFILE_TAB}
            />
          ),
          tabBarLabel: ({ focused }) => (
            <TabBarLabel
              focused={focused}
              label={BOTTOM_TABS_MOBILE.PROFILE_TAB.name}
            />
          ),
        }}
        component={ProfileTabScreen}
      />
    </MainTab.Navigator>
  );
}
const SearchTab = createNativeStackNavigator();

function SearchTabs() {
  return (
    <SearchTab.Navigator screenOptions={{ headerShown: false }}>
      <SearchTab.Screen
        name={SCREENS.SEARCH_MENTOR}
        component={SearchTabScreen}
      />
      <SearchTab.Screen
        name={SCREENS.SEARCH_TUTOR}
        component={SearchTabScreen}
      />
      <SearchTab.Screen
        name={SCREENS.SEARCH_CLASS}
        component={SearchTabScreen}
      />
    </SearchTab.Navigator>
  );
}

const RequestModalStack = createNativeStackNavigator();

function RequestModalStacks() {
  const { params, name } = useRoute();
  return (
    <RequestModalStack.Navigator screenOptions={{ headerShown: false }}>
      {name === SCREENS.REQUEST_MENTOR ? (
        <>
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_MENTOR_RULE}
            initialParams={params}
            component={RequestRuleScreen}
          />
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_MENTOR_BASIC}
            initialParams={params}
            component={RequestBasicInfoScreen}
          />
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_MENTOR_INTRODUCTION}
            initialParams={params}
            component={RequestIntroductionScreen}
          />
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_MENTOR_FIELDS}
            initialParams={params}
            component={RequestFieldsScreen}
          />
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_MENTOR_DONE}
            initialParams={params}
            component={RequestDoneScreen}
          />
        </>
      ) : (
        <>
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_TUTOR_RULE}
            initialParams={params}
            component={RequestRuleScreen}
          />
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_TUTOR_BASIC}
            initialParams={params}
            component={RequestBasicInfoScreen}
          />
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_TUTOR_INTRODUCTION}
            initialParams={params}
            component={RequestIntroductionScreen}
          />
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_TUTOR_FIELDS}
            initialParams={params}
            component={RequestFieldsScreen}
          />
          <RequestModalStack.Screen
            name={SCREENS.REQUEST_TUTOR_DONE}
            initialParams={params}
            component={RequestDoneScreen}
          />
        </>
      )}
    </RequestModalStack.Navigator>
  );
}

const UpdateClassModalStack = createNativeStackNavigator();

function UpdateClassModalStacks() {
  const { params } = useRoute();
  return (
    <UpdateClassModalStack.Navigator screenOptions={{ headerShown: false }}>
      <UpdateClassModalStack.Screen
        name={SCREENS.UPDATE_CLASS_BASIC}
        initialParams={params}
        component={UpdateClassBasicScreen}
      />
      <UpdateClassModalStack.Screen
        name={SCREENS.UPDATE_CLASS_FIELDS}
        initialParams={params}
        component={UpdateClassFieldsScreen}
      />
      <UpdateClassModalStack.Screen
        name={SCREENS.UPDATE_CLASS_DONE}
        initialParams={params}
        component={UpdateClassDoneScreen}
      />
    </UpdateClassModalStack.Navigator>
  );
}
