import { isAfter, isBefore } from "date-fns";
import { useEffect, useState } from "react";

const { useQuery } = require("@apollo/client");
const {
  GET_MY_CHATS,
  UPDATE_CHAT,
} = require("../../../../lib/apollo/gql/chat");

function useChatTabContainer() {
  const [fetchingMore, setFetchMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const { loading, data, fetchMore, refetch, subscribeToMore } = useQuery(
    GET_MY_CHATS,
    {
      variables: {
        first: 10,
      },
    },
  );

  useEffect(() => {
    const getUpdatedChats = (prev, updateChat) => {
      //If prev data does not exist but receive new updateChat
      if (!prev?.getMyChats?.edges) {
        //Delete temporary chatId after
        updateChat.id = updateChat.chatId;
        delete updateChat.chatId;
        return [updateChat];
      }

      // This becomes true if the updateChat which supposed to come ealier, came later than chat already in prev.
      // Suppose in prev, for example, there is chat{id:1, time: 12:00:04am}
      // Then we received update chat such that chat{id:1, time: 12:00:03am}
      // It means this client app had received the chat in wrong order due to server's asynchronous behavior.
      // Therefore, just returns prev edges
      let foundDelayInUpdateChat = false;
      let chats = prev.getMyChats.edges.filter(edge => {
        if (
          updateChat.chatId === edge.id &&
          isBefore(
            new Date(updateChat.lastMessageAt),
            new Date(edge.lastMessageAt),
          )
        ) {
          foundDelayInUpdateChat = true;
        }
        return updateChat.chatId !== edge.id;
      });
      if (foundDelayInUpdateChat) {
        return prev.getMyChats.edges;
      }

      //Delete temporary chatId after
      updateChat.id = updateChat.chatId;

      // Insert
      let inserted = false;
      for (const index in chats) {
        if (
          isAfter(
            new Date(updateChat.lastMessageAt),
            new Date(chats[index].lastMessageAt),
          )
        ) {
          // Insert updateChat
          chats.splice(index, 0, updateChat);
          inserted = true;
          break;
        }
      }
      if (!inserted) {
        chats.push(updateChat);
      }

      return chats;
    };

    const unsubscribeGetMyChats = subscribeToMore({
      document: UPDATE_CHAT,
      updateQuery: (prev, { subscriptionData }) => {
        // Check if subscription data has received successfully
        const inValidSubscriptionData = !subscriptionData?.data;
        const inValidPreviousData = !prev?.getMyChats;

        if (inValidSubscriptionData || inValidPreviousData) {
          console.error("Invalid subscription data received in subscribe");
          console.error("prev: ", prev);
          console.error("subscriptionData: ", subscriptionData);
          return prev;
        }

        return {
          getMyChats: {
            ...prev.getMyChats,
            edges: getUpdatedChats(prev, subscriptionData.data.updateChat),
          },
        };
      },
    });

    return () => {
      unsubscribeGetMyChats();
    };
  }, []);

  function onEndReached() {
    const { hasNextPage, endCursor } = data?.getMyChats?.pageInfo;

    if (hasNextPage && !fetchingMore) {
      fetchMore(true);

      fetchMore({
        variables: {
          after: endCursor,
          first: 10,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => ({
          getMyChats: {
            ...fetchMoreResult.getMyChats,
            edges: [
              ...prevResult.getMyChats.edges,
              ...fetchMoreResult.getMyChats.edges,
            ],
          },
        }),
      }).finally(() => {
        setFetchMore(false);
      });
    }
  }

  const onRefresh = () => {
    setRefreshing(true);

    refetch()
      .catch(e => console.log(e))
      .finally(() => {
        setRefreshing(false);
      });
  };

  return {
    models: {
      loading,
      data: data?.getMyChats?.edges,
      refreshing,
      fetchingMore,
    },
    operations: {
      onEndReached,
      onRefresh,
    },
  };
}

export default useChatTabContainer;
