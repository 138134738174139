import React from "react";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { theme } from "../utilities/theme";
import logo from "../assets/images/logo_color.png";

function WebNavBar() {
  return (
    <div style={styles.wrapper}>
      <div style={styles.inner}>
        <a href="/">
          <img style={styles.logo} src={logo} />
        </a>
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: theme.topBarHeight,
    borderBottomColor: theme.color.border,
    borderBottomWidth: 1,
    alignItems: "center",
  },
  inner: {
    maxWidth: 1000,
    width: "100%",
    height: "100%",
    paddingHorizontal: theme.spacing.section,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    width: 120,
    height: 33,
    objectFit: "contain",
  },
};

export default WebNavBar;
