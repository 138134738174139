export const MIN_SCREEN_WIDTH = {
  DESKTOP: 1180,
  TABLET: 600,
};

export function getMediaQueryStrings() {
  const up = {};
  const down = {};

  Object.keys(MIN_SCREEN_WIDTH).map(key => {
    up[key] = `@media (min-width: ${MIN_SCREEN_WIDTH[key]}px)`;
    down[key] = `@media (max-width: ${MIN_SCREEN_WIDTH[key]}px)`;
  });

  return {media: {up, down}};
}
