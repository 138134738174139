import { useMutation } from "@apollo/client";
import {
  TOGGLE_ACTIVE_MENTOR,
  TOGGLE_ACTIVE_TUTOR,
} from "../../../../lib/apollo/gql/user";

function useProfileTabToggleOverlay(data) {
  const [toggleActiveMentor] = useMutation(TOGGLE_ACTIVE_MENTOR);
  const [toggleActiveTutor] = useMutation(TOGGLE_ACTIVE_TUTOR);

  function onToggleActiveMentor(active) {
    if (data?.tutor?.id) {
      toggleActiveTutor({
        variables: {
          active: data?.tutor?.active,
          mentorActive: active,
        },
      });
    } else {
      toggleActiveMentor({
        variables: {
          active,
        },
      });
    }
  }

  function onToggleActiveTutor(active) {
    toggleActiveTutor({
      variables: {
        mentorActive: data?.tutor?.mentorActive,
        active,
      },
    });
  }

  return {
    operations: {
      onToggleActiveMentor,
      onToggleActiveTutor,
    },
  };
}

export default useProfileTabToggleOverlay;
