import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { GET_MENTOR, GET_TUTOR } from "../../../../lib/apollo/gql/user";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect } from "react";
import {
  GET_CHAT_WITH_TUTOR_ID,
  START_CHAT,
} from "../../../../lib/apollo/gql/chat";
import { SCREENS } from "../../../../routes/screens";
import { GET_ALL_REVIEWS } from "../../../../lib/apollo/gql/review";

function useProfileDetailContainer() {
  const client = useApolloClient();
  const { navigate } = useNavigation();
  const { params } = useRoute();

  const isMentor = !!params?.mentorId;

  const [getMentor, { data: mentorData, loading: mentorLoading }] =
    useLazyQuery(GET_MENTOR);
  const [getTutor, { data: tutorData, loading: tutorLoading }] =
    useLazyQuery(GET_TUTOR);

  const [startChat] = useMutation(START_CHAT, {
    onCompleted: ({ startChat }) => {
      if (startChat?.id) {
        navigate(SCREENS.CHAT, { chatId: startChat?.id });
      }
    },
  });

  useEffect(() => {
    if (isMentor) {
      getMentor({
        variables: {
          mentorId: params?.mentorId,
        },
      });
    } else {
      getTutor({
        variables: {
          tutorId: params?.tutorId,
        },
      });
    }
  }, [isMentor]);

  async function onFooterButtonPress(tutorId) {
    const { data } = await client.query({
      query: GET_CHAT_WITH_TUTOR_ID,
      variables: {
        tutorId,
      },
    });

    if (data?.getChatWithTutorId) {
      navigate(SCREENS.CHAT, { chatId: data?.getChatWithTutorId?.id });
    } else {
      startChat({
        variables: {
          tutorId,
        },
      });
    }
  }

  return {
    models: {
      loading: mentorLoading || tutorLoading,
      data: isMentor ? mentorData?.getMentor : tutorData?.getTutor,
      isMentor,
    },
    operations: {
      onFooterButtonPress,
    },
  };
}

export default useProfileDetailContainer;
