import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import TopProgressBar from "../../../common/components/TopProgressBar";
import CreateProfileFieldsContainer from "../container/CreateProfileFieldsContainer";

function CreateProfileFieldsScreen() {
  const { navigate } = useNavigation();

  return (
    <Layout>
      <NavigationBar
        title="회원가입"
        onBackButtonPress={() => navigate(SCREENS.CREATE_BASIC_PROFILE)}
      />
      <TopProgressBar progress={3} total={3} />
      <CreateProfileFieldsContainer />
    </Layout>
  );
}

export default CreateProfileFieldsScreen;
