import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { useNavigation, useRoute } from "@react-navigation/native";
import { GET_CLASS } from "../../../../lib/apollo/gql/class";
import {
  GET_CHAT_WITH_TUTOR_ID,
  START_CHAT,
} from "../../../../lib/apollo/gql/chat";
import { SCREENS } from "../../../../routes/screens";

function useClassDetailContainer() {
  const client = useApolloClient();
  const { navigate } = useNavigation();
  const { params } = useRoute();

  const { data, loading } = useQuery(GET_CLASS, {
    variables: {
      classId: params?.classId,
    },
  });

  const [startChat] = useMutation(START_CHAT, {
    onCompleted: ({ startChat }) => {
      if (startChat?.id) {
        navigate(SCREENS.CHAT, { chatId: startChat?.id });
      }
    },
  });

  async function onFooterButtonPress(tutorId) {
    const { data } = await client.query({
      query: GET_CHAT_WITH_TUTOR_ID,
      variables: {
        tutorId,
      },
    });

    if (data?.getChatWithTutorId) {
      navigate(SCREENS.CHAT, { chatId: data?.getChatWithTutorId?.id });
    } else {
      startChat({
        variables: {
          tutorId,
        },
      });
    }
  }

  return {
    models: {
      loading,
      data: data?.getClass,
    },
    operations: {
      onFooterButtonPress,
    },
  };
}

export default useClassDetailContainer;
