import React from "react";
import Layout from "../../../common/components/Layout";
import { useRoute } from "@react-navigation/native";
import NavigationBar from "../../../common/components/NavigationBar";
import { SEARCH_USER_TYPE_TEXTS } from "../../../../utilities/constants";
import ViewAllContainer from "../container/ViewAllContainer";

function ViewAllScreen() {
  const { params } = useRoute();
  return (
    <Layout>
      <NavigationBar
        title={
          "#" + params?.fieldName + " " + SEARCH_USER_TYPE_TEXTS[params?.type]
        }
      />
      <ViewAllContainer />
    </Layout>
  );
}

export default ViewAllScreen;
