import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import BoardContainer from "../container/BoardContainer";
import TextButton from "../../../common/components/TextButton";
import TabTitle from "../../../common/components/TabTitle";
import { styles } from "../../../details/profileDetail/components/ProfileDetailContainer";

function BoardScreen() {
  const { canGoBack, navigate, goBack } = useNavigation();
  return (
    <Layout isTabScreen>
      <TabTitle title="커뮤니티" comp={
        <TextButton
          text="목록"
          buttonStyle={[styles.button, { backgroundColor: 'black'}]}
          textStyle={[styles.buttonText, { color: 'white' }]}
          onPress={() => {   canGoBack() ? goBack() : navigate(SCREENS.BOARD_TAB)
          }}
        />} />
      <BoardContainer />
    </Layout>
  );
}

export default BoardScreen;
