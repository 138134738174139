import React from "react";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { theme } from "../../../../utilities/theme";
import closeIcon from "../../../../assets/images/close_line.png";
import { useQuery } from "@apollo/client";
import { GET_MY_USER_PROFILE } from "../../../../lib/apollo/gql/user";
import TextButton from "../../../common/components/TextButton";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import defaultProfile from "../../../../assets/images/default_profile_image.png";

function RequestDoneContainer() {
  const { navigate } = useNavigation();
  const { params, name } = useRoute();

  const { data, loading } = useQuery(GET_MY_USER_PROFILE);

  if (loading) return <View />;

  const isMentor = name === SCREENS.REQUEST_MENTOR_DONE;

  return (
    <View style={styles.wrapper}>
      <Pressable
        style={styles.closeButton}
        onPress={() => navigate(params?.entryScreenName)}
      >
        <Image style={styles.closeIcon} source={closeIcon} />
        <Text style={styles.closeText}>닫기</Text>
      </Pressable>
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <Text style={styles.title}>{`${
          isMentor ? "이타멘토" : "이타강사"
        } 지원이\n완료되었습니다`}</Text>
        <Image
          style={styles.avatar}
          source={
            data?.getMyUserProfile?.avatar
              ? { uri: data?.getMyUserProfile?.avatar?.uri }
              : defaultProfile
          }
        />
        <Text style={styles.username}>{data?.getMyUserProfile?.username}</Text>
        <View style={styles.infoBox}>
          <Text style={styles.infoBoxTitle}>
            {`관리자 확인 후\n${
              isMentor ? "이타멘토" : "이타강사"
            }로 활동 가능합니다`}
          </Text>
          <Text style={styles.infoBoxDescription}>
            2-3일 내로 안내드리겠습니다.
          </Text>
        </View>
        <TextButton
          text="돌아가기"
          onPress={() => navigate(params?.entryScreenName)}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  closeButton: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingVertical: theme.spacing.normal,
    paddingHorizontal: theme.spacing.default,
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  closeText: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.section,
    paddingHorizontal: theme.spacing.regular,
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: theme.font.subtitle1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    lineHeight: 36,
    textAlign: "center",
    marginBottom: theme.spacing.middle,
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 60,
    marginBottom: theme.spacing.xxSmall2,
  },
  username: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.middle,
  },
  infoBox: {
    width: "100%",
    padding: theme.spacing.default,
    borderRadius: 16,
    backgroundColor: theme.color.grayBox,
    marginBottom: theme.spacing.middle,
    flexDirection: "column",
    alignItems: "center",
  },
  infoBoxTitle: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    lineHeight: 24,
    textAlign: "center",
    marginBottom: theme.spacing.small,
  },
  infoBoxDescription: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
  },
});

export default RequestDoneContainer;
