import React, {useState} from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import defaultProfile from "../../../../assets/images/default_profile_image.png";
import { differenceInDays, format } from "date-fns";
import { ko } from "date-fns/locale";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function BoardCard({ data, type, isUser, onPress }) {
  const { navigate } = useNavigation();
  const [parentWidth, setParentWidth] = useState(0);
  const onLayout = event => {
    const { width } = event.nativeEvent.layout;
    setParentWidth(width);
  };
  /*
    const isDeleted = isUser ? data?.tutor?.deletedAt : data?.user?.deletedAt;
    const avatar = isUser ? data?.tutor?.avatar : data?.user?.avatar;
    const username = isUser ? data?.tutor?.username : data?.user?.username;
    const isTutor = !!data?.tutor?.tutor?.id;
  */
  return (
    <Pressable
      style={styles.wrapper}
      onPress={() => {
        onPress();
        navigate(SCREENS.BOARD, { postId: data?.id, boardId: data?.boardId, postData: data, type });
      }}
      onLayout={onLayout}
    >
      <View style={styles.titleRow}>
        {/* <View style={styles.column}>
          <View style={styles.titleRow}> */}
            <Text style={styles.boardType}>{data.boardId==1?"자유게시판":data.boardId==2?"천일국 알바":data.boardId==3?"고민있어요":"수련/프로그램"}</Text>
            <Text style={[styles.title]} numberOfLines={1} lineBreakMode="tail">
              {data?.category && `[${data?.category}]`} {data?.title}
            </Text>
            {/*data?.hasUnreadMessage && <View style={styles.new} />*/}
          {/* </View>
        </View> */}
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', marginTop: 5 }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {/* <Image
            source={type == 1 ? defaultProfile : data?.user?.avatar ? { uri: data?.user?.avatar?.uri } : defaultProfile}
            style={styles.avatar}
          /> */}
          <Text style={styles.message}>{data?.boardId == 3 ? '익명' : (data?.user?.username || '관리자')}</Text>
          <Text style={[styles.message, {marginLeft: 10}]}>조회수 {data?.viewCnt}</Text>
          <Text style={[styles.message, {marginLeft: 10}]}>댓글 {data?.commentCnt}</Text>
        </View>
        <Text style={styles.lastMessageAt} numberOfLines={1} lineBreakMode="middle">
          {format(
            new Date(data?.createdAt),
            differenceInDays(new Date(), new Date(data?.createdAt)) < 1
              ? "aaa h:mm"
              : "yyyy-MM-dd aaa h:mm",
            { locale: ko },
          )}
        </Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing.small,
    paddingHorizontal: theme.spacing.regular,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#efefef',
  },
  avatar: {
    width: 15,
    height: 15,
    borderRadius: 15 / 2,
    marginRight: 3,
    marginTop: 3,
  },
  column: {
    flexDirection: "row",
    alignItems: "center",
  },
  titleRow: {
    width: '100%',
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    fontFamily: "Pretendard-Bold",
    fontSize: theme.font.body2,
    color: theme.color.black,
    flex: 1,
  },
  new: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.color.secondary,
  },
  message: {
    fontFamily: "Pretendard-Regular",
    fontSize: 12,
    color: '#b7b2c2',
  },
  lastMessageAt: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption2,
    color: '#b7b2c2',
  },
  boardType: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption3,
    color: '#b7b2c2',
    lineHeight: 15,
    backgroundColor: '#f5f6fa',
    borderRadius: 4,
    width: 54,
    textAlign: 'center',
    marginRight: 6,
  },
});

export default BoardCard;
