import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QuillEditor.css";
import useEditor from "./QuillEditor.hook";

function Editor({...rest}) {
  const {
    refs: { editorRef },
  } = useEditor();
  const Quill = ReactQuill.Quill;

  const Font = Quill.import("formats/font");
  Font.whitelist = ["Pretendard"];
  
  const Size = Quill.import("attributors/style/size");
  Size.whitelist = [
    "8px",
    "11px",
    "12px",
    "14px",
    "16px",
    "20px",
    "24px",
    "28px",
    "30px",
    "36px",
    "44px",
  ];

  const lineHeights = [
    0.85, 0.9, 0.95, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0,
  ];
  
  const Parchment = Quill.import("parchment");
  const lineHeightConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: lineHeights,
  };
  
  const LineHeightStyle = new Parchment.Attributor.Style(
    "line-height",
    "line-height",
    lineHeightConfig
  );
  
  Quill.register(Font, true);
  Quill.register(Size, true);
  Quill.register(LineHeightStyle, true);

  let Link = Quill.import('formats/link');
  Quill.register(Link, true);

  const modules = {
    toolbar: {
      container: [
        [{ font: Font.whitelist }],
        [{ size: Size.whitelist }],
        ["bold", "italic", "underline", "strike", "blockquote", "link"],
        [{ align: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["image", "video"],
      ],
    },
  };

  return (
    <div style={styles.wrapper}>
      <ReactQuill
        className="editor"
        ref={editorRef}
        theme="snow"
        placeholder="내용을 입력해주세요."
        style={{ width: "100%" }}
        preserveWhitespace
        modules={modules}
        {...rest}
      />
    </div>
  );
}

const styles = {
  wrapper: {
    position: "relative",
  },
};

export default Editor;
