import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import UpdateProfileContainer from "../container/UpdateProfileContainer";

function UpdateProfileScreen() {
  const { navigate } = useNavigation();
  return (
    <Layout>
      <NavigationBar
        title="내 프로필"
        onBackButtonPress={() => navigate(SCREENS.PROFILE_TAB)}
      />
      <UpdateProfileContainer />
    </Layout>
  );
}

export default UpdateProfileScreen;
