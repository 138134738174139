import { useQuery } from "@apollo/client";
import { useRoute } from "@react-navigation/native";
import { useState } from "react";
import { SCREENS } from "../../../../routes/screens";
import {
  SEARCH_MENTORS,
  SEARCH_TUTORS,
  SERARCH_CLASSES,
} from "../../../../lib/apollo/gql/search";

const QUERY_MAP = {
  [SCREENS.SEARCH_MENTOR]: SEARCH_MENTORS,
  [SCREENS.SEARCH_TUTOR]: SEARCH_TUTORS,
  [SCREENS.SEARCH_CLASS]: SERARCH_CLASSES,
};

function useSearchTabContainer() {
  const { name } = useRoute();

  const [showSearchOverlay, setShowSearchOverlay] = useState(false);
  const [fieldIds, setFieldIds] = useState([]);
  const [area, setArea] = useState(null);
  const [fetchingMore, setFetchMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const { data, loading, fetchMore, refetch } = useQuery(QUERY_MAP[name], {
    variables:
      name === SCREENS.SEARCH_MENTOR
        ? {
            first: 12,
            fieldIds,
          }
        : {
            first: 12,
            fieldIds,
            areaId: area?.value !== "all" ? area?.value : null,
          },
  });

  function toggleShowSearchOverlay(value) {
    setShowSearchOverlay(value);
  }

  function onFieldsChange(fieldIds) {
    setFieldIds(fieldIds);
  }

  function onAreaChange(area) {
    setArea(area);
  }

  function onEndReached() {
    const { hasNextPage, endCursor } =
      name === SCREENS.SEARCH_MENTOR
        ? data?.searchMentors?.pageInfo
        : name === SCREENS.SEARCH_TUTOR
        ? data?.searchTutors?.pageInfo
        : data?.searchClasses?.pageInfo;

    if (hasNextPage && !fetchingMore) {
      setFetchMore(true);

      fetchMore({
        variables:
          name === SCREENS.SEARCH_MENTOR
            ? {
                first: 12,
                fieldIds,
                after: endCursor,
              }
            : {
                first: 12,
                fieldIds,
                areaId: area?.value !== "all" ? area?.value : null,
                after: endCursor,
              },
      }).finally(() => {
        setFetchMore(false);
      });
    }
  }

  function onRefresh() {
    setRefreshing(true);

    refetch()
      .catch(e => console.log(e))
      .finally(() => {
        setRefreshing(false);
      });
  }

  return {
    models: {
      showSearchOverlay,
      fieldIds,
      area,
      loading,
      data:
        name === SCREENS.SEARCH_MENTOR
          ? data?.searchMentors?.edges
          : name === SCREENS.SEARCH_TUTOR
          ? data?.searchTutors?.edges
          : data?.searchClasses?.edges,
      refreshing,
      fetchingMore,
    },
    operations: {
      toggleShowSearchOverlay,
      onFieldsChange,
      onAreaChange,
      onEndReached,
      onRefresh,
    },
  };
}

export default useSearchTabContainer;
