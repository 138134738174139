import React from "react";
import { StyleSheet, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import useChangePasswordContainer from "./ChangePasswordContainer.hook";
import InputWithLabel from "../../../common/components/InputWithLabel";
import TextButton from "../../../common/components/TextButton";

function ChangePasswordContainer() {
  const {
    models: { state },
    operations: {
      onInputChange,
      onInputFocus,
      onPasswordBlur,
      onConfirmPasswordBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useChangePasswordContainer();

  const {
    currentPassword,
    password,
    confirmPassword,
    passwordError,
    confirmPasswordError,
  } = state;

  return (
    <View style={styles.wrapper}>
      <InputWithLabel
        label="현재 비밀번호"
        placeholder="6자 이상"
        secureTextEntry
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        returnKeyType="next"
        value={currentPassword}
        onChangeText={text => onInputChange(text, "currentPassword")}
      />
      <InputWithLabel
        label="새로운 비밀번호"
        placeholder="6자 이상"
        secureTextEntry
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        returnKeyType="next"
        value={password}
        errorMessage={passwordError}
        onChangeText={text => onInputChange(text, "password")}
        onFocus={() => onInputFocus("password")}
        onBlur={onPasswordBlur}
      />
      <InputWithLabel
        label="새로운 비밀번호 확인"
        placeholder="6자 이상"
        secureTextEntry
        wrapperStyle={{ marginBottom: theme.spacing.section }}
        value={confirmPassword}
        errorMessage={confirmPasswordError}
        onChangeText={text => onInputChange(text, "confirmPassword")}
        onFocus={() => onInputFocus("confirmPassword")}
        onBlur={onConfirmPasswordBlur}
        onSubmitEditing={() => {
          if (!isSubmitDisabled()) {
            onSubmit();
          }
        }}
      />
      <TextButton
        disabled={isSubmitDisabled()}
        text="변경"
        onPress={onSubmit}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing.middle,
    paddingHorizontal: theme.spacing.regular,
  },
});

export default ChangePasswordContainer;
