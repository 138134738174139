import { useEffect, useState } from "react";

const { useQuery } = require("@apollo/client");
const { GET_FIELDS } = require("../../../../lib/apollo/gql/field");

function useSearchConditionOverlay(fieldIds, _area) {
  const { data, loading } = useQuery(GET_FIELDS);
  const [selectedFieldIds, setSelectedFieldIds] = useState(new Set());
  const [area, setArea] = useState(null);

  useEffect(() => {
    if (fieldIds?.length > 0) {
      setSelectedFieldIds(new Set(fieldIds.map(fieldId => fieldId)));
    }
  }, [fieldIds, setSelectedFieldIds]);

  useEffect(() => {
    if (_area) {
      setArea(_area);
    }
  }, [_area, setArea]);

  function onFieldSelect(fieldId) {
    const currentSelectedFieldIds = new Set(selectedFieldIds);

    if (currentSelectedFieldIds.has(fieldId)) {
      currentSelectedFieldIds.delete(fieldId);
      setSelectedFieldIds(currentSelectedFieldIds);
    } else {
      currentSelectedFieldIds.add(fieldId);
      setSelectedFieldIds(currentSelectedFieldIds);
    }
  }

  function onAreaSelect(area) {
    setArea(area);
  }

  function onClearPress() {
    setSelectedFieldIds(new Set());
    setArea(null);
  }

  return {
    models: {
      loading,
      data: data?.getFields,
      selectedFieldIds,
      area,
    },
    operations: {
      onFieldSelect,
      onAreaSelect,
      onClearPress,
    },
  };
}

export default useSearchConditionOverlay;
