import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import SignUpQuizContainer from "../container/SignUpQuizContainer";

function SignUpQuizScreen() {
  const { navigate } = useNavigation();

  return (
    <Layout>
      <NavigationBar
        title="가입 퀴즈"
        onBackButtonPress={() => navigate(SCREENS.LANDING)}
      />
      <SignUpQuizContainer />
    </Layout>
  );
}

export default SignUpQuizScreen;
