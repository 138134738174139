import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";

function ChatDeleteOverlay({
  closeOverlay,
}) {

  return (
    <View style={styles.wrapper}>
      <View style={styles.inner}>
        <View style={styles.innerTop}>
          <Text style={styles.title}>{"나가기"}</Text>
          <Text style={styles.subTitle}>{"채팅을 종료하시겠습니까?"}</Text>
        </View>
        <View style={styles.bottomWrapper}>
          <Pressable
            style={styles.bottomButton}
            onPress={() => {
              closeOverlay(true);
            }}
          >
            <Text style={styles.bottomButtonText}>{"종료"}</Text>
          </Pressable>
          <Pressable
            style={[styles.bottomButton, { borderLeftWidth: 1 }]}
            onPress={() => {
              closeOverlay(false);
            }}
          >
            <Text style={styles.bottomButtonText}>{"취소"}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0, 31, 37, 0.5)",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: theme.spacing.default,
    zIndex: 1000,
  },
  inner: {
    width: "100%",
    backgroundColor: theme.color.white,
    borderRadius: 8,
  },
  innerTop: {
    paddingVertical: theme.spacing.section,
    flexDirection: "column",
    alignItems: "flex-start",
    paddingHorizontal: theme.spacing.large,
  },

  title: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.section,
    lineHeight: 30,
    textAlign: "center",
    width: "100%",
  },
  subTitle: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    lineHeight: 21,
    textAlign: "center",
    width: "100%",
  },
  body: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  bodyText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    lineHeight: 21,
    textAlign: "left",
  },

  bottomWrapper: {
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: theme.color.gray4,
  },
  bottomButton: {
    borderColor: theme.color.gray4,
    paddingVertical: theme.spacing.default,
    flex: 1,
  },
  bottomButtonText: {
    fontSize: theme.font.body2,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
    textAlign: "center",
  },
});

export default ChatDeleteOverlay;
