import { useQuery } from "@apollo/client";
import {
  GET_CLASSES_BY_FIELD,
  GET_MENTORS_BY_FIELD,
  GET_TUTORS_BY_FIELD,
} from "../../../../lib/apollo/gql/user";
import { useRoute } from "@react-navigation/native";
import { useState } from "react";

const QUERY_MAP = {
  MENTOR: GET_MENTORS_BY_FIELD,
  TUTOR: GET_TUTORS_BY_FIELD,
  CLASS: GET_CLASSES_BY_FIELD,
};

function useViewAllContainer() {
  const { params } = useRoute();
  const { fieldId, type } = params;

  const [fetchingMore, setFetchMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const { data, loading, fetchMore, refetch } = useQuery(QUERY_MAP[type], {
    variables: {
      first: 12,
      fieldId: fieldId,
    },
  });

  function onEndReached() {
    const { hasNextPage, endCursor } =
      type === "MENTOR"
        ? data?.getMentorsByField?.pageInfo
        : type === "TUTOR"
        ? data?.getTutorsByField?.pageInfo
        : data?.getClassesByField?.pageInfo;

    if (hasNextPage && !fetchingMore) {
      setFetchMore(true);

      fetchMore({
        variables: {
          first: 12,
          fieldId: fieldId,
          after: endCursor,
        },
      }).finally(() => {
        setFetchMore(false);
      });
    }
  }

  function onRefresh() {
    setRefreshing(true);

    refetch()
      .catch(e => console.log(e))
      .finally(() => {
        setRefreshing(false);
      });
  }

  return {
    models: {
      loading,
      data:
        type === "MENTOR"
          ? data?.getMentorsByField?.edges
          : type === "TUTOR"
          ? data?.getTutorsByField?.edges
          : data?.getClassesByField?.edges,
      refreshing,
      fetchingMore,
    },
    operations: {
      onEndReached,
      onRefresh,
    },
  };
}

export default useViewAllContainer;
