import React from "react";
import { Image, StyleSheet, Text, Pressable, View } from "react-native";
import backIcon from "../../../assets/images/back.png";
import { theme } from "../../../utilities/theme";
import { useNavigation } from "@react-navigation/native";
import closeIcon from "../../../assets/images/close_line.png";
import { SCREENS } from "../../../routes/screens";

function NavigationBarBoard({
  backButtonText = "",
  title,
  isModal = false,
  onBackButtonPress,
}) {
  const { navigate, goBack, canGoBack } = useNavigation();

  return (
    <View style={styles.wrapper}>
      <Pressable
        style={styles.backButton}
        onPress={
          onBackButtonPress
            ? onBackButtonPress
            : canGoBack()
            ? () => goBack()
            : () => navigate(SCREENS.HOME)
        }
      >
        <Text style={styles.backButtonText}>{backButtonText}</Text>
      </Pressable>
      <Text style={styles.title}>{title}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: theme.spacing.normal,
    position: "relative",
    borderTopColor: "#E5E5E5",
    borderTopWidth: 1,
    borderBottomColor: "#E5E5E5",
    borderBottomWidth: 1,
    paddingTop: 15,
    paddingBottom: 15,
  },
  backButton: {
    position: "absolute",
    left: theme.spacing.default,
    top: theme.spacing.normal,
    bottom: theme.spacing.normal,
    flexDirection: "row",
    alignItems: "center",
  },
  backButtonIcon: {
    width: 24,
    height: 24,
  },
  backButtonText: {
    fontSize: theme.font.body1,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
  },
  title: {
    fontSize: theme.font.body1,
    color: theme.color.black,
    width: "95%",
    fontFamily: "Pretendard-Bold",
  },
});

export default NavigationBarBoard;
