import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Alert, View, Text, TextInput, Pressable, ScrollView } from "react-native";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import BoardContainer from "../container/BoardContainer";
import TabTitle from "../../../common/components/TabTitle";
import { GET_BOARD, WRITE_POSTS } from "../../../../lib/apollo/gql/board";
import { useMutation, useQuery } from "@apollo/client";
import { navigate } from "../../../../routes/RoutesContainer";
import { styles } from "../../../details/profileDetail/components/ProfileDetailContainer";
import { theme } from "../../../../utilities/theme";
import useBoardTabContainer from "../../boardTab/container/BoardTabContainer.hook";
import FileInput from "../../../common/components/FileInput";
import useFileInput from "../../../common/components/FileInput.hook";
import TextButton from "../../../common/components/TextButton";
import Editor from "../../../common/components/QuillEditor";
import BoardTypeSelector from "../../../common/components/BoardTypeSelector";

function BoardWriteScreen() {

  const { canGoBack, navigate, goBack } = useNavigation();

  const {
    params: { boardId, type },
  } = useRoute();

  const {
    operations: { onEndReached, onRefresh },
  } = useBoardTabContainer(+boardId);

  const { data } = useQuery(GET_BOARD, { variables: { getBoardId: +boardId } });
  
  const splitCategory = data?.getBoard?.category?.split(',');

  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [files, setFiles] = React.useState([]);

  const { refs } = useFileInput();

  const [writePosts] = useMutation(WRITE_POSTS, {
    onCompleted: ({ ok }) => {
      console.log("ok", ok);
      onRefresh();
      navigate(SCREENS.BOARD_TAB);
    },
    onError: (err) => {
      console.log("에러", err);
    }
  });

  const writePost = useCallback(() => {
    console.log("글쓰기", title, content, category, boardId);
    writePosts({
      variables: {
        title: title,
        content: content,
        category: category,
        boardId: Number(boardId),
        fileList: files
      }
    })
  }, [title, content, category, boardId, files]);

  useEffect(() => {
    if (!category) {
      if (splitCategory?.length)
        setCategory(splitCategory[0]);
    }
  }, [splitCategory]);

  const [board, setBoard] = useState(null);
  useEffect(() => {
    setBoard({value: data?.getBoard?.id, label: data?.getBoard?.name});
  }, [data]);
  
  function onBoardTypeSelect(board) {
    setBoard(board);
    setCategory('');
    navigate(SCREENS.BOARD_WRITE, { boardId: board.value });
  }

  // const memoizedBoardTypeSelector = useMemo(
  //   () => (
  //     <BoardTypeSelector
  //       wrapperStyle={{ marginBottom: theme.spacing.middle }}
  //       value={board}
  //       onConfirm={onBoardTypeSelect}
  //     />
  //   ),
  //   [board, onBoardTypeSelect],
  // );

  return (
    <Layout isTabScreen>
      <TabTitle title="글쓰기" comp={(
        <TextButton
          text="목록"
          buttonStyle={[styles.button, {backgroundColor: 'black'}]}
          textStyle={[styles.buttonText, { color: 'white' }]}
          onPress={() => {
            canGoBack() ? goBack() : navigate(SCREENS.BOARD_TAB)
          }}
        />
      )} />

      {/* <NavigationBar
        title={
          data?.getBoard?.name + ' 글쓰기'
        }
        onBackButtonPress={() =>
          canGoBack() ? goBack() : navigate(SCREENS.BOARD_TAB)
        }
      /> */}
      <BoardTypeSelector
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={board}
        onConfirm={onBoardTypeSelect}
      />

      <View style={{ padding: 10, flex: 1 }}>
        <View><Text style={{
          borderBottomWidth: 1, borderBottomColor: '#cdcdcd', fontSize: 16, fontWeight: 500, color: 'black',
          fontFamily: "Pretendard-Bold"
        }}>말머리</Text></View>

        <View style={{ flexDirection: 'row' }}>
          {!splitCategory?.length && (
            <Pressable style={styles.avatarWrapper}><Text style={[styles.bottomButtonText, { color: 'black' }]}>말머리가 없습니다.</Text></Pressable>
          )}

          {splitCategory?.length && splitCategory?.map((item, index) => {
            return (
              <Pressable key={'cat' + index} style={[styles.avatarWrapper, { padding: 5, paddingTop: 0 }]} onPress={() => {
                setCategory(item);
              }}>
                <Text style={[styles.bottomButtonText, { color: 'black' }, category == item && {
                  color:
                    theme.color.primary
                }]}>{item}</Text>
              </Pressable>
            )
          })}
        </View>
        <ScrollView style={{ flex: 1 }}>
          {/* <View><Text style={{
            borderBottomWidth: 1, borderBottomColor: 'black',
            fontFamily: "Pretendard-Bold", fontSize: 16, fontWeight: 500, color: 'black'
          }}>제목</Text></View> */}
          <View>
            <TextInput multiline={false} placeholder="제목" placeholderTextColor="#ccc" style={{
              fontFamily: "Pretendard-Regular", fontSize: 14, color: '#333', height: 40, borderColor: '#ccc', borderWidth: 1, paddingLeft: 10
            }} value={title} onChange={(e) => {
              setTitle(e.nativeEvent.text);
            }} />
          </View>
          {/* <View><Text style={{
            borderBottomWidth: 1, borderBottomColor: 'black',
            fontFamily: "Pretendard-Bold", fontSize: 16, fontWeight: 500, color: 'black', marginTop: 20
          }}>내용</Text></View> */}
          <View style={{ marginBottom: 10, marginTop: 20 }}>
            {/* <TextInput multiline={true} numberOfLines={10} placeholder="내용을 입력해주세요." style={{
              fontFamily: "Pretendard-Regular", fontSize: 14, color: '#333', borderColor: 'gray', borderWidth: 1, padding: 10,
            }} verticalAlign="auto" value={content} onChange={(e) => {
              setContent(e.nativeEvent.text);
            }} /> */}
            <Editor
              value={content}
              onChange={setContent}
            />
          </View>
          <View><Text style={{
            fontFamily: "Pretendard-Bold", fontSize: 16, fontWeight: 500, color: 'black', marginTop: 20
          }}>첨부파일</Text></View>
          <FileInput files={files} setFiles={setFiles} showBottomTag={true} />
          <View>
            <Pressable style={{ backgroundColor: 'black', padding: 10, marginTop: 20, borderRadius: 5 }} onPress={writePost}>
              <Text style={{
                fontFamily: "Pretendard-Bold", color: 'white', textAlign: 'center'
              }} >작성하기</Text>
            </Pressable>
          </View>
        </ScrollView>
        
      </View>
    </Layout >
  );
}

export default React.memo(BoardWriteScreen);
