import { gql } from "@apollo/client";

export const GET_CHAT_WITH_TUTOR_ID = gql`
  query GetChatWithTutorId($tutorId: ID!) {
    getChatWithTutorId(tutorId: $tutorId) {
      id
      chatId
    }
  }
`;

export const DELETE_CHAT = gql`
mutation DeleteChat($chatId: ID!) {
  deleteChat(chatId: $chatId){
    chatId
  }
}
`;

export const START_CHAT = gql`
  mutation StartChat($tutorId: ID!) {
    startChat(tutorId: $tutorId) {
      id
      chatId
    }
  }
`;

export const GET_MY_CHATS = gql`
  query GetMyChats($after: String, $first: Int) {
    getMyChats(after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        chatId
        user {
          id
          username
          avatar {
            id
            uri
          }
          mentor {
            id
            verified
            active
          }
          tutor {
            id
            active
            verified
          }
          deletedAt
        }
        tutor {
          id
          username
          avatar {
            id
            uri
          }
          mentor {
            id
            verified
            active
          }
          tutor {
            id
            active
            verified
          }
          deletedAt
        }
        userLastSeenAt
        tutorLastSeenAt
        lastMessageAt
        lastMessage {
          id
          text
        }
        hasUnreadMessage
        createdAt
      }
    }
  }
`;

export const GET_CHAT = gql`
  query GetChat($chatId: ID!) {
    getChat(chatId: $chatId) {
      id
      chatId
      user {
        id
        username
        avatar {
          id
          uri
        }
        mentor {
          id
          verified
          active
        }
        tutor {
          id
          active
          verified
        }
        deletedAt
      }
      tutor {
        id
        username
        avatar {
          id
          uri
        }
        mentor {
          id
          verified
          active
        }
        tutor {
          id
          active
          verified
        }
        deletedAt
      }
      userLastSeenAt
      tutorLastSeenAt
      lastMessageAt
      lastMessage {
        id
        text
      }
      hasUnreadMessage
      createdAt
    }
  }
`;

export const INCOMPLETE_MESSAGES = gql`
  {
    incompleteMessages @client {
      id
      text
      status
      __typename
      author {
        id
        username
        __typename
      }
    }
  }
`;

export const UPDATE_LAST_SEEN_AT = gql`
  mutation UpdateLastSeenAt($chatId: ID!) {
    updateLastSeenAt(chatId: $chatId) {
      id
      chatId
    }
  }
`;

export const GET_CHAT_MESSAGES = gql`
  query GetChatMessages($chatId: ID!, $after: String, $first: Int) {
    getChatMessages(chatId: $chatId, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        chat {
          id
          chatId
        }
        author {
          id
          username
          avatar {
            id
            uri
          }
        }
        text
        createdAt
      }
    }
  }
`;

export const NEW_MESSAGE = gql`
  subscription NewMessage($chatId: ID!) {
    newMessage(chatId: $chatId) {
      id
      chat {
        id
        chatId
      }
      author {
        id
        username
        avatar {
          id
          uri
        }
      }
      text
      createdAt
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($chatId: ID!, $text: String!) {
    sendMessage(chatId: $chatId, text: $text) {
      id
      chat {
        id
        chatId
      }
      author {
        id
        username
        avatar {
          id
          uri
        }
      }
      text
      createdAt
    }
  }
`;

export const UPDATE_CHAT = gql`
  subscription UpdateChat {
    updateChat {
      id
      chatId
      user {
        id
        username
        avatar {
          id
          uri
        }
        mentor {
          id
          verified
          active
        }
        tutor {
          id
          active
          verified
        }
        deletedAt
      }
      tutor {
        id
        username
        avatar {
          id
          uri
        }
        mentor {
          id
          verified
          active
        }
        tutor {
          id
          active
          verified
        }
        deletedAt
      }
      userLastSeenAt
      tutorLastSeenAt
      lastMessageAt
      lastMessage {
        id
        text
      }
      hasUnreadMessage
      createdAt
    }
  }
`;
