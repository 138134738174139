import { gql } from "@apollo/client";

export const GET_AREAS = gql`
  query GetAreas {
    getAreas {
      id
      name
      sort
    }
  }
`;
