import React from "react";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import closeIcon from "../../../../../assets/images/close_line.png";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../../routes/screens";
import { theme } from "../../../../../utilities/theme";
import { useQuery } from "@apollo/client";
import { GET_CLASS } from "../../../../../lib/apollo/gql/class";
import defaultCoverPhoto from "../../../../../assets/images/default_cover_photo.png";

function UpdateClassDoneContainer() {
  const { navigate } = useNavigation();
  const { params } = useRoute();

  const { data, loading } = useQuery(GET_CLASS, {
    variables: {
      classId: params?.classId,
    },
  });

  //console.log(data?.getClass);

  if (loading) return <View />;

  return (
    <View style={styles.wrapper}>
      <Pressable
        style={styles.closeButton}
        // TEMP
        onPress={() => navigate(SCREENS.PROFILE_TAB)}
      >
        <Image style={styles.closeIcon} source={closeIcon} />
        <Text style={styles.closeText}>닫기</Text>
      </Pressable>
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <Text style={styles.title}>강의 등록 완료</Text>
        <Image
          style={styles.coverPhoto}
          source={
            data?.getClass?.coverPhoto
              ? { uri: data?.getClass?.coverPhoto?.uri }
              : defaultCoverPhoto
          }
        />
        <Text style={styles.classTitle}>{data?.getClass?.title}</Text>
        <View style={styles.row}>
          <Text style={styles.rowLeftText}>강사</Text>
          <Text style={styles.rowRightText}>
            {data?.getClass?.tutor?.user?.username}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.rowLeftText}>소요시간</Text>
          <Text style={styles.rowRightText}>
            {data?.getClass?.duration}시간
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.rowLeftText}>강의 분야</Text>
          <View style={{ flexDirection: "column", alignItems: "flex-end" }}>
            {data?.getClass?.fields?.map(field => (
              <Text key={field?.id} style={styles.rowRightText}>
                {field?.name}
              </Text>
            ))}
          </View>
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  closeButton: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingVertical: theme.spacing.normal,
    paddingHorizontal: theme.spacing.default,
  },
  closeIcon: {
    width: 24,
    height: 24,
  },
  closeText: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.middle,
    paddingHorizontal: theme.spacing.regular,
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    fontSize: theme.font.subtitle1,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
    marginBottom: theme.spacing.middle,
  },
  coverPhoto: {
    width: "100%",
    height: 130,
    marginBottom: theme.spacing.default,
  },
  classTitle: {
    width: "100%",
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.subtitle2,
    color: theme.color.black,
    fontWeight: "bold",
    paddingBottom: theme.spacing.middle,
    borderBottomWidth: 1,
    borderBottomColor: theme.color.border,
  },
  row: {
    width: "100%",
    paddingVertical: theme.spacing.default,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: theme.color.border,
  },
  rowLeftText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.black,
  },
  rowRightText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.black,
    fontWeight: "bold",
  },
});

export default UpdateClassDoneContainer;
