import React from "react";
import { Image, Pressable, ScrollView, Text, View } from "react-native";
import TabTitle from "../../../common/components/TabTitle";
import BoardTabContainer from "../container/BoardTabContainer";
import { styles } from "../../../details/profileDetail/components/ProfileDetailContainer";
import { useQuery } from "@apollo/client";
import { useNavigation, useRoute } from "@react-navigation/native";
import { GET_BOARDS } from "../../../../lib/apollo/gql/board";
import { SCREENS } from "../../../../routes/screens";
import Layout from "../../../common/components/Layout";
import TextButton from "../../../common/components/TextButton";

function BoardTabScreen() {

  
  const { canGoBack, navigate, goBack } = useNavigation();

  const { data } = useQuery(GET_BOARDS, { variables: { maxPage: 100, page: 1 } });
  const [tab, setTab] = React.useState(0);
  const [type, setType] = React.useState(0);

  let { params } = useRoute();
  
  return (
    <Layout isTabScreen>
      <TabTitle title="커뮤니티"
       comp={(
        <TextButton
          text="글쓰기"
          buttonStyle={[styles.button, { backgroundColor: 'black'}]}
          textStyle={[styles.buttonText, { color: 'white' }]}
          onPress={() => {
            navigate(SCREENS.BOARD_WRITE, { boardId: tab == 0 ? 1 : tab });
          }}
        />
      )} />
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={true} style={{ padding: 10, maxHeight: 60,  width: '100%' }}  >
          <Pressable style={[tab == 0 ? styles.section4 : styles.section5]} key={0} onPress={() => { navigate(SCREENS.BOARD_TAB, { boardId: 0 }) }}>
            <Text style={[tab == 0 ? styles.section4activeTitle : styles.sectionTitle, { lineHeight: 40}]}>
              전체
            </Text>
          </Pressable>
        {data?.getAllBoards?.map((item, index) => {

          if (params?.boardId === 0 && tab !== 0) {
            setTab(0);
            setType(0);
          }
          if (params?.boardId) {
            if (item?.id == params.boardId && tab !== item?.id) {
              setTab(item?.id);
              setType(item?.type);
            }
          } else {
            // if (index == 0 && tab == 0) {
            //   setTab(item?.id);
            //   setType(item?.type);
            // }
          }

          return (
            <Pressable style={[tab == item?.id ? styles.section4 : styles.section5, {marginLeft: 10}]} key={item?.id} onPress={() => { navigate(SCREENS.BOARD_TAB, { boardId: item?.id }) }}>
              <Text style={[tab == item?.id ? styles.section4activeTitle : styles.sectionTitle, { lineHeight: 40}]}>
                {item?.name}
              </Text>
            </Pressable>
          );
        })}
      </ScrollView>

      <BoardTabContainer boardid={tab} type={type} />
    </Layout>
  );
}

export default BoardTabScreen;
