import { useState } from "react";
import { format } from "date-fns";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_MY_USER_PROFILE,
  IS_UNIQUE_USERNAME,
  UPDATE_MY_USER_PROFILE,
} from "../../../../lib/apollo/gql/user";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function useRequestBasicInfoContainer() {
  const { navigate } = useNavigation();
  const { name, params } = useRoute();

  const [state, setState] = useState({
    username: "",
    name: "",
    dateOfBirth: format(new Date(), "yyyy-MM-dd"),
    gender: null,
    phone: "",
    areas: null,
    usernameError: "",
    nameError: "",
  });

  const { data, loading, error } = useQuery(GET_MY_USER_PROFILE, {
    onCompleted: ({ getMyUserProfile }) => {
      setState(prev => ({
        ...prev,
        username: getMyUserProfile?.username,
        name: getMyUserProfile?.name || "",
        dateOfBirth:
          getMyUserProfile?.dateOfBirth || format(new Date(), "yyyy-MM-dd"),
        gender: getMyUserProfile?.gender,
        phone: getMyUserProfile?.phone || "",
      }));
    },
  });

  const [isUniqueUsername, { loading: usernameLoading }] =
    useLazyQuery(IS_UNIQUE_USERNAME);

  const [updateMyUserProfile, { loading: updateLoading }] = useMutation(
    UPDATE_MY_USER_PROFILE,
    {
      onCompleted: () => {
        if (name === SCREENS.REQUEST_MENTOR_BASIC) {
          navigate(SCREENS.REQUEST_MENTOR_INTRODUCTION, { ...params });
        } else {
          navigate(SCREENS.REQUEST_TUTOR_INTRODUCTION, { ...params });
        }
      },
    },
  );

  function onInputChange(value, key) {
    if (key === "phone" && isNaN(Number(value))) {
      return;
    }

    setState(prev => ({
      ...prev,
      [key]: value,
    }));
  }

  function onBirthChange(date) {
    setState(prev => ({
      ...prev,
      dateOfBirth: date,
    }));
  }

  function onGenderChange(gender) {
    setState(prev => ({
      ...prev,
      gender,
    }));
  }

  function onUsernameFocus() {
    if (state.usernameError) {
      setState(prev => ({
        ...prev,
        usernameError: "",
      }));
    }
  }

  async function onUsernameBlur() {
    if (state.username?.length < 2) {
      setState(prev => ({
        ...prev,
        usernameError: "닉네임은 최소 2자이상입니다",
      }));
    }
    if (state?.username !== data?.getMyUserProfile?.username) {
      const { data } = await isUniqueUsername({
        variables: {
          username: state.username,
        },
      });

      if (!data?.isUniqueUsername) {
        setState(prev => ({
          ...prev,
          usernameError: "이미 사용중인 닉네임입니다",
        }));
      }
    }
  }

  function onNameFocus() {
    if (state.nameError) {
      setState(prev => ({
        ...prev,
        nameError: "",
      }));
    }
  }

  async function onNameBlur() {
    if (state.name?.length < 1) {
      setState(prev => ({
        ...prev,
        nameError: "이름을 입력해주세요",
      }));
    }
  }

  function isSubmitDisabled() {
    const {
      username,
      name,
      dateOfBirth,
      gender,
      phone,
      usernameError,
      nameError,
    } = state;

    const isValidUsername = !usernameError && username?.length > 1;
    const isValidName = !nameError && name?.length > 0;
    const isValidDateOfBirth = !!dateOfBirth;

    return (
      !(isValidUsername && isValidName && isValidDateOfBirth) ||
      updateLoading ||
      usernameLoading
    );
  }

  function onSubmit() {
    const { username, name, dateOfBirth, gender, phone } = state;

    updateMyUserProfile({
      variables: {
        userInput: {
          name,
          username,
          gender,
          dateOfBirth,
          phone,
          email: data?.getMyUserProfile?.email,
          fieldIds: data?.getMyUserProfile?.fields?.map(field => field?.id),
        },
      },
    });
  }

  return {
    models: {
      state,
      avatar: data?.getMyUserProfile?.avatar,
    },
    operations: {
      onInputChange,
      onBirthChange,
      onGenderChange,
      onUsernameFocus,
      onUsernameBlur,
      onNameFocus,
      onNameBlur,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useRequestBasicInfoContainer;
