import imageCompression from "browser-image-compression";
import {
  IMAGE_COMPRESSION_OPTIONS,
  MOBILE_IMAGE_COMPRESSION_OPTIONS,
  isWeb,
} from "../../../utilities/constants";
import { ReactNativeFile } from "apollo-upload-client";

const { useMutation } = require("@apollo/client");
const { useState, useRef } = require("react");
const {
  UPDATE_MY_AVATAR,
  GET_MY_USER_PROFILE,
} = require("../../../lib/apollo/gql/user");

function useAvatarInput() {
  const inputRef = useRef();

  const [updateMyAvatar] = useMutation(UPDATE_MY_AVATAR);

  async function onAvatarPress() {
    if (isWeb) {
      inputRef?.current?.click();
    } else {
      const { openPicker } = await import("react-native-image-crop-picker");
      const { path, mime } = await openPicker(MOBILE_IMAGE_COMPRESSION_OPTIONS);
      const name = path.substring(path.lastIndexOf("/") + 1);
      const file = { uri: path, name, type: mime };

      await updateMyAvatar({
        variables: {
          avatarInput: {
            file: new ReactNativeFile(file),
          },
        },
      });
    }
  }

  async function onInputChange(e) {
    const { files } = e.target;

    if (isWeb) {
      const compressed = await imageCompression(
        files[0],
        IMAGE_COMPRESSION_OPTIONS,
      );
      await updateMyAvatar({
        variables: {
          avatarInput: {
            file: compressed,
          },
        },
      });
    }
  }

  return {
    refs: {
      inputRef,
    },

    operations: {
      onAvatarPress,
      onInputChange,
    },
  };
}

export default useAvatarInput;
