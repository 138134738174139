import React from "react";
import Layout from "../../../../common/components/Layout";
import NavigationBar from "../../../../common/components/NavigationBar";
import TopProgressBar from "../../../../common/components/TopProgressBar";
import UpdateClassFieldsContainer from "../container/UpdateClassFieldsContainer";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../../routes/screens";

function UpdateClassFieldsScreen() {
  const { navigate } = useNavigation();
  const { params } = useRoute();

  return (
    <Layout>
      <NavigationBar
        title={params?.classId === "new" ? "강의 등록" : "강의 수정"}
        onBackButtonPress={() =>
          navigate(SCREENS.UPDATE_CLASS_BASIC, { classId: params?.classId })
        }
      />
      <TopProgressBar progress={2} total={2} />
      <UpdateClassFieldsContainer />
    </Layout>
  );
}

export default UpdateClassFieldsScreen;
