import React from "react";
import CustomKeyBoardAvoidingView from "../../../common/components/CustomKeyBoardAvoidingView";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import useResignContainer from "./ResignContainer.hook";
import InputWithLabel from "../../../common/components/InputWithLabel";
import TextButton from "../../../common/components/TextButton";

function ResignContainer() {
  const {
    models: { reason },
    operations: { onInputChange, isSubmitDisabled, onSubmit },
  } = useResignContainer();

  return (
    <CustomKeyBoardAvoidingView>
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <View style={styles.top}>
          <Text style={styles.topText}>
            {`지금 탈퇴하시면 \n모든 정보와 활동데이터가 삭제되며,\n한번탈퇴한 계정은 다시 사용할 수 없습니다.
`}
          </Text>
        </View>
        <InputWithLabel
          label="탈퇴 사유(선택)"
          placeholder="탈퇴 사유를 적어주세요"
          wrapperStyle={{ marginBottom: theme.spacing.section }}
          inputStyle={{ height: 104 }}
          value={reason}
          multiline
          onChangeText={onInputChange}
          onSubmitEditing={() => {
            if (!isSubmitDisabled()) {
              onSubmit();
            }
          }}
        />
        <TextButton
          text="회원탈퇴"
          disabled={isSubmitDisabled()}
          buttonStyle={{ backgroundColor: theme.color.error }}
          textStyle={{ color: theme.color.white }}
          onPress={onSubmit}
        />
      </ScrollView>
    </CustomKeyBoardAvoidingView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.default,
    paddingHorizontal: theme.spacing.regular,
  },
  top: {
    width: "100%",
    paddingVertical: theme.spacing.default,
    backgroundColor: theme.color.grayBox,
    borderRadius: 8,
    marginBottom: theme.spacing.middle,
  },
  topText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: "#000000",
    lineHeight: 21,
    textAlign: "center",
  },
});

export default ResignContainer;
