const { useNavigation } = require("@react-navigation/native");
const { useSignUpContext } = require("../provider/SignUpProvider");
const { useState } = require("react");
const { useLazyQuery } = require("@apollo/client");
const { IS_UNIQUE_USERNAME } = require("../../../../lib/apollo/gql/user");
const { SCREENS } = require("../../../../routes/screens");

function useCreateBasicProfileContainer() {
  const { navigate } = useNavigation();

  const {
    username,
    name,
    dateOfBirth,
    gender,
    phone,
    introduction,
    onInputChange,
    onBirthChange,
    onGenderChange,
  } = useSignUpContext();

  const [usernameError, setUsernameError] = useState("");

  const [isUniqueUsername, { loading }] = useLazyQuery(IS_UNIQUE_USERNAME);

  function onUsernameFocus() {
    if (usernameError) {
      setUsernameError("");
    }
  }

  async function onUsernameBlur() {
    if (username?.length < 2) {
      setUsernameError("닉네임은 최소 2자이상입니다");
    } else {
      const { data } = await isUniqueUsername({
        variables: {
          username,
        },
      });

      if (!data?.isUniqueUsername) {
        setUsernameError("이미 사용중인 닉네임입니다");
      }
    }
  }

  function isSubmitDisabled() {
    const isValidUsername = !usernameError && username?.length > 1;
    const isValidDateOfBirth = !!dateOfBirth;

    return !(isValidUsername && isValidDateOfBirth) || loading;
  }

  function onSubmit() {
    navigate(SCREENS.CREATE_PROFILE_FIELDS);
  }

  return {
    models: {
      username,
      name,
      dateOfBirth,
      gender,
      phone,
      introduction,
      usernameError,
    },
    operations: {
      onInputChange,
      onBirthChange,
      onGenderChange,
      onUsernameFocus,
      onUsernameBlur,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useCreateBasicProfileContainer;
