import React from "react";
import Layout from "../../../common/components/Layout";
import ClassDetailContainer from "../container/ClassDetailContainer";

function ClassDetailScreen() {
  return (
    <Layout>
      <ClassDetailContainer />
    </Layout>
  );
}

export default ClassDetailScreen;
