import { useQuery } from "@apollo/client";
import { GET_AREAS } from "../../../lib/apollo/gql/area";

function useAreaSelector() {
  const { data, loading } = useQuery(GET_AREAS);

  return {
    models: {
      loading,
      data: data?.getAreas,
    },
  };
}

export default useAreaSelector;
