import React from "react";
import RoutesContainer from "./routes/RoutesContainer";
import { SafeAreaProvider } from "react-native-safe-area-context";
import ScreenWidthProvider from "./modules/providers/ScreenWidthProvider";
import { ApolloProvider } from "@apollo/client";
import client from "./lib/apollo";
import AuthProvider from "./modules/providers/AuthProvider";

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ScreenWidthProvider>
          <SafeAreaProvider>
            <RoutesContainer />
          </SafeAreaProvider>
        </ScreenWidthProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
