import React from "react";
import { StyleSheet, Text } from "react-native";
import { View } from "react-native";
import { theme } from "../../../utilities/theme";
import TextButton from "./TextButton";
import { GENDERS } from "../../../utilities/constants";

function GenderInput({
  wrapperStyle = {},
  label,
  labelStyle,
  gender,
  onGenderSelect,
}) {
  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      <Text style={[styles.label, labelStyle]}>{label}</Text>
      <View style={styles.genderRow}>
        <TextButton
          text="여자"
          buttonStyle={[
            gender === GENDERS.FEMALE
              ? styles.selectedGenderButton
              : styles.genderButton,
            { marginRight: theme.spacing.xxSmall2 },
          ]}
          textStyle={
            gender === GENDERS.FEMALE
              ? styles.selectedGenderText
              : styles.genderText
          }
          onPress={() => onGenderSelect(GENDERS.FEMALE)}
        />
        <TextButton
          text="남자"
          buttonStyle={[
            gender === GENDERS.MALE
              ? styles.selectedGenderButton
              : styles.genderButton,
          ]}
          textStyle={
            gender === GENDERS.MALE
              ? styles.selectedGenderText
              : styles.genderText
          }
          onPress={() => onGenderSelect(GENDERS.MALE)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
  },
  label: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },
  genderRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  genderButton: {
    flex: 1,
    backgroundColor: theme.color.white,
    borderWidth: 1,
    borderColor: theme.color.gray4,
  },
  selectedGenderButton: {
    flex: 1,
    backgroundColor: theme.color.primaryOpacity,
    borderWidth: 1,
    borderColor: theme.color.primary,
  },
  genderText: {
    color: theme.color.black,
    fontWeight: "normal",
  },
  selectedGenderText: {
    color: theme.color.black,
    fontWeight: "normal",
  },
});

export default GenderInput;
