import React from "react";
import Layout from "../../../../common/components/Layout";
import NavigationBar from "../../../../common/components/NavigationBar";
import TopProgressBar from "../../../../common/components/TopProgressBar";
import UpdateClassBasicContainer from "../container/UpdateClassBasicContainer";
import { useRoute } from "@react-navigation/native";

function UpdateClassBasicScreen() {
  const { params } = useRoute();

  return (
    <Layout>
      <NavigationBar
        title={params?.classId === "new" ? "강의 등록" : "강의 수정"}
        isModal
      />
      <TopProgressBar progress={1} total={2} />
      <UpdateClassBasicContainer />
    </Layout>
  );
}

export default UpdateClassBasicScreen;
