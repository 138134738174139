import { useQuery } from "@apollo/client";
import React from "react";
import { GET_POLICIES } from "../../../../lib/apollo/gql/admin";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import { theme } from "../../../../utilities/theme";
import Webview from "../../../common/components/Webview";
import arrowIcon from "../../../../assets/images/arrow_right_line_gray.png";

function PolicyContainer() {
  const { navigate } = useNavigation();
  const { name } = useRoute();
  const { loading, data } = useQuery(GET_POLICIES);

  if (loading) return <View />;

  const html =
    name === SCREENS.TERMS
      ? data?.getPolicies?.terms
      : data?.getPolicies?.privacy;

  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={{ padding: theme.spacing.default }}
    >
      <Webview html={html} />
      <View style={styles.companyWrapper}>
        <Text style={styles.companyText}>
          회사명:{data?.getCompanyInformation?.name}
        </Text>
        <Text style={styles.companyText}>
          주소:{data?.getCompanyInformation?.address}
        </Text>
        <Text style={styles.companyText}>
          대표:{data?.getCompanyInformation?.ceo}
        </Text>
        <Text style={styles.companyText}>
          사업자번호:{data?.getCompanyInformation?.registrationNumber}
        </Text>
      </View>
      {name === SCREENS.TERMS && (
        <Pressable
          style={styles.buttonRow}
          onPress={() => navigate(SCREENS.RESIGN)}
        >
          <Text style={styles.buttonText}>이타주의자 회원 탈퇴</Text>
          <Image style={styles.buttonIcon} source={arrowIcon} />
        </Pressable>
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  companyWrapper: {
    padding: theme.spacing.default,
    borderWidth: 1,
    borderColor: theme.color.gray5,
    marginVertical: theme.spacing.default,
    backgroundColor: theme.color.white,
    borderRadius: 8,
  },
  companyText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    lineHeight: 18,
    color: theme.color.gray3,
  },

  buttonRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.gray2,
  },
  buttonIcon: {
    width: 20,
    height: 20,
  },
});

export default PolicyContainer;
