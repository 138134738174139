import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import logoImage from "../../../../assets/images/logo_color.png";
import TextButton from "../../../common/components/TextButton";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function LandingContainer() {
  const { navigate } = useNavigation();

  return (
    <View style={styles.wrapper}>
      <Image style={styles.logo} source={logoImage} resizeMode="contain" />
      <View style={styles.buttonsWrapper}>
        <TextButton
          text="회원가입"
          buttonStyle={styles.signUpButton}
          onPress={() => navigate(SCREENS.SIGN_UP_QUIZ)}
        />
        <TextButton
          text="로그인"
          buttonStyle={styles.signInButton}
          textStyle={styles.signInButtonText}
          onPress={() => navigate(SCREENS.SIGN_IN)}
        />
      </View>
      <Text style={styles.bottomText}>
        {`하늘부모님성회 미래세대의\n정보와 지혜를 나누는 커뮤니티입니다`}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    position: "relative",
    backgroundColor: theme.color.white,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: theme.spacing.regular,
  },
  logo: {
    width: 240,
    height: 64,
    marginBottom: theme.spacing.section * 2,
  },
  buttonsWrapper: {
    width: "100%",
  },
  signUpButton: {
    marginBottom: theme.spacing.small,
  },
  signInButton: {
    backgroundColor: theme.color.white,
    borderWidth: 1,
    borderColor: theme.color.gray1,
  },
  signInButtonText: {
    color: theme.color.black,
  },
  bottomText: {
    fontFamily: "Pretendard-Bold",
    fontSize: theme.font.body2,
    color: theme.color.gray2,
    position: "absolute",
    bottom: 50,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

export default LandingContainer;
