import React from "react";
import Layout from "../../../common/components/Layout";
import TabTitle from "../../../common/components/TabTitle";
import ChatTabContainer from "../container/ChatTabContainer";

function ChatTabScreen() {
  return (
    <Layout isTabScreen>
      <TabTitle title="채팅" />
      <ChatTabContainer />
    </Layout>
  );
}

export default ChatTabScreen;
