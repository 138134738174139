import React from "react";
import Layout from "../../../common/components/Layout";
import SignInContainer from "../container/SignInContainer";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function SignInScreen() {
  const { navigate } = useNavigation();
  return (
    <Layout>
      <NavigationBar
        title="로그인"
        onBackButtonPress={() => navigate(SCREENS.LANDING)}
      />
      <SignInContainer />
    </Layout>
  );
}

export default SignInScreen;
