import React, { createContext, useContext, useEffect, useState } from "react";
import { Dimensions, Platform } from "react-native";
import { MIN_SCREEN_WIDTH } from "../../utilities/theme/mediaQueries";

const initialValue = {
  screenWidth: 0,
  isPc: false,
  isTablet: false,
  isMobile: false,
};

const ScreenWidthContext = createContext(initialValue);

function ScreenWidthProvider({ children }) {
  const [value, setValue] = useState(initialValue);

  function handleResize() {
    const currentWidth = window.innerWidth;

    if (currentWidth >= MIN_SCREEN_WIDTH.DESKTOP) {
      setValue({
        screenWidth: currentWidth,
        isPc: true,
        isTablet: false,
        isMobile: false,
      });
    } else if (
      currentWidth < MIN_SCREEN_WIDTH.DESKTOP &&
      currentWidth >= MIN_SCREEN_WIDTH.TABLET
    ) {
      setValue({
        screenWidth: currentWidth,
        isPc: false,
        isTablet: true,
        isMobile: false,
      });
    } else {
      setValue({
        screenWidth: currentWidth,
        isPc: false,
        isTablet: false,
        isMobile: true,
      });
    }
  }

  useEffect(() => {
    if (Platform.OS === "web") {
      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }

    if (Platform.OS !== "web") {
      const subscription = Dimensions.addEventListener("change", handleResize);

      return () => subscription?.remove();
    }
  }, []);

  return (
    <ScreenWidthContext.Provider value={value}>
      {children}
    </ScreenWidthContext.Provider>
  );
}

export default ScreenWidthProvider;

export function useScreenWidthContext() {
  return useContext(ScreenWidthContext);
}
