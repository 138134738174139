import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import TopProgressBar from "../../../common/components/TopProgressBar";
import SignUpContainer from "../container/SignUpContainer";

function SignUpScreen() {
  const { navigate } = useNavigation();
  return (
    <Layout>
      <NavigationBar
        title="회원가입"
        onBackButtonPress={() => navigate(SCREENS.SIGN_UP_QUIZ)}
      />
      <TopProgressBar progress={1} total={3} />
      <SignUpContainer />
    </Layout>
  );
}

export default SignUpScreen;
