import { useRoute } from "@react-navigation/native";
import React, { useMemo } from "react";
import {
  FlatList,
  Image,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { theme } from "../../../../utilities/theme";
import closeIcon from "../../../../assets/images/close_line.png";
import AreaSelector from "../../../common/components/AreaSelector";
import useSearchConditionOverlay from "./SearchConditionOverlay.hook";
import TextButton from "../../../common/components/TextButton";
import refreshIcon from "../../../../assets/images/refresh_line.png";
import { SCREENS } from "../../../../routes/screens";

function SearchConditionOverlay({
  fieldIds,
  area: _area,
  onFieldsChange,
  onAreaChange,
  closeSearchOverlay,
}) {
  const { name } = useRoute();

  const {
    models: { loading, data, selectedFieldIds, area },
    operations: { onFieldSelect, onAreaSelect, onClearPress },
  } = useSearchConditionOverlay(fieldIds, _area);

  const memoizedAreaSelector = useMemo(
    () => (
      <AreaSelector
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="출강지역"
        isSearch
        value={area}
        onConfirm={onAreaSelect}
      />
    ),
    [area, onAreaSelect],
  );

  function renderItem({ item }) {
    const isSelected = new Set(selectedFieldIds).has(item.id);
    return (
      <TextButton
        text={item.name}
        buttonStyle={[
          styles.fieldButton,
          {
            backgroundColor: isSelected
              ? theme.color.primaryOpacity
              : theme.color.white,
          },
        ]}
        textStyle={[
          styles.fieldButtonText,
          { color: isSelected ? theme.color.black : theme.color.gray2 },
        ]}
        onPress={() => onFieldSelect(item.id)}
      />
    );
  }

  const memoizedFieldsSelector = useMemo(
    () => (
      <View>
        <Text style={styles.fieldsTitle}>관심분야(중복선택)</Text>
        <FlatList
          style={styles.flatList}
          contentContainerStyle={styles.contentContainerStyle}
          data={data}
          keyExtractor={item => item.id}
          renderItem={renderItem}
          removeClippedSubviews
          numColumns={2}
        />
      </View>
    ),
    [selectedFieldIds, onFieldSelect],
  );

  if (loading) return <View />;

  return (
    <View style={styles.wrapper}>
      <View style={styles.inner}>
        <Pressable style={styles.closeButton} onPress={closeSearchOverlay}>
          <Image style={styles.closeIcon} source={closeIcon} />
          <Text style={styles.closeText}>취소</Text>
        </Pressable>

        <Text style={styles.title}>검색조건</Text>

        {name !== SCREENS.SEARCH_MENTOR && memoizedAreaSelector}
        {memoizedFieldsSelector}

        <View style={styles.bottomRow}>
          <Pressable style={styles.bottomButton} onPress={onClearPress}>
            <Image style={styles.refreshIcon} source={refreshIcon} />
            <Text style={styles.bottomButtonText}>초기화</Text>
          </Pressable>
          <TextButton
            text="확인"
            buttonStyle={{ flex: 1 }}
            onPress={() => {
              onFieldsChange(Array.from(selectedFieldIds));
              onAreaChange(area);
              closeSearchOverlay();
            }}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0, 31, 37, 0.5)",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: theme.spacing.default,
    zIndex: 1000,
  },
  inner: {
    width: "100%",
    backgroundColor: theme.color.white,
    borderRadius: 8,
    padding: theme.spacing.default,
    paddingBottom: theme.spacing.section,
  },
  closeButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: theme.spacing.default,
  },
  closeIcon: {
    width: 20,
    height: 20,
  },
  closeText: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  title: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.section,
    textAlign: "center",
  },

  fieldsTitle: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
  },
  flatList: {
    marginTop: theme.spacing.small,
    marginBottom: theme.spacing.section,
  },
  contentContainerStyle: {
    height: 184,
  },
  fieldButton: {
    margin: theme.spacing.xxSmall,
    flex: 1 / 2,
    borderWidth: 1,
    borderColor: theme.color.gray4,
  },
  fieldButtonText: {
    fontWeight: "normal",
  },

  bottomRow: {
    flexDirection: "row",
  },
  bottomButton: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    marginHorizontal: theme.spacing.xxSmall,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.color.gray1,
    paddingVertical: theme.spacing.default,
    justifyContent: "center",
  },
  refreshIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing.xxSmall,
  },
  bottomButtonText: {
    color: theme.color.black,
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
  },
});

export default SearchConditionOverlay;
