import { getMediaQueryStrings } from "./mediaQueries";

export const theme = {
  containerMaxWidth: 400,
  topBarHeight: 60,
  color: {
    primary: "#00a070",
    secondary: "#ff8a00",
    secondaryOpacity: "#ffa53c",
    tertiary: "#ffca0e",
    tertiaryOpacity: "#ffd966",
    error: "#ff4444",
    white: "#ffffff",
    black: "#001f25",
    background: "#fbfbfb",
    primaryOpacity: "#d5efe7",
    border: "#ebebeb",
    gray1: "#2a4449",
    gray2: "#556a6e",
    gray3: "#808f92",
    gray4: "#aab4b6",
    gray5: "#d5dadb",
    grayBox: "#f4f5f6",
  },
  font: {
    title1: 40,
    title2: 32,
    title3: 28,
    subtitle1: 24,
    subtitle2: 20,
    body1: 16,
    body2: 14,
    caption1: 12,
    caption2: 11,
    caption3: 9,
  },
  spacing: {
    xxSmall: 2,
    xxSmall2: 4,
    small: 8,
    normal: 12,
    default: 16,
    regular: 20,
    middle: 24,
    large: 32,
    section: 40,
  },

  ...getMediaQueryStrings(),
};
