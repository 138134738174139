import { useState } from "react";
import validator from "validator";
import { firebaseSignIn } from "../../../../lib/firebase/auth/firebaseAuth";
import { firebaseSendPasswordResetEmail } from "../../../../lib/firebase/auth/firebaseAuth";

function useSignInContainer() {
  const [state, setState] = useState({
    email: "",
    password: "",
    emailError: "",
  });

  function onInputChange(text, key) {
    setState(prev => ({
      ...prev,
      [key]: text,
    }));
  }

  function onFocus() {
    if (state.emailError) {
      setState(prev => ({
        ...prev,
        emailError: "",
      }));
    }
  }

  function onEmailBlur() {
    if (!validator.isEmail(state.email)) {
      setState(prev => ({
        ...prev,
        emailError: "이메일 주소를 확인해주세요",
      }));
    }
  }

  async function onFindPasswordPress() {
    if (!state.email) {
      setState(prev => ({
        ...prev,
        emailError: "비밀번호를 찾으실 이메일 주소를 입력해주세요",
      }));

      return;
    }

    await firebaseSendPasswordResetEmail(state.email);
    alert("비밀번호 재설정 이메일이 발송되었습니다");
  }

  function isSubmitDisabled() {
    const isValidEmail = !state.emailError && !!state.email;
    const isValidPassword = state.password.length > 5;

    return !(isValidEmail && isValidPassword);
  }

  async function onSubmit() {
    try {
      await firebaseSignIn(state.email, state.password);
    } catch (error) {
      setState(prev => ({
        ...prev,
        emailError: "이메일 혹은 비밀번호를 확인해주세요",
      }));
    }
  }

  return {
    models: {
      state,
    },
    operations: {
      onInputChange,
      onFocus,
      onEmailBlur,
      onFindPasswordPress,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useSignInContainer;
