import { useEffect, useRef } from "react";
import {
  IMAGE_COMPRESSION_OPTIONS,
  MOBILE_IMAGE_COMPRESSION_OPTIONS,
  isWeb,
} from "../../../utilities/constants";
import imageCompression from "browser-image-compression";
const { useMutation } = require("@apollo/client");
const {
  UPDATEIMAGE
} = require("../../../lib/apollo/gql/board");

function useEditor() {
  const editorRef = useRef();
  const [updateMyFile, { loading, error, data }] = useMutation(UPDATEIMAGE);

  useEffect(() => {
    /**
     * When user upload an image from quill editor
     * It gets file from html input
     * and upload it to firebase storage
     * and get the url for the image
     */
    function imageUploader() {
      const input = document.createElement("input");

      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();

      input.onchange = async (e) => {
        const { files } = e.target;

        const filePromises = Array.from(files).map((file) => {
          if (file.type.startsWith('image/')) {
            return imageCompression(file, IMAGE_COMPRESSION_OPTIONS);
          } else {
            return file;
          }
        });

        if (isWeb) {
          const processedFiles = await Promise.all(filePromises);
    
          const uploadPromises = processedFiles.map((processedFile) =>
            updateMyFile({
              variables: {
                avatarInput: {
                  file: processedFile,
                },
              },
            })
          );
    
          const uploadResults = await Promise.all(uploadPromises);
    
          const uploadedFiles = uploadResults.map((result, index) => ({
            path: result.data.uploadImage.path,
            fileName: processedFiles[index].name,
          }));
    
          console.log("FILEresult", uploadedFiles);

          const range = editorRef?.current?.getEditor().getSelection();
          editorRef?.current
              ?.getEditor()
              .insertEmbed(range?.index, "image", `https://storage.googleapis.com/altruists_prod_media/${uploadedFiles[0].path}`);
        }
      };
    }

    if (editorRef?.current) {
      const toolbar = editorRef?.current.getEditor().getModule("toolbar");
      toolbar.addHandler("image", imageUploader);

      // toolbar.addHandler('link', (value) => {
      //   const url = prompt('Please enter link');
      //   const range = editorRef?.current?.getEditor().getSelection();
      //     editorRef?.current
      //         ?.getEditor()
      //         .insertText(range?.index, url, `link`, url);
      // });
    }
  }, [editorRef?.current]);

  return {
    refs: {
      editorRef,
    },
  };
}

export default useEditor;
