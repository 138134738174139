import { useQuery } from "@apollo/client";
import { GET_MY_USER_PROFILE } from "../../../../lib/apollo/gql/user";
import { useState } from "react";

function useProfileTabContainer() {
  const [showMentorOverlay, setShowMentorOverlay] = useState(false);
  const [showTutorOverlay, setShowTutorOverlay] = useState(false);

  const { data, loading } = useQuery(GET_MY_USER_PROFILE);

  function toggleMentorOverlay(show) {
    setShowMentorOverlay(show);
  }

  function toggleTutorOverlay(show) {
    setShowTutorOverlay(show);
  }

  return {
    models: {
      loading,
      data: data?.getMyUserProfile,
      showMentorOverlay,
      showTutorOverlay,
    },
    operations: {
      toggleMentorOverlay,
      toggleTutorOverlay,
    },
  };
}

export default useProfileTabContainer;
