import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import ResignContainer from "../container/ResignContainer";

function ResignScreen() {
  return (
    <Layout>
      <NavigationBar title="회원탈퇴" />
      <ResignContainer />
    </Layout>
  );
}

export default ResignScreen;
