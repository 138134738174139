import { gql } from "@apollo/client";

export const GET_BOARDS = gql`
query GetAllBoards($maxPage: Int, $page: Int) {
  getAllBoards(maxPage: $maxPage, page: $page) {
    id
    category
    name
    roleId
    type
  }
}
`;

export const GET_POST = gql`
query GetPost($getPostId: Int, $boardId: Int) {
  getPost(id: $getPostId, boardId: $boardId) {
    boardId
    category
    content
    createdAt
    id
    title
    updatedAt
    viewCnt
    commentCnt
    user {
      avatar {
        uri
      }
      name
      username
    }
    userId
    postFiles {
      fileName
      media {
        uri
        type
      }
    }
  }
}
`;

export const GET_BOARD = gql`
query GetBoard($getBoardId: Int) {
  getBoard(id: $getBoardId) {
    category
    createdAt
    id
    name
    roleId
    push
    total
    type
    updatedAt
  }
}
`;

export const WRITE_POSTS = gql`
mutation Mutation($fileList: [FileList], $category: String, $content: String, $title: String, $userId: String, $boardId: Int) {
  createPost(fileList: $fileList, category: $category, content: $content, title: $title, userId: $userId, boardId: $boardId)
}
`;

export const EDIT_POSTS = gql`
mutation Mutation($fileList: [FileList], $category: String, $content: String, $title: String, $userId: String, $boardId: Int, $postId: Int) {
  editPost(fileList: $fileList, category: $category, content: $content, title: $title, userId: $userId, boardId: $boardId, postId: $postId)
}
`;

export const WRITE_COMMENT = gql`
mutation Mutation($postId: Int, $boardId: Int, $userId: Int, $content: String) {
  createComment(
    postId: $postId
    boardId: $boardId
    userId: $userId
    content: $content
  )
}
`;
export const DELETE_COMMENT = gql`
mutation DeleteComment($deleteCommentId: Int) {
  deleteComment(id: $deleteCommentId) 
}
`;
export const DELETE_POST = gql`
mutation DeletePost($boardId: Int, $id: Int) {
  deletePost(boardId: $boardId, id: $id){
    boardId
  }
}
`;
export const UPDATEFILE = gql`
mutation UploadFile($fileInput: MediaInput, $fileName: String) {
  uploadFile(fileInput: $fileInput, fileName: $fileName) {
    fileId
    mediaId
    success
  }
}
`;
export const UPDATEIMAGE = gql`
mutation UploadImage($avatarInput: MediaInput) {
  uploadImage(avatarInput: $avatarInput) {
    path
    success
  }
}
`;

export const GET_COMMENTS = gql`
query GetComments($maxPage: Int, $page: Int, $postId: Int, $boardId: Int) {
  getComments(maxPage: $maxPage, page: $page, postId: $postId, boardId: $boardId) {
    total
    result {
      content
      createdAt
      id
      postId
      userId
      user {
        id
        name
        username
        email
        dateOfBirth
        gender
        phone
        introduction
        createdAt
        updatedAt
        deletedAt
        resignReason
      }
    }
  }
}
`;

export const GET_BOARD_LIST = gql`
query GetAllPosts($boardId: Int, $page: Int, $maxPage: Int) {
  getAllPosts(boardId: $boardId, page: $page, maxPage: $maxPage) {
    result {
      boardId
      category
      content
      createdAt
      id
      title
      updatedAt
      viewCnt
      commentCnt
      userId
      username
      user {
        avatar {
          uri
        }
        username
      }
    }
    total
  }
}
`;
export const GET_CHAT = gql`
  query GetChat($chatId: ID!) {
    getChat(chatId: $chatId) {
      id
      chatId
      user {
        id
        username
        avatar {
          id
          uri
        }
        mentor {
          id
          verified
          active
        }
        tutor {
          id
          active
          verified
        }
        deletedAt
      }
      tutor {
        id
        username
        avatar {
          id
          uri
        }
        mentor {
          id
          verified
          active
        }
        tutor {
          id
          active
          verified
        }
        deletedAt
      }
      userLastSeenAt
      tutorLastSeenAt
      lastMessageAt
      lastMessage {
        id
        text
      }
      hasUnreadMessage
      createdAt
    }
  }
`;

export const INCOMPLETE_MESSAGES = gql`
  {
    incompleteMessages @client {
      id
      text
      status
      __typename
      author {
        id
        username
        __typename
      }
    }
  }
`;

export const UPDATE_LAST_SEEN_AT = gql`
  mutation UpdateLastSeenAt($chatId: ID!) {
    updateLastSeenAt(chatId: $chatId) {
      id
      chatId
    }
  }
`;

export const GET_CHAT_MESSAGES = gql`
  query GetChatMessages($chatId: ID!, $after: String, $first: Int) {
    getChatMessages(chatId: $chatId, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        chat {
          id
          chatId
        }
        author {
          id
          username
          avatar {
            id
            uri
          }
        }
        text
        createdAt
      }
    }
  }
`;

export const NEW_MESSAGE = gql`
  subscription NewMessage($chatId: ID!) {
    newMessage(chatId: $chatId) {
      id
      chat {
        id
        chatId
      }
      author {
        id
        username
        avatar {
          id
          uri
        }
      }
      text
      createdAt
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($chatId: ID!, $text: String!) {
    sendMessage(chatId: $chatId, text: $text) {
      id
      chat {
        id
        chatId
      }
      author {
        id
        username
        avatar {
          id
          uri
        }
      }
      text
      createdAt
    }
  }
`;

export const UPDATE_CHAT = gql`
  subscription UpdateChat {
    updateChat {
      id
      chatId
      user {
        id
        username
        avatar {
          id
          uri
        }
        mentor {
          id
          verified
          active
        }
        tutor {
          id
          active
          verified
        }
        deletedAt
      }
      tutor {
        id
        username
        avatar {
          id
          uri
        }
        mentor {
          id
          verified
          active
        }
        tutor {
          id
          active
          verified
        }
        deletedAt
      }
      userLastSeenAt
      tutorLastSeenAt
      lastMessageAt
      lastMessage {
        id
        text
      }
      hasUnreadMessage
      createdAt
    }
  }
`;
