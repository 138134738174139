import { StyleSheet } from "react-native";
import { theme } from "../../../utilities/theme";

export const commonStyles = StyleSheet.create({
  formTitleRow: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingHorizontal: theme.spacing.regular,
    paddingTop: theme.spacing.middle,
  },
  formTitle: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  formTitleRowRequiredText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.error,
  },
});
