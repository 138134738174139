import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import SignUpQuizOption from "../components/SignUpQuizOption";
import TextButton from "../../../common/components/TextButton";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import useSignUpQuizContainer from "./SignUpQuizContainer.hook";

function SignUpQuizContainer() {
  const { navigate } = useNavigation();

  const {
    models: { loading, data, selectedAnswer },
    operations: { onAnswerSelect },
  } = useSignUpQuizContainer();

  if (loading) return <View />;

  const isWrong = selectedAnswer && selectedAnswer !== data?.answer;
  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>정답을 선택해주세요</Text>
      <Text style={styles.quiz}>Q. {data?.quiz}</Text>
      <SignUpQuizOption
        wrapperStyle={{ marginBottom: theme.spacing.default }}
        option={data?.option1}
        index={1}
        answer={data?.answer}
        selectedAnswer={selectedAnswer}
        onSelect={onAnswerSelect}
      />
      <SignUpQuizOption
        wrapperStyle={{ marginBottom: theme.spacing.default }}
        option={data?.option2}
        index={2}
        answer={data?.answer}
        selectedAnswer={selectedAnswer}
        onSelect={onAnswerSelect}
      />
      <SignUpQuizOption
        wrapperStyle={{ marginBottom: theme.spacing.default }}
        option={data?.option3}
        index={3}
        answer={data?.answer}
        selectedAnswer={selectedAnswer}
        onSelect={onAnswerSelect}
      />
      <SignUpQuizOption
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        option={data?.option4}
        index={4}
        answer={data?.answer}
        selectedAnswer={selectedAnswer}
        onSelect={onAnswerSelect}
      />
      {!!selectedAnswer && (
        <Text
          style={[
            styles.resultText,
            {
              color: !isWrong ? theme.color.primary : theme.color.error,
            },
          ]}
        >
          {isWrong ? "틀렸습니다! 다시 풀어주세요!" : "정답입니다!"}
        </Text>
      )}
      {!!selectedAnswer && (
        <TextButton
          text={isWrong ? "다시 풀기" : "가입하기"}
          onPress={() =>
            isWrong ? onAnswerSelect(null) : navigate(SCREENS.SIGN_UP)
          }
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    paddingTop: theme.spacing.section,
    paddingHorizontal: theme.spacing.regular,
  },
  title: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.secondary,
    marginBottom: theme.spacing.xxSmall2,
  },
  quiz: {
    fontSize: theme.font.subtitle2,
    color: theme.color.black,
    marginBottom: theme.spacing.section,
    lineHeight: 30,
    fontFamily: "Pretendard-Bold",
  },
  resultText: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    marginBottom: theme.spacing.middle,
    textAlign: "center",
  },
});

export default SignUpQuizContainer;
