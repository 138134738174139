import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QuillViewer.css";

function Viewer({...rest}) {
  const Quill = ReactQuill.Quill;

  return (
    <div style={styles.wrapper}>
      <ReactQuill
        className="viewer"
        theme="snow"
        style={{ width: "100%" }}
        preserveWhitespace
        readOnly={true}
        {...rest}
      />
    </div>
  );
}

const styles = {
  wrapper: {
    position: "relative",
  },
};

export default Viewer;
