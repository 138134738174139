import { useNavigation, useRoute } from "@react-navigation/native";
import { useState } from "react";
import { GET_FIELDS } from "../../../../lib/apollo/gql/field";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_MY_USER_PROFILE,
  UPDATE_MY_MENTOR_FIELDS,
  UPDATE_MY_TUTOR_FIELDS,
  UPDATE_MY_USER_PROFILE,
  UPDATE_USER_FIELDS,
} from "../../../../lib/apollo/gql/user";
import { SCREENS } from "../../../../routes/screens";

function useUpdateFieldsContainer() {
  const { navigate } = useNavigation();
  const { name, params } = useRoute();

  const [selectedFieldIds, setSelectedFieldsIds] = useState(new Set());

  const { data: fieldsData, loading: fieldsLoading } = useQuery(GET_FIELDS);

  const { data, loading, error } = useQuery(GET_MY_USER_PROFILE, {
    onCompleted: ({ getMyUserProfile }) => {
      if (params?.type === "user") {
        setSelectedFieldsIds(
          new Set(getMyUserProfile?.fields?.map(field => field?.id)),
        );
      } else if (params?.type === "mentor") {
        setSelectedFieldsIds(
          new Set(getMyUserProfile?.mentor?.fields?.map(field => field?.id)),
        );
      } else {
        setSelectedFieldsIds(
          new Set(getMyUserProfile?.tutor?.fields?.map(field => field?.id)),
        );
      }
    },
  });

  const [updateUserFields, { loading: updateProfileLoading }] = useMutation(
    UPDATE_USER_FIELDS,
    {
      onCompleted: onMutationCompleted,
    },
  );

  const [updateMyMentorFields, { loading: updateMentorLoading }] = useMutation(
    UPDATE_MY_MENTOR_FIELDS,
    {
      onCompleted: onMutationCompleted,
    },
  );
  const [updateMyTutorFields, { loading: updateTutorLoading }] = useMutation(
    UPDATE_MY_TUTOR_FIELDS,
    {
      onCompleted: onMutationCompleted,
    },
  );

  function onMutationCompleted() {
    setSelectedFieldsIds(new Set());
    navigate(SCREENS.UPDATE_PROFILE);
  }

  function onFieldSelect(fieldId) {
    const currentSelectedFieldIds = new Set(selectedFieldIds);

    if (currentSelectedFieldIds.has(fieldId)) {
      currentSelectedFieldIds.delete(fieldId);
      setSelectedFieldsIds(currentSelectedFieldIds);
    } else {
      currentSelectedFieldIds.add(fieldId);
      setSelectedFieldsIds(currentSelectedFieldIds);
    }
  }

  function onSubmit() {
    const fieldIds = Array.from(selectedFieldIds);
    if (params?.type === "user") {
      updateUserFields({
        variables: {
          fieldIds,
        },
      });
    } else if (params?.type === "mentor") {
      updateMyMentorFields({
        variables: {
          fieldIds,
        },
      });
    } else {
      updateMyTutorFields({
        variables: {
          fieldIds,
        },
      });
    }
  }
  return {
    models: {
      fields: fieldsData?.getFields,
      selectedFieldIds,
      loading: fieldsLoading || loading,
      updateLoading:
        updateMentorLoading || updateTutorLoading || updateProfileLoading,
    },
    operations: {
      onFieldSelect,
      onSubmit,
    },
  };
}

export default useUpdateFieldsContainer;
