import React, { useState, useEffect, useRef } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  Image,
  Linking,
  View,
  Pressable,
  useWindowDimensions,
  FlatList, 
  RefreshControl
} from "react-native";
import { theme } from "../../../../utilities/theme";
import useBoardContainer from "./BoardContainer.hook";
import BoardMessage from "../components/BoardMessage";
import NavigationBar from "../../../common/components/NavigationBar";
import { SCREENS } from "../../../../routes/screens";
import { useNavigation, useRoute } from "@react-navigation/native";
import CustomKeyBoardAvoidingView from "../../../common/components/CustomKeyBoardAvoidingView";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_COMMENT, DELETE_POST, GET_COMMENTS, GET_POST, WRITE_COMMENT, GET_BOARDS } from "../../../../lib/apollo/gql/board";
import RenderHTML from "react-native-render-html";
import { useAuthContext } from "../../../providers/AuthProvider";
import BoardTabContainer from "../../boardTab/container/BoardTabContainer";
import useBoardTabContainer from "../../boardTab/container/BoardTabContainer.hook";
import BoardCard from "../../boardTab/components/BoardCard";
import Viewer from "../../../common/components/QuillViewer";

import defaultProfile from "../../../../assets/images/default_profile_image.png";
import NavigationBarBoard from "../../../common/components/NavigationBar_board";
function BoardContainer() {
  const { canGoBack, navigate, goBack } = useNavigation();
  const { width } = useWindowDimensions();
  const { user } = useAuthContext();
  const [content, setContent] = React.useState('');

  let {
    params: { postId, boardId, postData, type },
  } = useRoute();

  const { data: boardData } = useQuery(GET_BOARDS, { variables: { maxPage: 100, page: 1 } });
  const [boardTab, setBoardTab] = React.useState(0);
  const [boardType, setBoardType] = React.useState(0);
  const {
    models: { loading, data: boardPost, refreshing, fetchingMore, error },
    operations: { onEndReached, onRefresh },
  } = useBoardTabContainer(+boardTab);


  const { data: post, refetch: refetchPost } = useQuery(GET_POST, { variables: { getPostId: +postId, boardId: +boardId } });
  const { data, refetch } = useQuery(GET_COMMENTS, { variables: { postId: +postId, page: 1, maxPage: 100, boardId: postData?.boardId } });

  let finalData = postData;
  finalData = post?.getPost?.id ? post?.getPost : postData;

  const [writeComment] = useMutation(WRITE_COMMENT, {
    onCompleted: ({ ok }) => {
      setTimeout(() => {
        refetch();
      }, 300);
      setContent('');
    },
  });

  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: ({ ok }) => {
      canGoBack() ? goBack() : navigate(SCREENS.BOARD_TAB)
    },
  })

  const [deleteComment] = useMutation(DELETE_COMMENT, {
    onCompleted: ({ ok }) => {
      setTimeout(() => {
        refetch();
      }, 300);
    },
  })

  const [parentWidth, setParentWidth] = useState(0);
  const onLayout = event => {
    const { width } = event.nativeEvent.layout;
    setParentWidth(width);
  };


  const renderersProps = {
    img: {
      enableExperimentalPercentWidth: true
    }
  };
  const tagsStyles = {
    img: {
      alignSelf: 'center',
      width: '100%',
      maxWidth: parentWidth - 10,
    },
    p: {
      marginTop: 3,
      marginBottom: 3,
    },
    div: {
      userSelect: 'auto'
    }
  };
  let myPost = false;
  if (user?.id == finalData?.userId || (user == null && finalData?.user == null)) {
    myPost = true;
  }

  const scrollRef = useRef();

  // console.log("CHECK", user, finalData, myPost, boardTab,boardType)

  return (
    <ScrollView style={{ flex: 1, padding: 0 }} onLayout={onLayout} ref={scrollRef}>
      <Text style={styles.boardType}>{finalData?.boardId==1?"자유게시판":finalData?.boardId==2?"천일국 알바":finalData?.boardId==3?"고민있어요":"수련/프로그램"}</Text>
      <Text style={styles.boardTitle}>{
          (finalData?.category && `[${finalData?.category}] `) + "" + finalData?.title
        }</Text>
      <View style={styles.content}>
        <View style={{ marginHorizontal: 10, marginBottom: 10, flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ flex: 1, flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row'}}>
              {/* <Image
                source={type == 1 ? defaultProfile : finalData?.user?.avatar ? { uri: finalData?.user?.avatar?.uri } : defaultProfile}
                style={styles.avatar}

              /> */}
              <Text style={styles.message}>{finalData?.boardId == 3 ? "익명" : finalData?.user?.username ? finalData?.user?.username : '관리자'}</Text>
              <Text style={[styles.message, {marginLeft: 10}]}>조회수 {finalData?.viewCnt}</Text>
              <Text style={[styles.message, {marginLeft: 10}]}>댓글 {finalData?.commentCnt}</Text>
            </View>
            <Text style={{ color: '#b7b2c2', fontSize: 12 }}>{new Date(finalData?.createdAt).toLocaleString('ko-kr')}</Text>
          </View>

          {myPost && (
            <View style={{ flexDirection: 'row' }}>
              <Pressable style={[styles.button, { backgroundColor: '#727272', marginRight: 6}]} onPress={() => {
                navigate(SCREENS.BOARD_EDIT, { postData: postData });
              }}>
                <Text style={styles.buttonText}>수정</Text>
              </Pressable>
              <Pressable style={styles.button} onPress={() => {
                deletePost({ variables: { boardId: finalData?.boardId, id: finalData?.id } });
              }}>
                <Text style={styles.buttonText}>삭제</Text>
              </Pressable>
            </View>
          )}

        </View>
        <View style={{ marginTop: 20, borderTopColor: '#e5e5e5', borderTopWidth: 1 }} className={'htmlwrapper'}>
          {/* <RenderHTML
            contentWidth={width - 10}
            renderersProps={renderersProps}
            tagsStyles={tagsStyles}
            defaultTextProps={{selectable: true}}
            source={{ html: finalData?.content }} 
            baseStyle={{ fontSize: 16, color: 'black', userSelect: 'auto' }} /> */}

            <Viewer
              value={finalData?.content}
            />
        </View>

        <View style={{ padding: 5, borderTopColor: '#efefef', borderTopWidth: 1, borderBottomWidth: 1, borderBottomColor: '#efefef' }}>
          <Text style={{ color: 'black' }}>첨부파일</Text>
        </View>
        <View style={{ paddingTop: 0, paddingLeft: 5, marginBottom: 10 }} >
          {finalData?.postFiles?.length == 0 && (
            <View style={{ padding: 5 }}><Text style={{ color: 'black' }}>
              첨부파일이 없습니다.
            </Text></View>
          )}
          {finalData?.postFiles?.map((item, index) => {
            return (
              <View key={index} style={{ padding: 5, flexDirection: 'row', justifyContent: 'space-between', borderBottomColor: '#cdcdcd', borderBottomWidth: 1, borderTopColor: '#cdcdcd', borderTopWidth: 1 }}>
                <Pressable key={item?.media?.uri} onPress={async () => {
                  if (!item?.media?.uri) return;
                  await Linking.openURL(item?.media?.uri);
                }}>
                  {item?.media?.type?.includes('image') ? (
                    <Image source={{ uri: item?.media?.uri }} style={{ width: 100, height: 100 }} resizeMode="contain" />
                  ) : (
                    <Text style={styles.input}>{item?.fileName}</Text>
                  )}
                </Pressable>
              </View>
            );
          })}
        </View>
      </View>

      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginVertical: 20 }}>
        <Pressable style={styles.button} onPress={() => {
          canGoBack() ? goBack() : navigate(SCREENS.BOARD_TAB)
        }}>
          <Text style={styles.buttonText}>목록</Text>
        </Pressable>
      </View>

      <View style={{ paddingHorizontal: 10, borderTopColor: '#efefef', borderTopWidth: 1 }}>
        <Text style={{ color: 'black', fontSize: 16, fontFamily: "Pretendard-Bold", marginTop: 20 }}>댓글 ({finalData?.commentCnt})</Text>
      </View>

      <View style={{ flexDirection: 'column', margin: 10, backgroundColor: '#f0f0f0'}}>
        <TextInput
          style={{ padding: 10, fontSize: 14, height: 80 }}
          value={content}
          multiline
          onChange={(e) => {
            setContent(e.nativeEvent.text);
          }}
        />
        <Pressable style={[styles.button2, { backgroundColor: 'black', borderRadius: 4, margin: 5 }]} onPress={() => {
          //console.log("댓글", { postId: +postId, boardId: finalData?.boardId, content: content });
          writeComment({ variables: { postId: +postId, boardId: finalData?.boardId, content: content } });
        }}>
          <Text style={[styles.buttonText2, { lineHeight: 40 }]}>댓글달기</Text>
        </Pressable>
      </View>

      <View style={{ padding: 0 }}>
        {
          data?.getComments?.total == 0 || data?.getComments?.result?.length == 0 ? (
            <View style={{ padding: 10, borderBottomWidth: 1, borderBottomColor: '#efefef' }}>
              <View style={{ marginBottom: 5, flexDirection: 'row', justifyContent: 'space-between', /*borderBottomColor: '#cdcdcd', borderBottomWidth: 1, borderTopColor: '#cdcdcd', borderTopWidth: 1*/ }}>
                <View style={{ padding: 5 }}><Text style={{ color: 'black' }}>
                  댓글이 없습니다.
                </Text></View>
              </View>
            </View>
          ) : null
        }

        {data?.getComments?.result?.map((item, index) => {

          let isUser = false;
          if (item?.user?.id == user?.id) {
            isUser = true;
          }
          //console.log("댓글", item?.user?.id, user?.id);
          return (
            <View key={item?.id} style={{ flexDirection: 'row', alignContent:"center", margin: 10, paddingVertical: 10, borderBottomWidth: 1, borderBottomColor: '#f0f0f0', borderTopWidth: 1, borderTopColor: '#f0f0f0' }}>
              <View style={{ flexDirection: 'column', flex: 1}}>
                <Text style={{ color: 'black', fontSize: 14, fontFamily: 'Pretendard-Bold' }}>
                  {boardId == 3 ? "익명" : item?.user?.username ? item?.user?.username : '관리자'}
                </Text>
                <Text style={{ color: '#727272', fontSize: 10, fontFamily: 'Pretendard-Regular' }}>{new Date(item?.createdAt).toLocaleString('ko-kr')}</Text>
                <Text style={{ color: '#727272', fontSize: 12, fontFamily:  'Pretendard-Light', marginTop: 5}}>{item?.content}</Text>
              </View>
              {isUser && (
                <View style={{ alignItems: 'center', justifyContent: 'center'}}>
                  <Pressable onPress={() => {
                    deleteComment({ variables: { deleteCommentId: item?.id } });
                  }}>
                    <Text style={styles.buttonText3}>삭제</Text>
                  </Pressable>
                </View>
              )}
            </View>
          );
        })}
      </View>
      
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={true} style={{ padding: 10, maxHeight: 60,  width: '100%' }}  >
          <Pressable style={[boardTab == 0 ? styles.section4 : styles.section5]} key={0} onPress={() => { setBoardTab(0); setBoardTab(0); }}>
            <Text style={[boardTab == 0 ? styles.section4activeTitle : styles.sectionTitle, { lineHeight: 40}]}>
              전체
            </Text>
          </Pressable>
        {boardData?.getAllBoards?.map((item, index) => {

          // if (boardId === 0 && boardTab !== 0) {
          //   setBoardTab(0);
          //   setBoardTab(0);
          // }
          // if (boardId) {
          //   if (item?.id == boardId && boardTab !== item?.id) {
          //     setBoardTab(item?.id);
          //     setBoardType(item?.type);
          //   }
          // }

          return (
            <Pressable style={[boardTab == item?.id ? styles.section4 : styles.section5, {marginLeft: 10}]} key={item?.id} onPress={() => { setBoardTab(item?.id); setBoardType(item?.type) }}>
              <Text style={[boardTab == item?.id ? styles.section4activeTitle : styles.sectionTitle, { lineHeight: 40}]}>
                {item?.name}
              </Text>
            </Pressable>
          );
        })}
      </ScrollView>

      <FlatList
        style={{ flexGrow: 0 }}
        data={boardPost}
        keyExtractor={item => item.id}
        renderItem={({ item }) => (
          <BoardCard data={item} type={type} isUser={item?.user?.id === user?.id} onPress={() => {
            scrollRef.current?.scrollTo({
              y: 0,
              animated: true,
            });
          }}/>
        )}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        onEndReached={onEndReached}
        onEndReachedThreshold={1}
      />
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: theme.color.background,
  },
  content: {
    flex: 1,
    width: "100%",
  },
  flatList: {
    flex: 1,
  },
  contentContainerStyle: {
    padding: theme.spacing.default,
  },
  footer: {
    padding: theme.spacing.default,
  },
  footerInner: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.color.border,
    padding: theme.spacing.default,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.color.white,
  },
  input: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    paddingRight: theme.spacing.small,
    flex: 1,
    verticalAlign: "middle",
  },
  button: {
    paddingHorizontal: theme.spacing.small,
    paddingVertical: theme.spacing.xxSmall2,
    borderRadius: 4,
    backgroundColor: 'black',
  },
  button2: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: theme.spacing.small,
    borderRadius: 0,
    backgroundColor: theme.color.primary,
  },
  buttonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.white,
    lineHeight: 21,
  },
  buttonText2: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.white,
    alignItems: 'center',
  },
  buttonText3: {
    fontFamily: "Pretendard-Bold",
    fontSize: theme.font.body2,
    color: theme.color.gray4,
    alignItems: 'center',
  },
  avatar: {
    width: 13,
    height: 13,
    marginTop: 5,
    marginRight: 5,
    borderRadius: 14 / 2,
  },
  section3: {
    backgroundColor: theme.color.white,
    borderRadius: 0,
    borderColor: '#efefef',
    borderBottomWidth: 1,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: 0,
    flexDirection: "row",
  },
  sectionTitle: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },

  section2activeTitle: {
    fontFamily: "Pretendard-Bold",
    fontSize: 13,
    color: theme.color.secondary,
    marginBottom: theme.spacing.xxSmall2,
  },
  boardType: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption3,
    color: '#b7b2c2',
    lineHeight: 15,
    backgroundColor: '#f5f6fa',
    borderRadius: 4,
    width: 54,
    textAlign: 'center',
    marginTop: 20,
    marginLeft: 10
  },
  boardTitle: {
    fontSize: theme.font.body1,
    color: theme.color.black,
    width: "95%",
    fontFamily: "Pretendard-Bold",
    margin: 10,
  },
  message: {
    fontFamily: "Pretendard-Regular",
    fontSize: 12,
    color: '#b7b2c2',
  },
  section4: {
    backgroundColor: 'black',
    borderRadius: 5,
    border: 0,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: 0,
    flexDirection: "row",
  },
  section5: {
    backgroundColor: '#f0f0f0',
    borderRadius: 5,
    border: 0,
    paddingHorizontal: theme.spacing.default,
    paddingVertical: 0,
    flexDirection: "row",
  },
  section4activeTitle: {
    fontFamily: "Pretendard-Bold",
    fontSize: 13,
    color: 'white',
    marginBottom: theme.spacing.xxSmall2,
  },
});

export default BoardContainer;
