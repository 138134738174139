export const SCREENS = {
  LANDING: "LANDING",
  SIGN_IN: "SIGN_IN",
  SIGN_UP: "SIGN_UP",
  SIGN_UP_QUIZ: "SIGN_UP_QUIZ",
  CREATE_BASIC_PROFILE: "CREATE_BASIC_PROFILE",
  CREATE_PROFILE_FIELDS: "CREATE_PROFILE_FIELDS",

  MAIN_TABS: "MAIN_TABS",
  HOME: "HOME",
  VIEW_ALL: "VIEW_ALL",

  SEARCH_TAB: "SEARCH_TAB",
  SEARCH_MENTOR: "SEARCH_MENTOR",
  SEARCH_TUTOR: "SEARCH_TUTOR",
  SEARCH_CLASS: "SEARCH_CLASS",

  CHAT_TAB: "CHAT_TAB",
  CHAT: "CHAT",

  BOARD: "BOARD",
  BOARD_WRITE: "BOARD_WRITE",
  BOARD_TAB: "BOARD_TAB",
  BOARD_EDIT: "BOARD_EDIT",

  PROFILE_TAB: "PROFILE_TAB",
  MANAGE_CLASS_ACTIVE: "MANAGE_CLASS_ACTIVE",
  MANAGE_CLASS_INACTIVE: "MANAGE_CLASS_INACTIVE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_FIELDS: "UPDATE_FIELDS",
  MANAGE_ACCOUNT: "MANAGE_ACCOUNT",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  TERMS: "TERMS",

  RESIGN: "RESIGN",

  PROFILE_DETAIL: "PROFILE_DETAIL",
  CLASS_DETAIL: "CLASS_DETAIL",

  REQUEST_MENTOR: "REQUEST_MENTOR",
  REQUEST_MENTOR_RULE: "REQUEST_MENTOR_RULE",
  REQUEST_MENTOR_BASIC: "REQUEST_MENTOR_BASIC",
  REQUEST_MENTOR_INTRODUCTION: "REQUEST_MENTOR_INTRODUCTION",
  REQUEST_MENTOR_FIELDS: "REQUEST_MENTOR_FIELDS",
  REQUEST_MENTOR_DONE: "REQUEST_MENTOR_DONE",

  REQUEST_TUTOR: "REQUEST_TUTOR",
  REQUEST_TUTOR_RULE: "REQUEST_TUTOR_RULE",
  REQUEST_TUTOR_BASIC: "REQUEST_TUTOR_BASIC",
  REQUEST_TUTOR_INTRODUCTION: "REQUEST_TUTOR_INTRODUCTION",
  REQUEST_TUTOR_FIELDS: "REQUEST_TUTOR_FIELDS",
  REQUEST_TUTOR_DONE: "REQUEST_TUTOR_DONE",

  UPDATE_CLASS: "UPDATE_CLASS",
  UPDATE_CLASS_BASIC: "UPDATE_CLASS_BASIC",
  UPDATE_CLASS_FIELDS: "UPDATE_CLASS_FIELDS",
  UPDATE_CLASS_DONE: "UPDATE_CLASS_DONE",
};
