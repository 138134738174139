import React from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import TextButton from "../../../common/components/TextButton";
import { theme } from "../../../../utilities/theme";
import useRequestFieldsContainer from "./RequestFieldsContainer.hook";

function RequestFieldsContainer() {
  const {
    models: { loading, fields, selectedFieldIds, updateLoading },
    operations: { onFieldSelect, onSubmit },
  } = useRequestFieldsContainer();

  function renderItem({ item }) {
    const isSelected = new Set(selectedFieldIds).has(item.id);
    return (
      <TextButton
        text={item.name}
        buttonStyle={[
          styles.fieldButton,
          {
            backgroundColor: isSelected
              ? theme.color.primaryOpacity
              : theme.color.white,
            borderColor: isSelected ? theme.color.primary : theme.color.gray4,
          },
        ]}
        textStyle={[
          styles.fieldButtonText,
          { color: isSelected ? theme.color.black : theme.color.gray2 },
        ]}
        onPress={() => onFieldSelect(item.id)}
      />
    );
  }

  if (loading) return <View />;

  return (
    <View style={styles.wrapper}>
      <View>
        <Text style={styles.title}>전문분야</Text>
        <Text style={styles.subTitle}>
          자신의 전문 분야를 1개 이상 선택해주세요
        </Text>
      </View>
      <FlatList
        style={styles.flatList}
        contentContainerStyle={styles.contentContainerStyle}
        data={fields}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        removeClippedSubviews
        numColumns={2}
      />
      <View
        style={{
          paddingVertical: theme.spacing.default,
        }}
      >
        <TextButton
          disabled={
            !(Array.from(selectedFieldIds)?.length > 0) || updateLoading
          }
          text="지원하기"
          onPress={onSubmit}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    paddingTop: theme.spacing.middle,
    paddingHorizontal: theme.spacing.regular,
  },
  flatList: {
    marginVertical: -theme.spacing.xxSmall2,
    marginHorizontal: -theme.spacing.regular,
  },
  contentContainerStyle: {
    paddingHorizontal: theme.spacing.default,
  },
  title: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },
  subTitle: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    marginBottom: theme.spacing.middle,
  },
  fieldButton: {
    margin: theme.spacing.xxSmall2,
    flex: 1 / 2,
    borderWidth: 1,
    borderColor: theme.color.gray4,
  },
  fieldButtonText: {
    fontWeight: "normal",
  },
});

export default RequestFieldsContainer;
