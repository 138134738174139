import React from "react";
import { StyleSheet, Text, Pressable, View } from "react-native";
import { theme } from "../../../utilities/theme";

function TextButton({
  text = "",
  buttonStyle = {},
  textStyle = {},
  disabled = false,
  onPress,
}) {
  const Component = !onPress ? View : Pressable;

  return (
    <Component
      style={[styles.wrapper, buttonStyle, disabled ? { opacity: 0.6 } : {}]}
      disabled={disabled}
      onPress={onPress}
    >
      <Text style={[styles.text, textStyle]}>{text}</Text>
    </Component>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
    paddingVertical: theme.spacing.default,
    borderRadius: 8,
    backgroundColor: theme.color.primary,
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.white,
    textAlign: "center",
  },
});

export default TextButton;
