import { from, split } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createUploadLink } from "apollo-upload-client";
import { getFirebaseIdToken } from "../../firebase/auth/firebaseAuth";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { config } from "../../../utilities/config/config";

//console.log("https://" + config.API_URL + "/graphql");

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // graphQLErrors.forEach(({ message, locations, path }) =>
    //   console.log(
    //     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    //   ),
    // );
    graphQLErrors.forEach(err => console.log(err));
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext(async (_, { headers }) => {
  try {
    const token = await getFirebaseIdToken();
    //console.log(token);
    return { headers: { ...headers, authorization: token } };
  } catch (err) {
    console.error(err);
  }
});

const uploadLink = createUploadLink({
  uri: "https://" + config.API_URL + "/graphql",
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://" + config.API_URL + "/graphql",
    connectionParams: async () => ({
      authorization: await getFirebaseIdToken(),
    }),
  }),
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  uploadLink,
);

export default from([retryLink, errorLink, authLink, link]);
