import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import InputWithLabel from "../../../common/components/InputWithLabel";
import { useAuthContext } from "../../../providers/AuthProvider";
import TextButton from "../../../common/components/TextButton";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function ManageAccountContainer() {
  const { navigate } = useNavigation();

  const { user } = useAuthContext();
  return (
    <View style={styles.wrapper}>
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="이메일"
        disabled
        value={user?.email}
      />
      <View>
        <Text style={styles.label}>비밀번호 변경</Text>
        <TextButton
          text="비밀번호 변경"
          buttonStyle={styles.button}
          textStyle={styles.buttonText}
          onPress={() => navigate(SCREENS.CHANGE_PASSWORD)}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    padding: theme.spacing.middle,
  },
  label: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },
  button: {
    backgroundColor: theme.color.white,
    borderColor: theme.color.gray4,
    borderWidth: 1,
    marginBottom: theme.spacing.middle,
  },
  buttonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.black,
    fontWeight: "normal",
  },
});

export default ManageAccountContainer;
