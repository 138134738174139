import { gql } from "@apollo/client";

export const GET_ALL_BANNER = gql`
query GetAllBanners {
    getAllBanners {
      edges {
        desc, 
        id, 
        link,
        type,
        mediaId,
        media {
          id, 
          path, 
        }
      }, 
      pageInfo {
        endCursor
      }
    }
  }
`;
