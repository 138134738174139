import { gql } from "@apollo/client";

export const GET_FIELDS = gql`
  query GetFields {
    getFields {
      id
      name
      sort
    }
  }
`;
