import React from "react";
import { theme } from "../../../utilities/theme";
import { format } from "date-fns";

function DateInput({
  wrapperStyle = {},
  label,
  labelStyle = {},
  inputStyle = {},
  isRequired = false,
  value,
  onConfirm,
}) {
  return (
    <div style={{ ...styles.wrapper, ...wrapperStyle }}>
      <div style={{ ...styles.label, ...labelStyle }}>
        {label}
        {isRequired && <span style={styles.requiredSign}>*</span>}
      </div>
      <div style={styles.inputWrapper}>
        <input
          style={{ ...styles.input, ...inputStyle }}
          type="date"
          max={format(new Date(), "yyyy-MM-dd")}
          value={value}
          onChange={e => onConfirm(e.target.value)}
        />
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    width: "100%",
    position: "relative",
  },
  label: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },
  requiredSign: {
    color: theme.color.error,
  },
  inputWrapper: {
    border: `1px solid ${theme.color.gray4}`,
    borderRadius: 8,
    padding: theme.spacing.default,
  },
  input: {
    color: theme.color.black,
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
  },
};

export default DateInput;
