import { InMemoryCache } from "@apollo/client";
import customRelayStylePagination from "./customRelayPagination";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getMyClasses: customRelayStylePagination(["active"]),
        getMyChats: customRelayStylePagination(),
        searchMentors: customRelayStylePagination(["fieldIds"]),
        searchTutors: customRelayStylePagination(["fieldIds", "areaId"]),
        searchClasses: customRelayStylePagination(["fieldIds", "areaId"]),
        getMentorsByField: customRelayStylePagination(["fieldId"]),
        getTutorsByField: customRelayStylePagination(["fieldId"]),
        getClassesByField: customRelayStylePagination(["fieldId"]),

        chat(_, { args, toReference }) {
          return toReference({
            __typename: "Chat",
            id: args.id,
          });
        },
        getChat: customRelayStylePagination(["chatId"]),
        getChatMessages: {
          // Needs to define any key argument related to this type/query
          keyArgs: ["chatId"],

          // Handles incoming data
          merge: function (existing, incoming, { args }) {
            if (args && !args.after) {
              // First fetch or refetch
              return incoming;
            }

            // Data received from relay pagination
            return {
              ...existing,
              pageInfo: incoming.pageInfo,
              edges: [...existing.edges, ...incoming.edges],
              incompleteMessages: incoming?.incompleteMessages || [],
            };
          },
        },
      },
    },
  },
});

// export const setDefaultCache = cache => {};

// setDefaultCache(cache);

export default cache;
