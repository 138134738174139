import React, { useMemo } from "react";
import {
  Image,
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { theme } from "../../../../../utilities/theme";
import { commonStyles } from "../../../../common/styles/commonStyles";
import useUpdateClassBasicContainer from "./UpdateClassBasicContainer.hook";
import InputWithLabel from "../../../../common/components/InputWithLabel";
import { isWeb } from "../../../../../utilities/constants";
import uploadIcon from "../../../../../assets/images/upload_line.png";
import TextButton from "../../../../common/components/TextButton";

function UpdateClassBasicContainer() {
  const {
    refs: { coverPhotoInputRef },
    models: { state, loading },
    operations: {
      onInputChange,
      onCoverPhotoPress,
      onCoverPhotoChange,
      isSubmitDisabled,
      onSubmit,
    },
  } = useUpdateClassBasicContainer();

  const { title, description, duration, coverPhoto, coverPhotoState } = state;

  const memoizedTitleInput = useMemo(
    () => (
      <InputWithLabel
        label="강의 제목"
        placeholder="입력해주세요"
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={title}
        onChangeText={text => onInputChange(text, "title")}
      />
    ),
    [title, onInputChange],
  );

  const memoizedDescriptionInput = useMemo(
    () => (
      <InputWithLabel
        multiline
        inputStyle={{
          height: 128,
          textAlignVertical: "top",
        }}
        label="강의 소개"
        placeholder="입력해주세요"
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={description}
        onChangeText={text => onInputChange(text, "description")}
      />
    ),
    [description, onInputChange],
  );

  const memoizedDurationInput = useMemo(
    () => (
      <View style={{ width: "100%", marginBottom: theme.spacing.middle }}>
        <Text style={styles.label}>강의 소요 시간</Text>
        <View style={styles.inputRow}>
          <TextInput
            style={styles.input}
            placeholderTextColor={theme.color.gray4}
            placeholder="1~100"
            value={duration}
            inputMode="numeric"
            onChangeText={text => onInputChange(text, "duration")}
          />
          <Text style={styles.inputTail}>시간</Text>
        </View>
      </View>
    ),
    [duration, onInputChange],
  );

  const memoizedCoverPhotoInput = useMemo(
    () => (
      <View style={{ width: "100%", marginBottom: theme.spacing.middle }}>
        <Text style={styles.label}>대표 사진</Text>
        <Pressable style={styles.coverPhotoButton} onPress={onCoverPhotoPress}>
          {!coverPhoto && !coverPhotoState && (
            <>
              <Image style={styles.uploadIcon} source={uploadIcon} />
              <Text style={styles.uploadText}>업로드</Text>
            </>
          )}
          {coverPhotoState ? (
            isWeb ? (
              <img
                src={URL.createObjectURL(coverPhotoState)}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 8,
                  objectFit: "cover",
                }}
              />
            ) : (
              <Image
                style={styles.coverPhoto}
                source={{ uri: coverPhotoState?.uri }}
                resizeMode="cover"
              />
            )
          ) : (
            !!coverPhoto && (
              <Image
                style={styles.coverPhoto}
                source={{ uri: coverPhoto?.uri }}
                resizeMode="cover"
              />
            )
          )}

          {isWeb ? (
            <input
              ref={coverPhotoInputRef}
              type="file"
              accept="images/*"
              onChange={onCoverPhotoChange}
            />
          ) : null}
        </Pressable>
      </View>
    ),
    [isWeb, coverPhoto, coverPhotoState, onCoverPhotoPress, onCoverPhotoChange],
  );

  if (loading) return <View />;

  return (
    <View style={styles.wrapper}>
      <KeyboardAvoidingView
        style={{ flex: 1 }}
        behavior={Platform.OS === "ios" ? "padding" : "height"}
      >
        <View style={commonStyles.formTitleRow}>
          <Text style={commonStyles.formTitle}>기본 정보</Text>
        </View>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.contentContainerStyle}
        >
          {memoizedTitleInput}
          {memoizedDescriptionInput}
          {memoizedDurationInput}
          {memoizedCoverPhotoInput}

          <TextButton
            text="다음"
            disabled={isSubmitDisabled()}
            onPress={onSubmit}
          />
        </ScrollView>
      </KeyboardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },

  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.default,
    paddingHorizontal: theme.spacing.regular,
  },

  label: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },

  inputRow: {
    flexDirection: "row",
    alignItems: "center",
  },

  input: {
    borderWidth: 1,
    borderColor: theme.color.gray4,
    borderRadius: 8,
    padding: theme.spacing.default,
    color: theme.color.black,
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    maxWidth: 99,
    width: "auto",
  },

  inputTail: {
    marginLeft: theme.spacing.default,
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.black,
  },

  coverPhotoButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: 129,
    borderRadius: 8,
    backgroundColor: theme.color.grayBox,
  },
  uploadIcon: {
    width: 24,
    height: 24,
    marginBottom: theme.spacing.xxSmall2,
  },
  uploadText: {
    fontSize: theme.font.body2,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
  },

  coverPhoto: {
    width: "100%",
    height: "100%",
    borderRadius: 8,
  },
});

export default UpdateClassBasicContainer;
