import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { theme } from "../../../utilities/theme";

function TabTitle({ title, comp }) {
  return (
    <View style={styles.wrapper}>
      <Text style={styles.title}>{title}</Text>
      {comp}

    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing.normal,
    paddingHorizontal: theme.spacing.default,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    fontSize: theme.font.subtitle1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
});

export default TabTitle;
