import { onAuthStateChanged } from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../../lib/firebase/auth/firebaseAuth";
import { useApolloClient } from "@apollo/client";
import { GET_MY_USER_PROFILE } from "../../lib/apollo/gql/user";

const defaultValue = {
  firebaseUser: null,
  user: null,
  loading: true,
};

const AuthContext = createContext(defaultValue);

function AuthProvider({ children }) {
  const client = useApolloClient();

  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    onAuthStateChanged(auth, async firebaseUser => {
      if (firebaseUser) {
        const { data } = await client.query({
          query: GET_MY_USER_PROFILE,
          fetchPolicy: "network-only",
        });

        if (data?.getMyUserProfile) {
          setState(prev => ({
            firebaseUser,
            user: data?.getMyUserProfile,
            loading: false,
          }));
        } else {
          setState(prev => ({
            ...prev,
            firebaseUser,
            loading: false,
          }));
        }
      } else {
        setState(prev => ({
          firebaseUser: null,
          user: null,
          loading: false,
        }));
      }
    });
  }, [client]);

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
}

export default AuthProvider;

export function useAuthContext() {
  return useContext(AuthContext);
}
