import React from "react";
import Layout from "../../../common/components/Layout";
import ProfileTabContainer from "../container/ProfileTabContainer";

function ProfileTabScreen() {
  return (
    <Layout isTabScreen>
      <ProfileTabContainer />
    </Layout>
  );
}

export default ProfileTabScreen;
