import { useQuery } from "@apollo/client";
import { GET_HOME_RECOMMENDS } from "../../../../lib/apollo/gql/home";

function useHomeContainer() {
  const { data, loading } = useQuery(GET_HOME_RECOMMENDS);

  return {
    models: {
      loading,
      data: data?.getHomeRecommends,
    },
  };
}

export default useHomeContainer;
