import React from "react";
import { StyleSheet, Text, TextInput, View } from "react-native";
import { theme } from "../../../utilities/theme";

function InputWithLabel({
  wrapperStyle = {},
  label,
  labelStyle = {},
  inputStyle = {},
  isRequired = false,
  errorMessage = "",
  ...rest
}) {
  return (
    <View style={[styles.wrapper, wrapperStyle]}>
      <Text style={[styles.label, labelStyle]}>
        {label}
        {isRequired && <Text style={styles.requiredSign}>*</Text>}
      </Text>
      <TextInput
        style={[
          styles.input,
          inputStyle,
          rest.disabled ? { backgroundColor: theme.color.grayBox } : {},
        ]}
        placeholderTextColor={theme.color.gray4}
        {...rest}
      />
      {!!errorMessage && (
        <Text style={styles.errorMessage}>{errorMessage}</Text>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    width: "100%",
  },

  label: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },
  requiredSign: {
    color: theme.color.error,
  },
  input: {
    borderWidth: 1,
    borderColor: theme.color.gray4,
    borderRadius: 8,
    padding: theme.spacing.default,
    color: theme.color.black,
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
  },
  errorMessage: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.error,
    marginTop: theme.spacing.xxSmall2,
    paddingLeft: theme.spacing.xxSmall2,
  },
});

export default InputWithLabel;
