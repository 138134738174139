import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import {
  GET_MY_USER_PROFILE,
  UPDATE_MY_MENTOR_BASIC_PROFILE,
  UPDATE_MY_TUTOR_BASIC_PROFILE,
} from "../../../../lib/apollo/gql/user";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function useRequestIntroductionContainer() {
  const { navigate } = useNavigation();
  const { name, params } = useRoute();

  const [state, setState] = useState({
    introduction: "",
    experience: "",
    introductionError: "",
    experienceError: "",
    areaIds: [],
  });

  const { data, loading, error } = useQuery(GET_MY_USER_PROFILE, {
    onCompleted: ({ getMyUserProfile }) => {
      if (
        name === SCREENS.REQUEST_MENTOR_INTRODUCTION &&
        getMyUserProfile?.mentor?.id
      ) {
        setState(prev => ({
          ...prev,
          introduction: getMyUserProfile?.mentor?.introduction || "",
          experience: getMyUserProfile?.mentor?.introduction || "",
        }));
      }

      if (
        name === SCREENS.REQUEST_TUTOR_INTRODUCTION &&
        getMyUserProfile?.tutor?.id
      ) {
        setState(prev => ({
          ...prev,
          introduction: getMyUserProfile?.tutor?.introduction || "",
          experience: getMyUserProfile?.tutor?.introduction || "",
        }));
      }
    },
  });

  const [updateMyMentorBasicProfile, { loading: updateMentorLoading }] =
    useMutation(UPDATE_MY_MENTOR_BASIC_PROFILE, {
      onCompleted: onMutationCompleted,
    });
  const [updateMyTutorBasicProfile, { loading: updateTutorLoading }] =
    useMutation(UPDATE_MY_TUTOR_BASIC_PROFILE, {
      onCompleted: onMutationCompleted,
    });

  function onMutationCompleted() {
    if (name === SCREENS.REQUEST_MENTOR_INTRODUCTION) {
      navigate(SCREENS.REQUEST_MENTOR_FIELDS, { ...params });
    } else {
      navigate(SCREENS.REQUEST_TUTOR_FIELDS, { ...params });
    }
  }

  function onAreaSelect(areaId) {
    setState(prev => ({
      ...prev,
      areaIds: [areaId],
    }));
  }

  function onInputChange(value, key) {
    if (
      key === "introduction" &&
      value.replaceAll(/\s/g, "").trim().length > 20
    ) {
      return;
    }

    setState(prev => ({
      ...prev,
      [key]: value,
    }));
  }

  function onInputFocus(key) {
    if (key === "introduction" && state.introductionError) {
      setState(prev => ({
        ...prev,
        introductionError: "",
      }));
    }

    if (key === "experience" && state.experienceError) {
      setState(prev => ({
        ...prev,
        experienceError: "",
      }));
    }
  }

  function onInputBlur(key) {
    if (key === "introduction" && state.introduction?.length < 1) {
      setState(prev => ({
        ...prev,
        introductionError: "소개를 작성해주세요",
      }));
    }

    if (key === "experience" && state.experience?.length < 1) {
      setState(prev => ({
        ...prev,
        experienceError: "경력을 작성해주세요",
      }));
    }
  }

  function isSubmitDisabled() {
    const {
      introduction,
      experience,
      areaIds,
      introductionError,
      experienceError,
    } = state;

    const isValidArea =
      name === SCREENS.REQUEST_TUTOR_INTRODUCTION ? areaIds?.length > 0 : true;
    const isValidIntroduction = !introductionError && introduction?.length > 0;
    const isValidExperience = !experienceError && experience?.length > 0;

    return (
      !(isValidArea && isValidIntroduction && isValidExperience) ||
      updateMentorLoading ||
      updateTutorLoading
    );
  }

  function onSubmit() {
    const { introduction, experience, areaIds } = state;

    if (name === SCREENS.REQUEST_MENTOR_INTRODUCTION) {
      updateMyMentorBasicProfile({
        variables: {
          introduction,
          experience,
        },
      });
    } else {
      updateMyTutorBasicProfile({
        variables: {
          introduction,
          experience,
          areaIds: [areaIds[0]?.value],
        },
      });
    }
  }

  return {
    models: {
      loading,
      state,
    },

    operations: {
      onAreaSelect,
      onInputChange,
      onInputFocus,
      onInputBlur,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useRequestIntroductionContainer;
