import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import defaultProfile from "../../../../assets/images/default_profile_image.png";
import { differenceInDays, format } from "date-fns";
import { ko } from "date-fns/locale";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function ChatCard({ data, isUser }) {
  const { navigate } = useNavigation();

  const isDeleted = isUser ? data?.tutor?.deletedAt : data?.user?.deletedAt;
  const avatar = isUser ? data?.tutor?.avatar : data?.user?.avatar;
  const username = isUser ? data?.tutor?.username : data?.user?.username;
  const isTutor = !!data?.tutor?.tutor?.id;

  return (
    <Pressable
      style={styles.wrapper}
      onPress={() => navigate(SCREENS.CHAT, { chatId: data?.id })}
    >
      <View style={styles.titleRow}>
        <Image
          style={styles.avatar}
          source={avatar ? { uri: avatar?.uri } : defaultProfile}
        />
        <View style={styles.column}>
          <View style={styles.titleRow}>
            <Text style={styles.title}>
              {isDeleted ? "탈퇴한 유저" : username}{" "}
              {!isDeleted &&
                (isUser ? (isTutor ? "강사" : "멘토") + "와의 채팅" : "")}
            </Text>
            {data?.hasUnreadMessage && <View style={styles.new} />}
          </View>
          <Text style={styles.message}>{data?.lastMessage?.text || " "}</Text>
        </View>
      </View>
      <Text style={styles.lastMessageAt}>
        {format(
          new Date(data?.lastMessageAt),
          differenceInDays(new Date(), new Date(data?.lastMessageAt)) < 1
            ? "aaa h:mm"
            : "L월 d일",
          { locale: ko },
        )}
      </Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    paddingVertical: theme.spacing.middle,
    paddingHorizontal: theme.spacing.regular,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.color.gray5,
  },
  avatar: {
    width: 50,
    height: 50,
    borderRadius: 50 / 2,
    marginRight: theme.spacing.default,
  },
  column: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  titleRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing.xxSmall2,
  },
  title: {
    fontFamily: "Pretendard-Bold",
    fontSize: theme.font.body1,
    color: theme.color.black,
    marginRight: theme.spacing.xxSmall2,
  },
  new: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: theme.color.secondary,
  },
  message: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
  },
  lastMessageAt: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption2,
    color: theme.color.gray3,
  },
});

export default ChatCard;
