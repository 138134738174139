import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../routes/screens";
import { useMutation } from "@apollo/client";
import {
  GET_MY_CLASSES,
  TOGGLE_CLASS_ACTIVE,
} from "../../../lib/apollo/gql/class";

function useLargeThumbnail() {
  const { navigate } = useNavigation();

  const [toggleClassActive] = useMutation(TOGGLE_CLASS_ACTIVE, {
    refetchQueries: () => [
      {
        query: GET_MY_CLASSES,
        variables: {
          active: true,
          first: 6,
        },
      },
      {
        query: GET_MY_CLASSES,
        variables: {
          active: false,
          first: 6,
        },
      },
    ],
  });

  function onEditPress(classId) {
    navigate(SCREENS.UPDATE_CLASS, { classId });
  }

  function onToggleClassActivePress(classId, active) {
    toggleClassActive({
      variables: {
        classId,
        active,
      },
    });
  }

  return {
    operations: {
      onEditPress,
      onToggleClassActivePress,
    },
  };
}

export default useLargeThumbnail;
