import React from "react";
import { Image, ScrollView, StyleSheet, Text, View, Linking, Pressable } from "react-native";
import HomeTopBar from "../components/HomeTopbar";
import { theme } from "../../../../utilities/theme";
import HomeSectionTitle from "../components/HomeSectionTitle";
import SmallThumbnail from "../../../common/components/SmallThumbnail";
import LargeThumbnail from "../../../common/components/LargeThumbnail";
import useHomeContainer from "./HomeContainer.hook";
import { useQuery } from "@apollo/client";
import { GET_ALL_BANNER } from "../../../../lib/apollo/gql/banner";
import Swiper from 'react-native-swiper/src';
import setImmediate from "setimmediate";

function HomeContainer() {
  const {
    models: { loading, data },
  } = useHomeContainer();

  const { data: banner, loading: loadingbanner } = useQuery(GET_ALL_BANNER);


  let mentorlength = 0;
  let tutorlength = 0;
  let classlength = 0;

  data?.mentors?.map(
    mentorByField => {
      mentorlength = mentorByField?.mentors?.length;
    }
  )

  data?.classes?.map(
    classByField => {
      classlength += classByField?.classes?.length;
    }
  )

  data?.tutors?.map(
    tutorByField => {
      tutorlength = tutorByField?.tutors?.length;
    }
  )

  return (
    <View style={styles.wrapper}>
      <HomeTopBar />

      {!loading && (
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.contentContainerStyle}
        >

          <View style={{ height: 380, marginBottom: 15 }}>

            {!loadingbanner && (
              <Swiper
                autoplay={true}
                zoomScale={10}
                centerContent={true}
                showsButtons={true}
                nextButton={<Text style={{ fontSize: 50, color: 'black' }}>›</Text>}
                prevButton={<Text style={{ fontSize: 50, color: 'black' }}>‹</Text>}
                onIndexChanged={(index) => {
                  console.log(index);
                }}
                autoplayTimeout={5}>
                {/*APP
              <Swiper
                showsPagination={true}
                autoplay={true}
                zoomScale={10}
                centerContent={true}
                autoplayTimeout={5}>
                  */}

                {banner?.getAllBanners?.edges?.map(
                  (b, i) => {
                    const uri = `https://storage.googleapis.com/altruists_prod_media/${b?.media?.path}`;
                    return (
                      <Pressable key={b?.mediaId} onPress={async () => {
                        if (!b?.link) return;
                        await Linking.openURL(b?.link);
                      }}>
                        <Image source={{ uri: uri }} style={{ width: '100%', height: 380 }} resizeMode="cover" resizeMethod="auto" />
                      </Pressable>)

                  })}
              </Swiper>
            )}
          </View>


          <View style={{ paddingHorizontal: 16 }}>

            {data?.mentors?.map(
              mentorByField =>
                mentorByField?.mentors?.length > 0 && (
                  <View
                    key={mentorByField?.field?.id + "mentor"}
                    style={styles.sectionWrapper}
                  >
                    <HomeSectionTitle
                      title="추천 이타멘토"
                      field={mentorByField?.field}
                      type="MENTOR"
                    />
                    <View style={styles.flexWrap}>
                      {mentorByField?.mentors?.map(
                        mentor =>
                          mentor?.verified &&
                          mentor?.active && (
                            <SmallThumbnail
                              wrapperStyle={{ flexBasis: "47%" }}
                              key={mentor?.id + "mentor"}
                              data={mentor}
                              isTutor={false}
                            />
                          ),
                      )}
                    </View>
                  </View>
                ),
            )}


            {data?.classes?.map(
              classByField =>
                classByField?.classes?.length > 0 && (
                  <View
                    key={classByField?.field?.id + "tutorClass"}
                    style={styles.sectionWrapper}
                  >
                    <HomeSectionTitle
                      title="추천 강의"
                      field={classByField?.field}
                      type="CLASS"
                    />
                    <View style={{ width: "100%" }}>
                      {classByField?.classes?.map(
                        tutorClass =>
                          tutorClass?.tutor?.verified &&
                          tutorClass?.tutor?.active && (
                            <LargeThumbnail
                              wrapperStyle={{ flexBasis: "47%" }}
                              key={tutorClass?.id + "tutorClass"}
                              data={tutorClass}
                            />
                          ),
                      )}
                    </View>
                  </View>
                ),
            )}

            {data?.tutors?.map(
              tutorByField =>
                tutorByField?.tutors?.length > 0 && (
                  <View
                    key={tutorByField?.field?.id + "tutor"}
                    style={styles.sectionWrapper}
                  >
                    <HomeSectionTitle
                      title="추천 이타강사"
                      field={tutorByField?.field}
                      type="TUTOR"
                    />
                    <View style={styles.flexWrap}>
                      {tutorByField?.tutors?.map(
                        tutor =>
                          tutor?.verified &&
                          tutor?.active && (
                            <SmallThumbnail
                              wrapperStyle={{ flexBasis: "47%" }}
                              key={tutor?.id + "tutor"}
                              data={tutor}
                              isTutor={true}
                            />
                          ),
                      )}
                    </View>
                  </View>
                ),
            )}</View>

        </ScrollView>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.large,
    paddingHorizontal: 0,
    paddingTop: 0
  },
  sectionWrapper: {
    marginBottom: theme.spacing.regular * 3,
  },
  flexWrap: {
    flexDirection: "row",
    alignItems: "stretch",
    flex: 1, 
    flexWrap: "wrap",
    marginHorizontal: -theme.spacing.small,
  },
});

export default HomeContainer;
