import React, { useMemo } from "react";
import { ScrollView, StyleSheet, Text } from "react-native";
import { theme } from "../../../../utilities/theme";
import InputWithLabel from "../../../common/components/InputWithLabel";
import TextButton from "../../../common/components/TextButton";

import useSignUpContainer from "./SignUpContainer.hook";
import CustomKeyBoardAvoidingView from "../../../common/components/CustomKeyBoardAvoidingView";

function SignUpContainer() {
  const {
    models: {
      email,
      password,
      confirmPassword,
      state: { emailError, passwordError, confirmPasswordError },
    },
    operations: {
      isUniqueEmail,
      onInputChange,
      onInputFocus,
      onEmailBlur,
      onPasswordBlur,
      onConfirmPasswordBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useSignUpContainer();

  const memoizedEmailInput = useMemo(
    () => (
      <InputWithLabel
        label="이메일"
        placeholder="이메일"
        keyboardType="email-address"
        autoFocus
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        returnKeyType="next"
        autoCapitalize="none"
        value={email}
        errorMessage={emailError}
        onChangeText={text => onInputChange(text, "email")}
        onFocus={() => onInputFocus("email")}
        onBlur={onEmailBlur}
      />
    ),
    [email, emailError, onInputChange, onInputFocus, onEmailBlur],
  );

  const memoizedPasswordInput = useMemo(
    () => (
      <InputWithLabel
        label="비밀번호"
        placeholder="6자 이상"
        secureTextEntry
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        returnKeyType="next"
        value={password}
        errorMessage={passwordError}
        onChangeText={text => onInputChange(text, "password")}
        onFocus={() => onInputFocus("password")}
        onBlur={onPasswordBlur}
      />
    ),
    [password, passwordError, onInputChange, onInputFocus, onPasswordBlur],
  );

  const memoizedConfirmPasswordInput = useMemo(
    () => (
      <InputWithLabel
        label="비밀번호 확인"
        placeholder="6자 이상"
        secureTextEntry
        wrapperStyle={{ marginBottom: theme.spacing.section }}
        value={confirmPassword}
        errorMessage={confirmPasswordError}
        onChangeText={text => onInputChange(text, "confirmPassword")}
        onFocus={() => onInputFocus("confirmPassword")}
        onBlur={onConfirmPasswordBlur}
        onSubmitEditing={() => {
          if (!isSubmitDisabled()) {
            onSubmit();
          }
        }}
      />
    ),
    [
      confirmPassword,
      confirmPasswordError,
      onInputChange,
      onInputFocus,
      onConfirmPasswordBlur,
    ],
  );

  const isDisabled = !(
    email &&
    password.length > 5 &&
    confirmPassword.length > 5 &&
    password === confirmPassword
  );

  return (
    <CustomKeyBoardAvoidingView>
      <ScrollView
        style={styles.wrapper}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <Text style={styles.title}>계정 정보</Text>
        {memoizedEmailInput}
        {memoizedPasswordInput}
        {memoizedConfirmPasswordInput}
        <TextButton
          disabled={isSubmitDisabled()}
          text="다음"
          onPress={onSubmit}
        />
      </ScrollView>
    </CustomKeyBoardAvoidingView>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.middle,
    paddingHorizontal: theme.spacing.regular,
  },
  title: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.default,
  },
});

export default SignUpContainer;
