import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../utilities/theme";
import uploadIcon from "../../../assets/images/upload_line.png";
import useAvatarInput from "./AvatarInput.hook";
import { isWeb } from "../../../utilities/constants";
import useFileInput from "./FileInput.hook";
import TextButton from "./TextButton";

function FileInput({ files, setFiles, showBottomTag = false }) {
  const {
    refs: { inputRef },

    operations: { onFilePress, onInputChange },
  } = useFileInput();

  return (
    <View style={styles.wrapper}>
      <Text style={styles.uploadText}>{files.length == 0 ? "선택한 파일 없음" : files[0].fileName}</Text>
      <Pressable style={styles.circle} onPress={()=>{onFilePress(setFiles)}}>
        {/* {files.length == 0 && (
          <>
            <Image style={styles.uploadIcon} source={uploadIcon} />
            <Text style={styles.uploadText}>업로드</Text> */}
            <TextButton
              text="파일 선택"
              buttonStyle={[styles.button, {backgroundColor: '#727272'}]}
              textStyle={[styles.buttonText, { color: 'white' }]}
            />
          {/* </>
        )} */}
        {/* {files.length > 0 && (
          <View style={[styles.bottomTag,{position:'relative'}]}>
            {files.map((file, index) => {
              return (
                <Text style={styles.bottomTagText}>업로드완료</Text>
              )
            })}
          </View>
        )} */}
        {isWeb ? (
          <input
            ref={inputRef}
            type="file"
            accept="*/*"
            onChange={(e)=>{onInputChange(e, setFiles)}}
          />
        ) : null}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 10,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 10
  },
  button: {
    width: "auto",
    // height: 36,
    paddingVertical: 11,
    paddingHorizontal: theme.spacing.normal,
    borderRadius: 4,
  },
  buttonText: {
    fontSize: theme.font.caption1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    lineHeight: 14,
  },
  circle: {
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    // width: 120,
    height: 36,
    borderRadius: 5,
    backgroundColor: theme.color.grayBox,
  },
  uploadIcon: {
    width: 24,
    height: 24,
    marginBottom: theme.spacing.xxSmall2,
  },
  uploadText: {
    color: "#ccc",
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Light",
  },
  avatar: {
    width: 120,
    height: 120,
    borderRadius: 60,
  },
  bottomTag: {
    paddingHorizontal: theme.spacing.small,
    paddingVertical: theme.spacing.xxSmall2,
    borderRadius: 53,
    borderWidth: 1,
    borderColor: theme.color.gray2,
    backgroundColor: theme.color.white,
  },
  bottomTagText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.gray2,
  },
});

export default FileInput;
