import { useState } from "react";

const { useQuery } = require("@apollo/client");
const { GET_RELIGION_QUIZ } = require("../../../../lib/apollo/gql/user");

function useSignUpQuizContainer() {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const { loading, data } = useQuery(GET_RELIGION_QUIZ);

  function onAnswerSelect(answerIndex) {
    setSelectedAnswer(answerIndex);
  }

  return {
    models: {
      loading,
      data: data?.getReligionQuiz,
      selectedAnswer,
    },
    operations: {
      onAnswerSelect,
    },
  };
}

export default useSignUpQuizContainer;
