import { gql } from "@apollo/client";

export const GET_RELIGION_QUIZ = gql`
  query GetReligionQuiz {
    getReligionQuiz {
      id
      quiz
      option1
      option2
      option3
      option4
      answer
    }
  }
`;

export const IS_UNIQUE_EMAIL = gql`
  query IsUniqueEmail($email: String!) {
    isUniqueEmail(email: $email)
  }
`;

export const IS_UNIQUE_USERNAME = gql`
  query IsUniqueUsername($username: String!) {
    isUniqueUsername(username: $username)
  }
`;

export const GET_MY_USER_PROFILE = gql`
  query GetMyUserProfile {
    getMyUserProfile {
      id
      name
      username
      email
      dateOfBirth
      gender
      phone
      introduction
      fields {
        id
        name
      }
      avatar {
        id
        uri
      }
      createdAt

      mentor {
        id
        introduction
        experience
        verified
        active
        fields {
          id
          name
          sort
        }
        createdAt
        updatedAt
      }
      tutor {
        id
        introduction
        experience
        verified
        active
        mentorActive
        fields {
          id
          name
          sort
        }
        areas {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_MENTOR = gql`
  query GetMentor($mentorId: ID!) {
    getMentor(mentorId: $mentorId) {
      id
      introduction
      experience
      verified
      active
      fields {
        id
        name
        sort
      }
      user {
        id
        username
        dateOfBirth
        gender
        avatar {
          id
          uri
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_TUTOR = gql`
  query GetTutor($tutorId: ID!) {
    getTutor(tutorId: $tutorId) {
      id
      introduction
      experience
      verified
      active
      mentorActive
      fields {
        id
        name
        sort
      }
      areas {
        id
        name
      }
      classes {
        id
        tutor {
          id
          user {
            id
            username
            avatar {
              id
              uri
            }
          }
          areas {
            id
            name
          }
        }
        title
        description
        duration
        active
        coverPhoto {
          id
          uri
        }
        fields {
          id
          name
          sort
        }
        createdAt
        updatedAt
      }
      user {
        id
        username
        dateOfBirth
        gender
        avatar {
          id
          uri
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_MENTORS_BY_FIELD = gql`
  query GetMentorsByField($fieldId: ID!, $first: Int, $after: String) {
    getMentorsByField(fieldId: $fieldId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        introduction
        experience
        verified
        active
        fields {
          id
          name
        }
        user {
          id
          username
          avatar {
            id
            uri
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_TUTORS_BY_FIELD = gql`
  query GetTutorsByField($fieldId: ID!, $first: Int, $after: String) {
    getTutorsByField(fieldId: $fieldId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        introduction
        experience
        verified
        active
        fields {
          id
          name
          sort
        }
        areas {
          id
          name
        }
        user {
          id
          username
          avatar {
            id
            uri
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_CLASSES_BY_FIELD = gql`
  query GetClassesByField($fieldId: ID!, $first: Int, $after: String) {
    getClassesByField(fieldId: $fieldId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        tutor {
          id
          introduction
          verified
          active
          areas {
            id
            name
          }
          user {
            id
            username
            avatar {
              id
              uri
            }
          }
        }
        title
        description
        duration
        coverPhoto {
          id
          uri
        }
        fields {
          id
          name
          sort
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_MY_USER_PROFILE = gql`
  mutation UpdateMyUserProfile($userInput: UserInput!) {
    updateMyUserProfile(userInput: $userInput) {
      id
      name
      username
      email
      dateOfBirth
      gender
      phone
      introduction
      fields {
        id
        name
        sort
      }
      avatar {
        id
        uri
      }
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const UPDATE_MY_AVATAR = gql`
  mutation UpdateMyAvatar($avatarInput: MediaInput) {
    updateMyAvatar(avatarInput: $avatarInput) {
      id
      avatar {
        id
        uri
      }
    }
  }
`;

export const UPDATE_USER_FIELDS = gql`
  mutation UpdateUserfields($fieldIds: [ID]!) {
    updateUserFields(fieldIds: $fieldIds) {
      id
      fields {
        id
        name
        sort
      }
    }
  }
`;

export const UPDATE_MY_MENTOR_BASIC_PROFILE = gql`
  mutation UpdateMyMentorBasicProfile(
    $introduction: String!
    $experience: String!
  ) {
    updateMyMentorBasicProfile(
      introduction: $introduction
      experience: $experience
    ) {
      id
      experience
      introduction
    }
  }
`;

export const UPDATE_MY_MENTOR_FIELDS = gql`
  mutation UpdateMyMentorFields($fieldIds: [ID]!) {
    updateMyMentorFields(fieldIds: $fieldIds) {
      id
      fields {
        id
        name
        sort
      }
    }
  }
`;

export const UPDATE_MY_TUTOR_BASIC_PROFILE = gql`
  mutation UpdateMyTutorBasicProfile(
    $introduction: String!
    $experience: String!
    $areaIds: [ID]!
  ) {
    updateMyTutorBasicProfile(
      introduction: $introduction
      experience: $experience
      areaIds: $areaIds
    ) {
      id
      experience
      introduction
      areas {
        id
        name
        sort
      }
    }
  }
`;

export const UPDATE_MY_TUTOR_FIELDS = gql`
  mutation UpdateMyTutorFields($fieldIds: [ID]!) {
    updateMyTutorFields(fieldIds: $fieldIds) {
      id
      fields {
        id
        name
        sort
      }
    }
  }
`;

export const TOGGLE_ACTIVE_MENTOR = gql`
  mutation ToggleActiveMentor($active: Boolean!, $mentorId: ID) {
    toggleActiveMentor(active: $active, mentorId: $mentorId) {
      id
      active
    }
  }
`;

export const TOGGLE_ACTIVE_TUTOR = gql`
  mutation ToggleActiveTutor(
    $active: Boolean!
    $mentorActive: Boolean!
    $tutorId: ID
  ) {
    toggleActiveTutor(
      active: $active
      mentorActive: $mentorActive
      tutorId: $tutorId
    ) {
      id
      active
      mentorActive
    }
  }
`;

export const UPDATE_MESSAGING_TOKEN = gql`
  mutation Mutation($newToken: String, $oldToken: String) {
    updateMessagingToken(newToken: $newToken, oldToken: $oldToken)
  }
`;

export const DELETE_USER_PROFILE = gql`
  mutation DeleteUserProfile($reason: String) {
    deleteUserProfile(reason: $reason)
  }
`;
