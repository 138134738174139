import React from "react";
import Layout from "../../../common/components/Layout";
import ProfileDetailContainer from "../components/ProfileDetailContainer";

function ProfileDetailScreen() {
  return (
    <Layout>
      <ProfileDetailContainer />
    </Layout>
  );
}

export default ProfileDetailScreen;
