import React from "react";
import { Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import useProfileTabToggleOverlay from "./ProfileTabToggleOverlay.hook";

function ProfileTabToggleOverlay({
  data,
  isTutoring,
  isTutor = false,
  closeOverlay,
}) {
  const {
    operations: { onToggleActiveMentor, onToggleActiveTutor },
  } = useProfileTabToggleOverlay(data);

  const title = isTutoring
    ? data?.tutor?.active
      ? `강사 활동을\n 잠깐 중단할까요?`
      : `강사 활동을\n 시작할까요?`
    : (isTutor && data?.tutor?.mentorActive) || data?.mentor?.active
    ? `멘토 활동을\n 잠깐 중단할까요?`
    : `멘토 활동을\n 시작할까요?`;

  const subtitle = isTutoring
    ? data?.tutor?.active
      ? "활동을 중단하면"
      : "활동을 시작하면"
    : (isTutor && data?.tutor?.mentorActive) || data?.mentor?.active
    ? "활동을 중단하면"
    : "활동을 시작하면";

  const bodyTexts = isTutoring
    ? data?.tutor?.active
      ? [
          "프로필이 다른 사람에게 보이지 않습니다",
          "강의 요청이 들어오지 않습니다",
          "언제든 활동을 다시 시작할 수 있습니다",
        ]
      : [
          "프로필이 다른 사람에게 나타납니다",
          "강의 요청이 들어옵니다",
          "언제든 활동을 다시 활동을 쉴 수 있습니다",
        ]
    : (isTutor && data?.tutor?.mentorActive) || data?.mentor?.active
    ? [
        "프로필이 다른 사람에게 보이지 않습니다",
        "멘토링 요청이 들어오지 않습니다",
        "언제든 활동을 다시 시작할 수 있습니다",
      ]
    : [
        "프로필이 다른 사람에게 나타납니다",
        "멘토링 요청이 들어옵니다",
        "언제든 활동을 다시 활동을 쉴 수 있습니다",
      ];

  const yesText = isTutoring
    ? data?.tutor?.active
      ? "네, 중단할게요"
      : "네, 활동할게요"
    : isTutor
    ? data?.tutor?.mentorActive
      ? "네, 중단할게요"
      : "네, 활동할게요"
    : data?.mentor?.active
    ? "네, 중단할게요"
    : "네, 활동할게요";

  const noText = isTutoring
    ? data?.tutor?.active
      ? "아니요, 활동합니다"
      : "아니요, 중단할게요"
    : isTutor
    ? data?.tutor?.mentorActive
      ? "아니요, 활동합니다"
      : "아니요, 중단할게요"
    : data?.mentor?.active
    ? "아니요, 활동합니다"
    : "아니요, 중단할게요";
  return (
    <View style={styles.wrapper}>
      <View style={styles.inner}>
        <View style={styles.innerTop}>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.subTitle}>{subtitle}</Text>
          <View style={styles.body}>
            {bodyTexts?.map(text => (
              <View key={text}>
                <Text style={styles.bodyText}>
                  <Text style={{ fontFamily: "Pretendard-Bold" }}>· </Text>
                  {text}
                </Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.bottomWrapper}>
          <Pressable
            style={styles.bottomButton}
            onPress={() => {
              if (isTutoring) {
                onToggleActiveTutor(!data?.tutor?.active);
              } else if (isTutor) {
                onToggleActiveMentor(!data?.tutor?.mentorActive);
              } else {
                onToggleActiveMentor(!data?.mentor?.active);
              }
              closeOverlay();
            }}
          >
            <Text style={styles.bottomButtonText}>{yesText}</Text>
          </Pressable>
          <Pressable
            style={[styles.bottomButton, { borderLeftWidth: 1 }]}
            onPress={closeOverlay}
          >
            <Text style={styles.bottomButtonText}>{noText}</Text>
          </Pressable>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: "rgba(0, 31, 37, 0.5)",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: theme.spacing.default,
    zIndex: 1000,
  },
  inner: {
    width: "100%",
    backgroundColor: theme.color.white,
    borderRadius: 8,
  },
  innerTop: {
    paddingVertical: theme.spacing.section,
    flexDirection: "column",
    alignItems: "flex-start",
    paddingHorizontal: theme.spacing.large,
  },

  title: {
    fontSize: theme.font.subtitle2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.section,
    lineHeight: 30,
    textAlign: "center",
    width: "100%",
  },
  subTitle: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    lineHeight: 21,
    textAlign: "left",
  },
  body: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  bodyText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    lineHeight: 21,
    textAlign: "left",
  },

  bottomWrapper: {
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderColor: theme.color.gray4,
  },
  bottomButton: {
    borderColor: theme.color.gray4,
    paddingVertical: theme.spacing.default,
    flex: 1,
  },
  bottomButtonText: {
    fontSize: theme.font.body2,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
    textAlign: "center",
  },
});

export default ProfileTabToggleOverlay;
