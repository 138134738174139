import React, { useMemo } from "react";
import {
  Image,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { theme } from "../../../../utilities/theme";
import { commonStyles } from "../../../common/styles/commonStyles";
import useUpdateProfileContainer from "./UpdateProfileContainer.hook";
import AvatarInput from "../../../common/components/AvatarInput";
import InputWithLabel from "../../../common/components/InputWithLabel";
import DateInput from "../../../common/components/DateInput";
import GenderInput from "../../../common/components/GenderInput";
import arrowIcon from "../../../../assets/images/arrow_right_line_black.png";
import TextButton from "../../../common/components/TextButton";
import AreaSelector from "../../../common/components/AreaSelector";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import CustomKeyBoardAvoidingView from "../../../common/components/CustomKeyBoardAvoidingView";

function UpdateProfileContainer() {
  const { navigate } = useNavigation();

  const {
    models: {
      loading,
      updateLoading,
      state,
      avatar,
      fields,
      mentorFields,
      tutorFields,
      requiredValues,
    },
    operations: {
      onBasicInputChange,
      onBirthChange,
      onGenderChange,
      onMentorInputChange,
      onTutorInputChange,
      onAreaSelect,
      onUsernameFocus,
      onUsernameBlur,
      onNameFocus,
      onNameBlur,
      onIntroductionFocus,
      onIntroductionBlur,
      onExperienceFocus,
      onExperienceBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useUpdateProfileContainer();

  if (loading) return <View />;

  const {
    username,
    name,
    dateOfBirth,
    gender,
    phone,
    introduction,
    mentor,
    tutor,
    usernameError,
    nameError,
    introductionError,
    experienceError,
  } = state;

  const memoizedUsernameInput = useMemo(
    () => (
      <InputWithLabel
        label="닉네임"
        isRequired={requiredValues.includes("username")}
        placeholder="2자 이상"
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={username}
        errorMessage={usernameError}
        onChangeText={text => onBasicInputChange(text, "username")}
        onFocus={onUsernameFocus}
        onBlur={onUsernameBlur}
      />
    ),
    [
      username,
      usernameError,
      onBasicInputChange,
      onUsernameFocus,
      onUsernameBlur,
    ],
  );

  const memoizedNameInput = useMemo(
    () => (
      <InputWithLabel
        label="이름"
        isRequired={requiredValues.includes("name")}
        placeholder="실명을 입력해 주세요"
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={name}
        errorMessage={nameError}
        onChangeText={text => onBasicInputChange(text, "name")}
        onFocus={onNameFocus}
        onBlur={onNameBlur}
      />
    ),
    [name, nameError, onBasicInputChange, onNameFocus, onNameBlur],
  );

  const memoizedBirthInput = useMemo(
    () => (
      <DateInput
        label="생년월일"
        isRequired={requiredValues.includes("dateOfBirth")}
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={dateOfBirth}
        onConfirm={date => onBirthChange(date)}
      />
    ),
    [dateOfBirth, onBirthChange],
  );

  const memoizedGenderInput = useMemo(
    () => (
      <GenderInput
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="성별"
        gender={gender}
        onGenderSelect={gender => onGenderChange(gender)}
      />
    ),
    [gender, onGenderChange],
  );

  const memoizedPhoneInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="휴대폰번호"
        placeholder="‘-’ 빼고 숫자만"
        inputMode="numeric"
        maxLength={11}
        value={phone}
        onChangeText={text => onBasicInputChange(text, "phone")}
      />
    ),
    [phone, onBasicInputChange],
  );

  const memoizedIntroductionInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        inputStyle={{
          height: 80,
          textAlignVertical: "top",
        }}
        isRequired={requiredValues.includes("introduction")}
        label="소개(20자 이내)"
        placeholder="자신에 대한 소개글을 적어주세요"
        multiline
        value={introduction}
        errorMessage={introductionError}
        onChangeText={text => onBasicInputChange(text, "introduction")}
        onFocus={onIntroductionFocus}
        onBlur={onIntroductionBlur}
      />
    ),
    [
      introduction,
      introductionError,
      onBasicInputChange,
      onIntroductionFocus,
      onIntroductionBlur,
    ],
  );

  const memoizedFields = useMemo(
    () => (
      <View>
        <View style={styles.fieldsLabelRow}>
          <Text style={styles.label}>
            관심분야<Text style={styles.requiredSign}>*</Text>
          </Text>
          <Pressable
            style={styles.changeFieldButton}
            onPress={() => navigate(SCREENS.UPDATE_FIELDS, { type: "user" })}
          >
            <Text style={styles.changeFieldText}>변경하기</Text>
            <Image style={styles.changeFieldIcon} source={arrowIcon} />
          </Pressable>
        </View>

        <View style={styles.fieldsWrapper}>
          {fields?.map(field => (
            <View key={field?.id} style={styles.field}>
              <Text style={styles.fieldText}>{field?.name}</Text>
            </View>
          ))}
        </View>
      </View>
    ),
    [fields],
  );

  const memoizedMentorIntroductionInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        inputStyle={{
          height: 80,
          textAlignVertical: "top",
        }}
        isRequired={requiredValues.includes("introduction")}
        label="소개(20자 이내)"
        placeholder="자신에 대한 소개글을 적어주세요"
        multiline
        value={mentor?.introduction}
        errorMessage={introductionError}
        onChangeText={text => onMentorInputChange(text, "introduction")}
        onFocus={onIntroductionFocus}
        onBlur={onIntroductionBlur}
      />
    ),
    [
      mentor?.introduction,
      introductionError,
      onMentorInputChange,
      onIntroductionFocus,
      onIntroductionBlur,
    ],
  );

  const memoizedMentorExperienceInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        inputStyle={{
          height: 104,
          textAlignVertical: "top",
        }}
        isRequired={requiredValues.includes("experience")}
        label="경력"
        placeholder="경력을 적어주세요"
        multiline
        value={mentor?.experience}
        errorMessage={experienceError}
        onChangeText={text => onMentorInputChange(text, "experience")}
        onFocus={onExperienceFocus}
        onBlur={onExperienceBlur}
      />
    ),
    [
      mentor?.experience,
      experienceError,
      onMentorInputChange,
      onExperienceFocus,
      onIntroductionBlur,
    ],
  );

  const memoizedMentorFields = useMemo(
    () => (
      <View>
        <View style={styles.fieldsLabelRow}>
          <Text style={styles.label}>
            전문분야<Text style={styles.requiredSign}>*</Text>
          </Text>
          <Pressable
            style={styles.changeFieldButton}
            onPress={() => navigate(SCREENS.UPDATE_FIELDS, { type: "mentor" })}
          >
            <Text style={styles.changeFieldText}>변경하기</Text>
            <Image style={styles.changeFieldIcon} source={arrowIcon} />
          </Pressable>
        </View>

        <View style={styles.fieldsWrapper}>
          {mentorFields?.map(field => (
            <View key={field?.id} style={styles.field}>
              <Text style={styles.fieldText}>{field?.name}</Text>
            </View>
          ))}
        </View>
      </View>
    ),
    [mentorFields],
  );

  const memoizedTutorAreaSelector = useMemo(
    () => (
      <AreaSelector
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="출강가능지역"
        isRequired={requiredValues.includes("areas")}
        value={tutor?.areaIds[0]}
        onConfirm={onAreaSelect}
      />
    ),
    [tutor?.areaIds, onAreaSelect],
  );

  const memoizedTutorIntroductionInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        inputStyle={{
          height: 80,
          textAlignVertical: "top",
        }}
        isRequired={requiredValues.includes("introduction")}
        label="소개(20자 이내)"
        placeholder="자신에 대한 소개글을 적어주세요"
        multiline
        value={tutor?.introduction}
        errorMessage={introductionError}
        onChangeText={text => onTutorInputChange(text, "introduction")}
        onFocus={onIntroductionFocus}
        onBlur={onIntroductionBlur}
      />
    ),
    [
      tutor?.introduction,
      introductionError,
      onTutorInputChange,
      onIntroductionFocus,
      onIntroductionBlur,
    ],
  );

  const memoizedTutorExperienceInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        inputStyle={{
          height: 104,
          textAlignVertical: "top",
        }}
        isRequired={requiredValues.includes("experience")}
        label="경력"
        placeholder="경력을 적어주세요"
        multiline
        value={tutor?.experience}
        errorMessage={experienceError}
        onChangeText={text => onTutorInputChange(text, "experience")}
        onFocus={onExperienceFocus}
        onBlur={onExperienceBlur}
      />
    ),
    [
      tutor?.experience,
      experienceError,
      onTutorInputChange,
      onExperienceFocus,
      onIntroductionBlur,
    ],
  );

  const memoizedTutorFields = useMemo(
    () => (
      <View>
        <View style={styles.fieldsLabelRow}>
          <Text style={styles.label}>
            전문분야<Text style={styles.requiredSign}>*</Text>
          </Text>
          <Pressable
            style={styles.changeFieldButton}
            onPress={() => navigate(SCREENS.UPDATE_FIELDS, { type: "tutor" })}
          >
            <Text style={styles.changeFieldText}>변경하기</Text>
            <Image style={styles.changeFieldIcon} source={arrowIcon} />
          </Pressable>
        </View>

        <View style={styles.fieldsWrapper}>
          {tutorFields?.map(field => (
            <View key={field?.id} style={styles.field}>
              <Text style={styles.fieldText}>{field?.name}</Text>
            </View>
          ))}
        </View>
      </View>
    ),
    [tutorFields],
  );

  return (
    <CustomKeyBoardAvoidingView>
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyle}
      >
        {/* BASIC PROFILE  */}
        <View
          style={[
            commonStyles.formTitleRow,
            { paddingHorizontal: 0, marginBottom: theme.spacing.default },
          ]}
        >
          <Text style={commonStyles.formTitle}>기본 정보</Text>
          <Text style={commonStyles.formTitleRowRequiredText}>*필수입력</Text>
        </View>
        <View style={{ marginBottom: 28 }}>
          <AvatarInput avatar={avatar} showBottomTag />
        </View>
        {memoizedUsernameInput}
        {memoizedNameInput}
        {memoizedBirthInput}
        {memoizedGenderInput}
        {memoizedPhoneInput}
        {!tutor?.id && !mentor?.id && memoizedIntroductionInput}
        {memoizedFields}

        {/* MENTOR PROFILE */}
        {!!mentor?.id && (
          <>
            <View
              style={[
                commonStyles.formTitleRow,
                { paddingHorizontal: 0, marginBottom: theme.spacing.default },
              ]}
            >
              <Text style={commonStyles.formTitle}>이타멘토 정보</Text>
            </View>
            {memoizedMentorIntroductionInput}
            {memoizedMentorExperienceInput}
            {memoizedMentorFields}
          </>
        )}

        {/* TUTOR PROFILE */}
        {!!tutor?.id && (
          <>
            <View
              style={[
                commonStyles.formTitleRow,
                { paddingHorizontal: 0, marginBottom: theme.spacing.default },
              ]}
            >
              <Text style={commonStyles.formTitle}>이타강사 정보</Text>
            </View>
            {memoizedTutorAreaSelector}
            {memoizedTutorIntroductionInput}
            {memoizedTutorExperienceInput}
            {memoizedTutorFields}
          </>
        )}

        <TextButton
          text={updateLoading ? "저장 중" : "저장"}
          disabled={isSubmitDisabled()}
          onPress={onSubmit}
        />
      </ScrollView>
    </CustomKeyBoardAvoidingView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingBottom: theme.spacing.middle,
    paddingHorizontal: theme.spacing.regular,
  },
  fieldsLabelRow: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing.normal,
  },
  label: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    marginBottom: theme.spacing.xxSmall2,
  },
  requiredSign: {
    color: theme.color.error,
  },
  changeFieldButton: {
    flexDirection: "row",
    alignItems: "center",
  },
  changeFieldText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
  },
  changeFieldIcon: {
    width: 20,
    height: 20,
  },
  fieldsWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    marginHorizontal: -theme.spacing.small,
    marginBottom: theme.spacing.regular,
  },
  field: {
    margin: theme.spacing.xxSmall2,
    flexBasis: "47%",
    borderWidth: 1,
    borderColor: theme.color.gray4,
    backgroundColor: theme.color.white,
    paddingVertical: theme.spacing.default,
    paddingHorizontal: theme.spacing.xxSmall2,
    borderRadius: 8,
  },
  fieldText: {
    fontWeight: "normal",
    textAlign: "center",
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.gray2,
  },
});

export default UpdateProfileContainer;
