import { gql } from "@apollo/client";

export const GET_HOME_RECOMMENDS = gql`
  query GetHomeRecommends {
    getHomeRecommends {
      mentors {
        field {
          id
          name
        }
        mentors {
          id
          introduction
          experience
          verified
          active
          fields {
            id
            name
          }
          user {
            id
            username
            avatar {
              id
              uri
            }
          }
          createdAt
          updatedAt
        }
      }
      classes {
        field {
          id
          name
        }
        classes {
          id
          tutor {
            id
            introduction
            verified
            active
            areas {
              id
              name
            }
            user {
              id
              username
              avatar {
                id
                uri
              }
            }
          }
          title
          description
          duration
          coverPhoto {
            id
            uri
          }
          fields {
            id
            name
            sort
          }
          active
          createdAt
          updatedAt
        }
      }
      tutors {
        field {
          id
          name
        }
        tutors {
          id
          introduction
          experience
          verified
          active
          fields {
            id
            name
            sort
          }
          areas {
            id
            name
          }
          user {
            id
            username
            avatar {
              id
              uri
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
