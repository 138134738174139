import React from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import useRequestRuleContainer from "./RequestRuleContainer.hook";
import { useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import TextButton from "../../../common/components/TextButton";

function RequestRuleContainer() {
  const { name } = useRoute();

  const {
    models: { loading, data },
    operations: { onAgreePress },
  } = useRequestRuleContainer();

  const processes = ["규칙 동의", "프로필 작성", "프로필 심사"];
  const isTutor = name === SCREENS.REQUEST_TUTOR_RULE;

  if (loading) return <View />;

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainerStyle}
      >
        <View style={styles.processWrapper}>
          <Text style={styles.processTitle}>
            {isTutor ? "이타강사" : "이타멘토"} 가입 과정
          </Text>
          <View style={styles.processRow}>
            {processes.map((process, index) => (
              <View key={process} style={styles.process}>
                <View style={styles.processNumber}>
                  <Text style={styles.processNumberText}>{index + 1}</Text>
                </View>
                <Text style={styles.processText}>{process}</Text>
              </View>
            ))}
          </View>
        </View>
        <View style={styles.rulesWrapper}>
          <Text style={styles.rulesTitle}>
            {`이타주의자들의\n${
              isTutor ? "이타강사" : "이타멘토"
            } 규칙을 확인해주세요`}
          </Text>
          <Text style={styles.rulesText}>
            {isTutor ? data?.tutorRule : data?.mentorRule}
          </Text>
        </View>
      </ScrollView>
      <View style={styles.footer}>
        <TextButton text="위의 내용에 동의합니다" onPress={onAgreePress} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.default,
    paddingHorizontal: theme.spacing.regular,
  },
  processWrapper: {
    padding: theme.spacing.default,
    backgroundColor: theme.color.grayBox,
    borderRadius: 8,
    marginBottom: theme.spacing.default,
  },
  processTitle: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: "#000000",
    textAlign: "center",
    marginBottom: theme.spacing.default,
  },
  processRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  process: {
    marginHorizontal: theme.spacing.small,
    flexDirection: "row",
    alignItems: "center",
  },
  processNumber: {
    backgroundColor: theme.color.secondary,
    width: 16,
    height: 16,
    borderRadius: 16 / 2,
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing.xxSmall2,
  },
  processNumberText: {
    fontSize: theme.font.caption1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.white,
  },
  processText: {
    fontSize: theme.font.body2,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },

  rulesWrapper: {
    padding: theme.spacing.middle,
    backgroundColor: theme.color.white,
    borderRadius: 8,
  },
  rulesTitle: {
    fontSize: theme.font.subtitle1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
    marginBottom: theme.spacing.middle,
    lineHeight: 36,
  },
  rulesText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    lineHeight: 21,
  },
  footer: {
    flexDirection: "row",
    alignItems: "center",
    padding: theme.spacing.default,
    backgroundColor: theme.color.white,
  },
});

export default RequestRuleContainer;
