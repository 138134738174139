import React, { useMemo } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { commonStyles } from "../../../common/styles/commonStyles";
import { theme } from "../../../../utilities/theme";
import useRequestIntroductionContainer from "./RequestIntroductionContainer.hook";
import InputWithLabel from "../../../common/components/InputWithLabel";
import TextButton from "../../../common/components/TextButton";
import { useRoute } from "@react-navigation/native";
import AreaSelector from "../../../common/components/AreaSelector";
import { SCREENS } from "../../../../routes/screens";
import CustomKeyBoardAvoidingView from "../../../common/components/CustomKeyBoardAvoidingView";

function RequestIntroductionContainer() {
  const { name } = useRoute();

  const {
    models: { loading, state },

    operations: {
      onAreaSelect,
      onInputChange,
      onInputFocus,
      onInputBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useRequestIntroductionContainer();

  const {
    introduction,
    experience,
    areaIds,
    introductionError,
    experienceError,
  } = state;

  const memoizedAreaSelector = useMemo(
    () => (
      <AreaSelector
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="강의가능지역"
        isRequired
        value={areaIds?.[0]}
        onConfirm={onAreaSelect}
      />
    ),
    [areaIds, onAreaSelect],
  );

  const memoizedIntroductionInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        inputStyle={{
          height: 80,
          textAlignVertical: "top",
        }}
        label="소개 (20자 이내)"
        isRequired
        placeholder="자신에 대한 소개글을 적어주세요"
        multiline
        value={introduction}
        errorMessage={introductionError}
        onChangeText={text => onInputChange(text, "introduction")}
        onFocus={() => onInputFocus("introduction")}
        onBlur={() => onInputBlur("introduction")}
      />
    ),
    [introduction, introductionError, onInputChange, onInputFocus, onInputBlur],
  );

  const memoizedExperienceInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.section }}
        inputStyle={{
          height: 104,
          textAlignVertical: "top",
        }}
        label="경력"
        isRequired
        placeholder="경력을 적어주세요"
        multiline
        value={experience}
        errorMessage={experienceError}
        onChangeText={text => onInputChange(text, "experience")}
        onFocus={() => onInputFocus("experience")}
        onBlur={() => onInputBlur("experience")}
      />
    ),
    [experience, experienceError, onInputChange, onInputFocus, onInputBlur],
  );

  if (loading) return <View />;

  return (
    <View style={styles.wrapper}>
      <View style={commonStyles.formTitleRow}>
        <Text style={commonStyles.formTitle}>
          {name === SCREENS.REQUEST_MENTOR_INTRODUCTION
            ? "이타멘토"
            : "이타강사"}{" "}
          소개
        </Text>
        <Text style={commonStyles.formTitleRowRequiredText}>*필수입력</Text>
      </View>
      <CustomKeyBoardAvoidingView>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.contentContainerStyle}
        >
          {name === SCREENS.REQUEST_TUTOR_INTRODUCTION
            ? memoizedAreaSelector
            : null}
          {memoizedIntroductionInput}
          {memoizedExperienceInput}
          <TextButton
            text="다음"
            disabled={isSubmitDisabled()}
            onPress={onSubmit}
          />
        </ScrollView>
      </CustomKeyBoardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },

  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.default,
    paddingHorizontal: theme.spacing.regular,
  },
});

export default RequestIntroductionContainer;
