import React, { useMemo } from "react";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import { commonStyles } from "../../../common/styles/commonStyles";
import useRequestBasicInfoContainer from "./RequestBasicInfoContainer.hook";
import AvatarInput from "../../../common/components/AvatarInput";
import InputWithLabel from "../../../common/components/InputWithLabel";
import GenderInput from "../../../common/components/GenderInput";
import DateInput from "../../../common/components/DateInput";
import TextButton from "../../../common/components/TextButton";
import CustomKeyBoardAvoidingView from "../../../common/components/CustomKeyBoardAvoidingView";

function RequestBasicInfoContainer() {
  const {
    models: { state, avatar },
    operations: {
      onInputChange,
      onBirthChange,
      onGenderChange,
      onUsernameFocus,
      onUsernameBlur,
      onNameFocus,
      onNameBlur,
      isSubmitDisabled,
      onSubmit,
    },
  } = useRequestBasicInfoContainer();

  const {
    username,
    name,
    dateOfBirth,
    gender,
    phone,
    usernameError,
    nameError,
  } = state;

  const memoizedAvatarInput = useMemo(
    () => <AvatarInput avatar={avatar} />,
    [avatar],
  );

  const memoizedUsernameInput = useMemo(
    () => (
      <InputWithLabel
        label="닉네임"
        isRequired
        placeholder="2자 이상"
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={username}
        errorMessage={usernameError}
        onChangeText={text => onInputChange(text, "username")}
        onFocus={onUsernameFocus}
        onBlur={onUsernameBlur}
      />
    ),
    [username, usernameError, onInputChange, onUsernameFocus, onUsernameBlur],
  );

  const memoizedNameInput = useMemo(
    () => (
      <InputWithLabel
        label="이름"
        isRequired
        placeholder="실명"
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={name}
        errorMessage={nameError}
        onChangeText={text => onInputChange(text, "name")}
        onFocus={onNameFocus}
        onBlur={onNameBlur}
      />
    ),
    [name, nameError, onInputChange, onNameFocus, onNameBlur],
  );

  const memoizedBirthInput = useMemo(
    () => (
      <DateInput
        label="생년월일"
        isRequired
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        value={dateOfBirth}
        onConfirm={date => onBirthChange(date)}
      />
    ),
    [dateOfBirth, onBirthChange],
  );

  const memoizedGenderInput = useMemo(
    () => (
      <GenderInput
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="성별"
        gender={gender}
        onGenderSelect={gender => onGenderChange(gender)}
      />
    ),
    [gender, onGenderChange],
  );

  const memoizedPhoneInput = useMemo(
    () => (
      <InputWithLabel
        wrapperStyle={{ marginBottom: theme.spacing.middle }}
        label="휴대폰번호"
        placeholder="‘-’ 빼고 숫자만"
        inputMode="numeric"
        maxLength={11}
        value={phone}
        onChangeText={text => onInputChange(text, "phone")}
      />
    ),
    [phone, onInputChange],
  );

  return (
    <View style={styles.wrapper}>
      <View style={commonStyles.formTitleRow}>
        <Text style={commonStyles.formTitle}>기본 정보</Text>
        <Text style={commonStyles.formTitleRowRequiredText}>*필수입력</Text>
      </View>
      <CustomKeyBoardAvoidingView>
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.contentContainerStyle}
        >
          {memoizedAvatarInput}
          {memoizedUsernameInput}
          {memoizedNameInput}
          {memoizedBirthInput}
          {memoizedGenderInput}
          {memoizedPhoneInput}
          <TextButton
            text="다음"
            disabled={isSubmitDisabled()}
            onPress={onSubmit}
          />
        </ScrollView>
      </CustomKeyBoardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
  },

  scrollView: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.default,
    paddingHorizontal: theme.spacing.regular,
  },
});

export default RequestBasicInfoContainer;
