import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import ManageAccountContainer from "../container/ManageAccountContainer";

function ManageAccountScreen() {
  return (
    <Layout>
      <NavigationBar title="계정 정보" />
      <ManageAccountContainer />
    </Layout>
  );
}

export default ManageAccountScreen;
