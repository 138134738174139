import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useEffect, useRef, useState } from "react";
import imageCompression from "browser-image-compression";

import {
  GET_CLASS,
  UPDATE_CLASS_BASIC,
} from "../../../../../lib/apollo/gql/class";
import {
  IMAGE_COMPRESSION_OPTIONS,
  MOBILE_IMAGE_COMPRESSION_OPTIONS,
  isWeb,
} from "../../../../../utilities/constants";
import { ReactNativeFile } from "apollo-upload-client";
import { SCREENS } from "../../../../../routes/screens";

function useUpdateClassBasicContainer() {
  const { navigate } = useNavigation();
  const { params } = useRoute();

  const coverPhotoInputRef = useRef();

  const [state, setState] = useState({
    id: "",
    title: "",
    description: "",
    duration: "",
    coverPhoto: null,
    coverPhotoState: null,
  });

  const [getClass, { loading }] = useLazyQuery(GET_CLASS, {
    onCompleted: ({ getClass }) => {
      if (getClass) {
        const { id, title, description, duration, coverPhoto } = getClass;

        setState(prev => ({
          ...prev,
          id,
          title,
          description,
          duration,
          coverPhoto,
        }));
      }
    },
    fetchPolicy: "network-only",
  });

  const [updateClassBasic, { loading: updateLoading }] = useMutation(
    UPDATE_CLASS_BASIC,
    {
      onCompleted: ({ updateClassBasic }) => {
        navigate(SCREENS.UPDATE_CLASS_FIELDS, {
          classId: updateClassBasic?.id,
        });
      },
    },
  );

  useEffect(() => {
    if (params?.classId !== "new") {
      getClass({
        variables: {
          classId: params?.classId,
        },
      });
    }
  }, [params?.classId]);

  function onInputChange(value, key) {
    if (key === "duration" && isNaN(Number(value))) {
      return;
    }

    setState(prev => ({
      ...prev,
      [key]: value,
    }));
  }

  async function onCoverPhotoPress() {
    if (isWeb) {
      coverPhotoInputRef?.current?.click();
    } else {
      const { openPicker } = await import("react-native-image-crop-picker");
      const { path, mime } = await openPicker(MOBILE_IMAGE_COMPRESSION_OPTIONS);
      const name = path.substring(path.lastIndexOf("/") + 1);
      const file = { uri: path, name, type: mime };

      setState(prev => ({
        ...prev,
        coverPhotoState: file,
      }));
    }
  }

  function onCoverPhotoChange(e) {
    const { files } = e.target;

    if (isWeb) {
      setState(prev => ({
        ...prev,
        coverPhotoState: files[0],
      }));
    }
  }

  function isSubmitDisabled() {
    const { title, description, duration, coverPhotoState, coverPhoto } = state;
    const isValidTitle = !!title;
    const isValidDescription = !!description;
    const isValidDuration = duration > 0;
    const isValidCoverPhoto = coverPhoto || coverPhotoState;

    return (
      !(
        isValidTitle &&
        isValidDescription &&
        isValidDuration &&
        isValidCoverPhoto
      ) || updateLoading
    );
  }

  async function onSubmit() {
    const { title, description, duration, coverPhotoState, coverPhoto } = state;

    const classId = params?.classId === "new" ? null : params?.classId;

    let coverPhotoInput = null;

    if (coverPhotoState) {
      if (isWeb) {
        const compressedFile = await imageCompression(
          coverPhotoState,
          IMAGE_COMPRESSION_OPTIONS,
        );
        coverPhotoInput = {
          file: compressedFile,
        };
      } else {
        coverPhotoInput = {
          file: new ReactNativeFile(coverPhotoState),
        };
      }
    }

    updateClassBasic({
      variables: {
        classInput: {
          id: classId,
          title,
          description,
          duration: Number(duration),
          coverPhotoInput,
        },
      },
    });
  }

  return {
    refs: {
      coverPhotoInputRef,
    },
    models: {
      state,
      loading,
    },
    operations: {
      onInputChange,
      onCoverPhotoPress,
      onCoverPhotoChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useUpdateClassBasicContainer;
