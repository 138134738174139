import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { theme } from "../../../utilities/theme";

function TopProgressBar({ progress, total }) {
  const totalBars = Array.from(new Array(total));

  return (
    <View style={styles.wrapper}>
      {totalBars.map((item, index) => (
        <View
          key={index}
          style={[
            styles.bar,
            {
              backgroundColor:
                index < progress ? theme.color.primary : theme.color.border,
            },
          ]}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    paddingHorizontal: theme.spacing.regular - theme.spacing.xxSmall2,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  bar: {
    flex: 1,
    height: 7,
    borderRadius: 2,
    marginHorizontal: theme.spacing.xxSmall,
  },
});

export default TopProgressBar;
