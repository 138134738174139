import React from "react";
import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../utilities/theme";
import { SCREENS } from "../../../routes/screens";
import { useNavigation, useRoute } from "@react-navigation/native";
import { BOTTOM_TABS } from "../../../utilities/constants";

function Layout({ children, isTabScreen = false, isDarkBackground = false }) {
  const { navigate } = useNavigation();

  const { name } = useRoute();

  return (
    <View
      style={{
        flex: 1,
        position: "relative",
        backgroundColor:
          isTabScreen || isDarkBackground
            ? theme.color.background
            : theme.color.white,
      }}
    >
      {children}
      {isTabScreen && (
        <View style={styles.bottomRow}>
          {BOTTOM_TABS.map(tab => {
            const isActive = tab.screen === name;
            return (
              <Pressable
                key={tab?.screen}
                style={styles.bottomButton}
                onPress={() => navigate(tab?.screen)}
              >
                <Image
                  style={styles.bottomIcon}
                  source={isActive ? tab.activeIcon : tab.icon}
                />
                <Text
                  style={[
                    styles.bottomText,
                    {
                      color: isActive ? theme.color.primary : theme.color.gray3,
                    },
                  ]}
                >
                  {tab?.name}
                </Text>
              </Pressable>
            );
          })}
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  bottomRow: {
    flexDirection: "row",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: theme.color.border,
  },
  bottomButton: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: theme.spacing.small,
  },
  bottomIcon: {
    width: 24,
    height: 24,
  },
  bottomText: {
    fontFamily: "Pretendard-Regular",
    fontSize: 10,
  },
});

export default Layout;
