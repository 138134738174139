import React from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { theme } from "../../../../utilities/theme";
import logo from "../../../../assets/images/logo_color.png";
import TextButton from "../../../common/components/TextButton";
import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import { useAuthContext } from "../../../providers/AuthProvider";

function HomeTopBar() {
  const { navigate } = useNavigation();
  const { name } = useRoute();

  const { user } = useAuthContext();

  return (
    <View style={styles.wrapper}>
      <Image style={styles.logo} source={logo} resizeMode="contain" />
      {!user?.mentor && (
        <TextButton
          text="이타멘토 지원하기"
          buttonStyle={styles.button}
          textStyle={styles.buttonText}
          onPress={() => {
            navigate(SCREENS.REQUEST_MENTOR, { entryScreenName: name });
          }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: theme.spacing.default,
    paddingVertical: theme.spacing.normal,
  },
  logo: {
    width: 117,
    height: 32,
  },
  button: {
    width: "auto",
    paddingVertical: theme.spacing.normal / 2,
    paddingHorizontal: theme.spacing.normal,
    borderRadius: 4,
  },
  buttonText: {
    fontSize: theme.font.caption1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.white,
  },
});

export default HomeTopBar;
