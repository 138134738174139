import React from "react";
import { Image, StyleSheet } from "react-native";

function TabBarIcon({ focused, source }) {
  const key = focused ? "activeIcon" : "icon";

  return <Image style={styles.bottomIcon} source={source[key]} />;
}

const styles = StyleSheet.create({
  bottomIcon: {
    width: 24,
    height: 24,
  },
});

export default TabBarIcon;
