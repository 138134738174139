import React from "react";
import Layout from "../../../common/components/Layout";
import HomeContainer from "../container/HomeContainer";

function HomeScreen() {
  return (
    <Layout isTabScreen>
      <HomeContainer />
    </Layout>
  );
}

export default HomeScreen;
