import { useQuery } from "@apollo/client";
import { GET_MY_CLASSES } from "../../../../lib/apollo/gql/class";
import { useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import { useState } from "react";

function useManageClassContainer() {
  const { name } = useRoute();
  const isActiveScreen = name === SCREENS.MANAGE_CLASS_ACTIVE;

  const [fetchingMore, setFetchMore] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const { loading, data, refetch, fetchMore } = useQuery(GET_MY_CLASSES, {
    variables: {
      active: isActiveScreen,
      first: 3,
    },
  });

  function onEndReached() {
    const { hasNextPage, endCursor } = data?.getMyClasses?.pageInfo;

    if (hasNextPage && !fetchingMore) {
      fetchMore(true);

      fetchMore({
        variables: {
          active: isActiveScreen,
          after: endCursor,
          first: 3,
        },
      }).finally(() => {
        setFetchMore(false);
      });
    }
  }

  function onRefresh() {
    setRefreshing(true);

    refetch()
      .catch(e => console.log(e))
      .finally(() => {
        setRefreshing(false);
      });
  }

  return {
    models: {
      isActiveScreen,
      refreshing,
      fetchingMore,
      data: data?.getMyClasses?.edges,
      loading,
    },
    operations: {
      onEndReached,
      onRefresh,
    },
  };
}

export default useManageClassContainer;
