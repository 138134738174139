import React, { useEffect } from "react";
import { FlatList, RefreshControl, StyleSheet, Text, View } from "react-native";
import { useAuthContext } from "../../../providers/AuthProvider";
import { theme } from "../../../../utilities/theme";
import TextButton from "../../../common/components/TextButton";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import useBoardTabContainer from "./BoardTabContainer.hook";
import BoardCard from "../components/BoardCard";

function BoardTabContainer({ boardid, type }) {
  const { navigate } = useNavigation();
  const { user } = useAuthContext();

  const {
    models: { loading, data, refreshing, fetchingMore, error },
    operations: { onEndReached, onRefresh },
  } = useBoardTabContainer(boardid);


  useEffect(() => {
    //console.log(data);
    onRefresh();
  }, [data]);

  useEffect(() => {
    console.log("error", error);
  }, [error]);

  return (
    <View style={{ flex: 1 }}>
      {!loading &&
        (data?.length > 0 ? (
          <FlatList
            style={styles.flatList}
            data={data}
            keyExtractor={item => item.id}
            renderItem={({ item }) => (
              <BoardCard data={item} type={type} isUser={item?.user?.id === user?.id} onPress={() => {}}/>
            )}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            onEndReached={onEndReached}
            onEndReachedThreshold={1}
          />
        ) : (
          <View style={styles.noneWrapper}>
            {error ? (
              <>
                <Text style={styles.noneText}>
                  {`👀\해당 커뮤니티 권한이 없습니다!`}
                </Text></>
            ) : (
              <>
                <Text style={styles.noneText}>
                  {`👀\n작성된 글이 없습니다. 새로 글을 작성해주세요!`}
                </Text>
                {
                  <TextButton
                    text="글작성"
                    buttonStyle={styles.noneButton}
                    onPress={() => {
                      navigate(SCREENS.BOARD_WRITE, { boardId: boardid, type: type });
                    }}
                  />}</>
            )}
          </View>
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  flatList: {
    flex: 1,
  },

  noneWrapper: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 100,
  },
  noneText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.black,
    textAlign: "center",
    marginBottom: theme.spacing.middle,
  },
  noneButton: {
    width: 160,
  },
});

export default BoardTabContainer;
