import React, { useEffect } from "react";
import useChatTabContainer from "./ChatTabContainer.hook";
import { FlatList, RefreshControl, StyleSheet, Text, View } from "react-native";
import ChatCard from "../components/ChatCard";
import { useAuthContext } from "../../../providers/AuthProvider";
import { theme } from "../../../../utilities/theme";
import TextButton from "../../../common/components/TextButton";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

function ChatTabContainer() {
  const { navigate } = useNavigation();
  const { user } = useAuthContext();

  const {
    models: { loading, data, refreshing, fetchingMore },
    operations: { onEndReached, onRefresh },
  } = useChatTabContainer();

  useEffect(() => {
    //console.log(data);
    onRefresh();
  }, [data]);

  return (
    <View style={{ flex: 1 }}>
      {!loading &&
        (data?.length > 0 ? (
          <FlatList
            style={styles.flatList}
            data={data}
            keyExtractor={item => item.id}
            renderItem={({ item }) => (
              <ChatCard data={item} isUser={item?.user?.id === user?.id} />
            )}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            onEndReached={onEndReached}
            onEndReachedThreshold={1}
          />
        ) : (
          <View style={styles.noneWrapper}>
            <Text style={styles.noneText}>
              {`👀\n멘토/강사/강의\n찾아보세요!`}
            </Text>
            <TextButton
              text="홈으로"
              buttonStyle={styles.noneButton}
              onPress={() => {
                navigate(SCREENS.HOME);
              }}
            />
          </View>
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  flatList: {
    flex: 1,
  },

  noneWrapper: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 100,
  },
  noneText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.black,
    textAlign: "center",
    marginBottom: theme.spacing.middle,
  },
  noneButton: {
    width: 160,
  },
});

export default ChatTabContainer;
