import { navigate } from "../../../routes/RoutesContainer";
import { SCREENS } from "../../../routes/screens";

import { PUSH_CATEGORIES } from "./pushConstants";

/**
 * Handle push notification
 *
 * by push notification category,
 * it navigates to the screen with data id
 *
 * content => each content screen
 *
 * sale => sold history
 *
 * buy => bought history
 *
 * note => each note
 *
 *
 * @param {object} remoteMessage
 * @param {object} userProfile
 */
export async function pushNotificationHandler(remoteMessage) {
  const { data } = remoteMessage;

  const { category } = data;

  switch (category) {
    case PUSH_CATEGORIES.CHAT: {
      const { dataId } = data;

      navigate(SCREENS.CHAT, { chatId: dataId });

      break;
    }

    default: {
      break;
    }
  }
}
