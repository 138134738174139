import React from "react";
import {
  Image,
  ImageBackground,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { theme } from "../../../utilities/theme";
import TextButton from "./TextButton";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../routes/screens";
import useLargeThumbnail from "./LargeThumbnail.hook";
import defaultCoverPhoto from "../../../assets/images/default_cover_photo.png";
import defaultProfilePhoto from "../../../assets/images/default_profile_image.png";

function LargeThumbnail({ data, isMine = false }) {
  const { navigate } = useNavigation();

  const {
    operations: { onEditPress, onToggleClassActivePress },
  } = useLargeThumbnail();

  const WrapperComponent = isMine ? View : Pressable;

  const wrapperProps = isMine
    ? {}
    : {
        onPress: () => navigate(SCREENS.CLASS_DETAIL, { classId: data?.id }),
      };

  return (
    <WrapperComponent style={styles.wrapper} {...wrapperProps}>
      <View style={styles.top}>
        <Image
          style={styles.coverPhoto}
          source={
            data?.coverPhoto
              ? { uri: data?.coverPhoto?.uri }
              : defaultCoverPhoto
          }
        />
        <View style={styles.topTag}>
          <Text style={styles.topTagText}>
            📍{data?.tutor?.areas[0]?.name} ⏳ {data?.duration}시간
          </Text>
        </View>
      </View>
      <View style={styles.bottom}>
        <View style={styles.bottomTop}>
          <Text style={styles.title}>{data?.title}</Text>
          <Text style={styles.fieldsText}>
            {data?.fields?.map(field => "#" + field?.name + " ")}
          </Text>
        </View>
        <View style={styles.bottomBottom}>
          <View style={styles.bottomBottomRow}>
            <Image
              style={styles.avatar}
              source={
                data?.tutor?.user?.avatar
                  ? { uri: data?.tutor?.user?.avatar?.uri }
                  : defaultProfilePhoto
              }
              resizeMode="cover"
            />
            <View>
              <Text style={styles.usertypeText}>Tutor</Text>
              <Text style={styles.username}>{data?.tutor?.user?.username}</Text>
            </View>
          </View>
          {!isMine && (
            <View>
              <TextButton
                text="강의 신청"
                buttonStyle={styles.button}
                textStyle={styles.buttonText}
              />
            </View>
          )}
        </View>
        {isMine && (
          <View style={styles.mineButtons}>
            <Pressable
              style={styles.mineButton}
              onPress={() => onEditPress(data?.id)}
            >
              <Text style={styles.mineButtonText}>강의 수정</Text>
            </Pressable>
            <Pressable
              style={styles.mineButton}
              onPress={() => onToggleClassActivePress(data?.id, !data?.active)}
            >
              <Text style={styles.mineButtonText}>
                강의 {data?.active ? "중단" : "재개"}
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    </WrapperComponent>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    marginVertical: theme.spacing.xxSmall2,
    borderRadius: 8,
    backgroundColor: theme.color.white,
    ...Platform.select({
      web: {
        shadowColor: "rgba(0,0,0,0.1)",
        shadowOffset: {
          width: 1,
          height: 1,
        },
        shadowRadius: 2,
        shadowOpacity: 1,
      },
      ios: {
        shadowColor: "rgba(0,0,0,0.1)",
        shadowOffset: {
          width: 1,
          height: 1,
        },
        shadowRadius: 2,
        shadowOpacity: 1,
      },
      android: {
        shadowColor: "rgba(0,0,0,0.5)",
        elevation: 3,
      },
    }),
  },
  top: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    width: "100%",
    position: "relative",
    borderWidth: 1,
    borderColor: theme.color.gray5,
  },
  coverPhoto: {
    width: "100%",
    height: 109,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  topTag: {
    position: "absolute",
    top: theme.spacing.normal,
    left: theme.spacing.normal,
    backgroundColor: "rgba(14, 14, 14, 0.7)",
    borderRadius: 27,
    paddingVertical: theme.spacing.xxSmall2,
    paddingHorizontal: theme.spacing.small,
  },
  topTagText: {
    color: theme.color.white,
    fontSize: theme.font.caption1,
    fontFamily: "Pretendard-Bold",
  },
  bottom: {
    padding: theme.spacing.default,
    borderWidth: 1,
    borderColor: theme.color.gray5,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  bottomTop: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: theme.color.border,
  },
  title: {
    fontSize: theme.font.body1,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
    marginBottom: theme.spacing.small,
  },
  fieldsText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.gray1,
    marginBottom: theme.spacing.middle,
  },
  bottomBottom: {
    width: "100%",
    flexDirection: "row",
    paddingTop: theme.spacing.default,
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottomBottomRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    width: 42,
    height: 42,
    borderRadius: 42 / 2,
    marginRight: theme.spacing.small,
  },
  usertypeText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption2,
    color: theme.color.black,
  },
  username: {
    fontSize: theme.font.body1,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
  },
  button: {
    flex: 0,
    marginVertical: theme.spacing.xxSmall2,
    paddingVertical: theme.spacing.small,
    width: 65,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.color.gray5,
    backgroundColor: theme.color.white,
  },
  buttonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
    fontWeight: "normal",
  },

  mineButtons: {
    flexDirection: "row",
    marginTop: theme.spacing.default,
    alignItems: "center",
  },
  mineButton: {
    flex: 1,
    marginHorizontal: theme.spacing.xxSmall,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: theme.color.gray5,
    paddingVertical: theme.spacing.small,
  },
  mineButtonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.caption1,
    color: theme.color.black,
    fontWeight: "normal",
    textAlign: "center",
  },
});

export default LargeThumbnail;
