import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useMemo } from "react";
import { theme } from "../../../../utilities/theme";
import { Image, StyleSheet, Text, View } from "react-native";
import defaultProfile from "../../../../assets/images/default_profile_image.png";
import { differenceInMinutes, format, startOfMinute } from "date-fns";
import { MESSAGE_STATUS } from "../../../../utilities/constants";
import { ko } from "date-fns/locale";

const Message = ({
  onResend,
  onDelete,
  currentMessage,
  previousMessage,
  nextMessage,
  myId,
  chatId,
}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const isMyMessage = myId === currentMessage?.author?.id;
  const styles = useMemo(() => getContainerStyles(isMyMessage), [isMyMessage]);
  const avatar = useMemo(() => {
    return currentMessage?.author?.avatar
      ? { uri: currentMessage?.author?.avatar?.uri }
      : defaultProfile;
  }, [currentMessage?.author?.profilePicture, currentMessage?.role]);
  const status = currentMessage?.status; //If message is not sending inprogress or failed, then this is null

  const showDaySeparator = useMemo(
    () =>
      !status &&
      (!isSameDay(currentMessage, previousMessage) ||
        !previousMessage ||
        Object.keys(previousMessage).length === 0),
    [currentMessage?.createdAt, previousMessage?.createdAt],
  );

  const showAvatar = useMemo(
    () =>
      !isMyMessage &&
      (!isSameUser(currentMessage, previousMessage) ||
        !isSameDay(currentMessage, previousMessage)),
    [
      currentMessage?.author?.id,
      previousMessage?.author?.id,
      currentMessage?.createdAt,
      previousMessage?.createdAt,
    ],
  );

  const showDeleteResendButton = status === MESSAGE_STATUS.SENDING_FAILED;

  const showTime = useMemo(() => {
    const sameUser = isSameUser(currentMessage, nextMessage);
    if (!nextMessage || !nextMessage.createdAt || !sameUser) {
      return true;
    }

    const currentMessageTime = startOfMinute(
      new Date(currentMessage.createdAt),
    );
    const nextMessageTime = startOfMinute(new Date(nextMessage.createdAt));
    const isNewMinute =
      Math.abs(
        differenceInMinutes(
          new Date(currentMessageTime),
          new Date(nextMessageTime),
        ),
      ) >= 1;
    return isNewMinute || !sameUser;
  }, [
    currentMessage?.author?.id,
    nextMessage?.author?.id,
    currentMessage?.createdAt,
    nextMessage?.createdAt,
  ]);

  const renderDaySeparator = useMemo(() => {
    return _renderDaySeparator(showDaySeparator, currentMessage);
  }, [showDaySeparator]);

  const renderAvatar = useMemo(
    () => _renderAvatar(showAvatar, avatar),
    [showAvatar],
  );

  const renderMessageBubble = useMemo(
    () => _renderMessageBubble(currentMessage, myId, chatId),
    [currentMessage],
  );

  const renderReadMarkAndTime = useMemo(() => {
    return _renderReadMarkAndTime(
      currentMessage?.id,
      styles,
      status,
      // currentMessage?.readUnreadIcon,
      showTime,
      currentMessage?.createdAt,
    );
  }, [status, showTime, currentMessage?.readUnreadIcon]);

  // const renderMessageStatusIcon = useMemo(
  //   () => _renderMessageStatusIcon(status),
  //   [status],
  // );

  // const renderDeleteAndResendButton = useMemo(
  //   () =>
  //     _renderDeleteAndResendButton(
  //       styles,
  //       currentMessage,
  //       showDeleteResendButton,
  //       onDelete,
  //       onResend,
  //     ),
  //   [showDeleteResendButton],
  // );

  return (
    <View>
      {renderDaySeparator}
      <View style={styles.container}>
        {renderAvatar}
        {renderMessageBubble}
        {renderReadMarkAndTime}
        {/* {renderMessageStatusIcon} */}
      </View>
      {/* {renderDeleteAndResendButton} */}
    </View>
  );
};

function _renderDaySeparator(showDaySeparator, currentMessage) {
  return showDaySeparator ? (
    <View style={{ marginBottom: theme.spacing.default, alignItems: "center" }}>
      <Text
        style={{
          fontFamily: "Pretendard-Regular",
          fontSize: theme.font.caption2,
          color: theme.color.gray1,
        }}
      >
        {format(new Date(currentMessage?.createdAt), "yy년 M월 d일", {
          locale: ko,
        })}
      </Text>
    </View>
  ) : (
    <View />
  );
}
/**
 * Avatar - only shows when:
 *  1. It is not my message and
 *  2. current message is neither same user nor same day compared to previous message
 * @param {*} showAvatar
 */
function _renderAvatar(showAvatar, avatar) {
  return showAvatar ? (
    <Image
      style={{
        position: "absolute",
        left: 0,
        width: 36,
        height: 36,
        borderRadius: 36 / 2,
        marginRight: theme.spacing.small,
      }}
      source={avatar}
    />
  ) : (
    <View />
  );
}

function _renderMessageBubble(currentMessage, myId, chatId) {
  const isMyMessage = myId === currentMessage.author.id;

  if (isMyMessage) {
    return (
      <View
        style={{
          borderRadius: 8,
          borderBottomRightRadius: 0,
          backgroundColor: theme.color.tertiaryOpacity,
          borderWidth: 1,
          borderColor: theme.color.grayBox,
          paddingHorizontal: theme.spacing.default,
          paddingVertical: theme.spacing.normal,
          width: "auto",
          maxWidth: "50%",
        }}
      >
        <Text
          style={{
            fontFamily: "Pretendard-Regular",
            fontSize: theme.font.body2,
            color: theme.color.black,
            lineHeight: 21,
          }}
        >
          {currentMessage?.text}
        </Text>
      </View>
    );
  } else {
    return (
      <View
        style={{
          borderRadius: 8,
          borderBottomLeftRadius: 0,
          backgroundColor: theme.color.white,
          borderWidth: 1,
          borderColor: theme.color.grayBox,
          paddingHorizontal: theme.spacing.default,
          paddingVertical: theme.spacing.normal,
          width: "auto",
          maxWidth: "50%",
        }}
      >
        <Text
          style={{
            fontFamily: "Pretendard-Regular",
            fontSize: theme.font.body2,
            color: theme.color.black,
            lineHeight: 21,
          }}
        >
          {currentMessage?.text}
        </Text>
      </View>
    );
  }
}

function _renderReadMarkAndTime(
  id,
  styles,
  status,
  // readUnreadIcon,
  showTime,
  time,
) {
  // if (readUnreadIcon) {
  //   readUndreadTracker.add(id);
  // } else {
  //   readUndreadTracker.delete(id);
  // }
  return (
    // Read mark and Time container
    <View style={styles.readAndTimeContainer}>
      {/*　Read/Unread */}
      {/* {readUnreadIcon && <Image source={readUnreadIcon} />} */}

      {/* Time */}
      {!status && showTime && (
        <Text
          style={{
            marginHorizontal: theme.spacing.small,
            fontFamily: "Pretendard-Regular",
            fontSize: theme.font.caption2,
            color: theme.color.gray3,
          }}
        >
          {format(new Date(time), "aa h:m", { locale: ko })}
        </Text>
      )}
    </View>
  );
}

// function _renderMessageStatusIcon(status) {
//   return status ? <MessageStatusIcon status={status} /> : <View />;
// }

// function _renderDeleteAndResendButton(
//   styles,
//   currentMessage,
//   showDeleteResendButton,
//   onDelete,
//   onResend,
// ) {
//   return showDeleteResendButton ? (
//     <View style={styles.deleteResendButtonsContainer}>
//       <ImageButton
//         imageStyle={styles.deleteButton}
//         image={require("../../../../../../assets/chat_delete.png")}
//         onPress={() => {
//           onDelete(currentMessage);
//         }}
//       />
//       <ImageButton
//         imageStyle={styles.resendButton}
//         image={require("../../../../../../assets/chat_resend.png")}
//         onPress={() => {
//           onResend(currentMessage);
//         }}
//       />
//     </View>
//   ) : (
//     <View />
//   );
// }

// Tracks read unread message by message id globally
const readUndreadTracker = new Set([]);
export default React.memo(Message, (prevProps, nextProps) => {
  // Component should update
  const {
    previousMessage: prevPreviousMessage,
    currentMessage: prevCurrentMessage,
    nextMessage: prevNextMessage,
  } = prevProps;
  const {
    previousMessage: nextPreviousMessage,
    currentMessage: nextCurrentMessage,
    nextMessage: nextNextMessage,
  } = nextProps;

  const hasNewNextMessage = !prevNextMessage?.id && nextNextMessage?.id;
  const hasNewPrevMessage = !prevPreviousMessage?.id && nextPreviousMessage?.id;

  const hasNextMessageStatusHasChanged =
    prevNextMessage &&
    nextNextMessage &&
    prevNextMessage?.status !== nextNextMessage?.status;
  const hasIncompleteMessageStatusChanged =
    prevCurrentMessage?.status !== nextCurrentMessage?.status;

  const hasUnreadStatusChange = readUndreadTracker.has(prevCurrentMessage?.id);

  const skipRerender = !(
    hasNewNextMessage ||
    hasNewPrevMessage ||
    hasNextMessageStatusHasChanged ||
    hasIncompleteMessageStatusChanged ||
    hasUnreadStatusChange
  );

  return skipRerender;
});

const getContainerStyles = isMyMessage => {
  const containerPaddingHorizontal = isMyMessage
    ? theme.spacing.small
    : theme.spacing.default + 30; //30 is profile image diameter

  return StyleSheet.create({
    container: {
      flexDirection: isMyMessage ? "row-reverse" : "row",
      width: "100%",
      justifyContent: "flex-start",
      marginVertical: theme.spacing.xxSmall2,
      paddingLeft: containerPaddingHorizontal, // 30 is diameter of profile image
    },

    readAndTimeContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginHorizontal: theme.spacing.xxSmall2,
    },

    deleteResendButtonsContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      marginTop: theme.spacing.xxSmall2,
      marginBottom: theme.spacing.xxSmall,
      marginRight: theme.spacing.small,
    },

    deleteButton: {
      width: 41,
      height: 18,
    },

    resendButton: {
      marginLeft: theme.spacing.xxSmall2,
      width: 51,
      height: 18,
    },
  });
};

export const isSameDay = (msg1, msg2) => {
  if (!msg1?.createdAt || !msg2?.createdAt) {
    return false;
  }
  return (
    format(new Date(msg1.createdAt), "yyyy-MM-dd") ===
    format(new Date(msg2.createdAt), "yyyy-MM-dd")
  );
};

export const isSameUser = (msg1, msg2) => {
  return msg1?.author?.id === msg2?.author?.id;
};
