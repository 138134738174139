import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  FlatList,
  KeyboardAvoidingView,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  View,
} from "react-native";
import { theme } from "../../../../utilities/theme";
import useChatContainer from "./ChatContainer.hook";
import ChatMessage from "../components/ChatMessage";
import ChatDeleteOverlay from "../components/ChatDeleteOverlay";
import NavigationBar from "../../../common/components/NavigationBar";
import { SCREENS } from "../../../../routes/screens";
import { useNavigation } from "@react-navigation/native";
import CustomKeyBoardAvoidingView from "../../../common/components/CustomKeyBoardAvoidingView";
import TextButton from "../../../common/components/TextButton";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_CHAT } from "../../../../lib/apollo/gql/chat";
import useChatTabContainer from "../../chatTab/container/ChatTabContainer.hook";

function ChatContainer() {
  const { canGoBack, navigate, goBack } = useNavigation();

  const {
    loading,
    errorMessages,
    errorChat,
    messages,
    chat,
    messageMutator,
    myId,
    text,
    setText,
  } = useChatContainer();

  const {
    operations: { onEndReached, onRefresh },
  } = useChatTabContainer();

  const isDeleted =
    myId === chat?.tutor?.id ? chat?.user?.deletedAt : chat?.tutor?.deletedAt;

  const contentOffsetRef = useRef(0);
  const flatListRef = useRef(null);
  const prevMessages = useRef(messages);

  const [showChatDeleteOverlay, setshowChatDeleteOverlay] = useState(false);

  // If new message has sent by user, scroll down
  useEffect(() => {
    if (prevMessages?.current?.length > 0 && messages?.length > 0) {
      if (
        prevMessages.current[0].id !== messages[0].id &&
        prevMessages.current[0].role === chat.role
      ) {
        flatListRef.current.scrollToOffset({
          offset: 0,
          animated: false,
        });
      }
    }
    prevMessages.current = messages;
  }, [messages]);

  const onResend = useCallback(message => {
    messageMutator.resendFailedMessage(message);
  }, []);

  const onDelete = useCallback(message => {
    messageMutator.deleteMessage(message);
  }, []);

  const onScroll = ({ nativeEvent }) => {
    const { contentOffset } = nativeEvent;
    // Is used to scroll up when keyboard shows. Check Keyboard will show/hide listner.
    contentOffsetRef.current = contentOffset.y;
  };

  function onChatTopReached(onEndReachProp) {
    const { distanceFromEnd } = onEndReachProp;
    // to prevent infinite fetching
    if (distanceFromEnd >= 0) {
      messageMutator.getPreviousMessages();
    }
  }

  function renderMessage({ item: currentMessage, index }) {
    // messages array is inverted
    const nextMessage = index !== 0 ? messages[index - 1] : undefined;
    const previousMessage =
      index + 1 < messages.length ? messages[index + 1] : undefined;

    return (
      <ChatMessage
        onResend={onResend}
        onDelete={onDelete}
        currentMessage={currentMessage}
        previousMessage={previousMessage}
        nextMessage={nextMessage}
        myId={myId}
        chatId={chat.id}
      />
    );
  }

  const [deleteChat] = useMutation(DELETE_CHAT, {
    onCompleted: ({ ok }) => {
      onRefresh();
      canGoBack() ? goBack() : navigate(SCREENS.CHAT_TAB)
    },
  })

  return (
    <View style={styles.wrapper}>
      <NavigationBar
        title={
          loading
            ? " "
            : myId === chat?.tutor?.id
            ? !chat?.user?.deletedAt
              ? chat?.user?.username || ""
              : "탈퇴한 유저"
            : chat?.tutor?.deletedAt
            ? "탈퇴한 유저"
            : chat?.tutor?.tutor?.id
            ? (chat?.tutor?.username || "") + "강사"
            : (chat?.tutor?.username || "") + "멘토"
        }
        onBackButtonPress={() =>
          canGoBack() ? goBack() : navigate(SCREENS.CHAT_TAB)
        }
        comp={(
          <TextButton
            text="나가기"
            buttonStyle={[styles.button2, {backgroundColor: 'black'}]}
            textStyle={[styles.buttonText, { color: 'white' }]}
            onPress={() => {
              setshowChatDeleteOverlay(true);
            }}
          />
        )}
      />
      <CustomKeyBoardAvoidingView
        footer={
          <View style={styles.footer}>
            <View style={styles.footerInner}>
              <TextInput
                style={styles.input}
                value={text}
                disabled={isDeleted}
                multiline
                placeholder={
                  isDeleted
                    ? "사용할 수 없습니다"
                    : "메시지를 이곳에 입력하세요"
                }
                placeholderTextColor={theme.color.gray3}
                onChangeText={value => setText(value)}
              />
              <Pressable
                style={[styles.button, { opacity: isDeleted ? 0.5 : 1 }]}
                disabled={isDeleted}
                onPress={() => {
                  if (text) {
                    messageMutator.createAndSendInProgressMessage(myId, text);
                    setText("");
                  }
                }}
              >
                <Text style={styles.buttonText}>전송</Text>
              </Pressable>
            </View>
          </View>
        }
      >
        <FlatList
          style={styles.flatList}
          contentContainerStyle={styles.contentContainerStyle}
          data={messages}
          keyExtractor={message => message.id}
          renderItem={renderMessage}
          ref={flatListRef}
          scrollEventThrottle={5}
          onScroll={onScroll}
          inverted={true}
          removeClippedSubviews={true}
          windowSize={19}
          onEndReached={onChatTopReached}
          onEndReachedThreshold={0.2}
          bounces={false}
        />
      </CustomKeyBoardAvoidingView>

      {showChatDeleteOverlay && (
        <ChatDeleteOverlay
          closeOverlay={(result) => {
            setshowChatDeleteOverlay(false);
            if (result) {
              deleteChat({ variables: { chatId: chat.id } });
            }
          }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    backgroundColor: theme.color.background,
  },
  flatList: {
    flex: 1,
  },
  contentContainerStyle: {
    padding: theme.spacing.default,
  },
  footer: {
    padding: theme.spacing.default,
  },
  footerInner: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: theme.color.border,
    padding: theme.spacing.default,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.color.white,
  },
  input: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.black,
    paddingRight: theme.spacing.small,
    flex: 1,
    verticalAlign: "middle",
  },
  button: {
    paddingHorizontal: theme.spacing.small,
    paddingVertical: theme.spacing.xxSmall2,
    borderRadius: 4,
    backgroundColor: theme.color.primary,
  },
  buttonText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body2,
    color: theme.color.white,
    lineHeight: 21,
  },
  button2: {
    width: "auto",
    position:"absolute",
    right: theme.spacing.default,
    paddingHorizontal: theme.spacing.small,
    paddingVertical: theme.spacing.xxSmall2,
    borderRadius: 4,
    backgroundColor: theme.color.primary,
  },
});

export default ChatContainer;
