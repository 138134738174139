import { gql } from "@apollo/client";
import { firebaseGetCurrentUser } from "../firebase/auth/firebaseAuth";

export const typeDefs = gql`
  enum MessageStatus {
    SENDING_IN_PROGRESS
    SENDING_FAILED
  }

  type IncompleteMessage {
    id: ID!
    text: String
    status: String
    media: Media
    author: User
  }

  extend type Chat {
    role: UserRole!
  }

  extend type MessageConnection {
    incompleteMessages: [IncompleteMessage]
  }

  extend type Query {
    chat(chatId: ID!): Chat!
  }
`;

export const resolvers = {
  MessageConnection: {
    incompleteMessages: parent => {
      return parent?.incompleteMessages || [];
    },
  },

  Chat: {
    role: async chat => {
      try {
        return await getRole(chat);
      } catch (err) {
        //console.log("Error in chat role resolvers get role: ", chat, err);
        return "UNKNOWN";
      }
    },
  },
};

const getRole = async parent => {
  const id = firebaseGetCurrentUser().uid;

  const {
    tutor: { id: tutorId },
    user: { id: userId },
  } = parent;

  if (id === tutorId && id !== userId) {
    return "TUTOR";
  } else if (id === userId && id !== tutorId) {
    return "USER";
  } else {
    return "UNKNOWN";
  }
};
