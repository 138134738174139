const { gql } = require("@apollo/client");

export const SEARCH_MENTORS = gql`
  query SearchMentors($fieldIds: [ID], $after: String, $first: Int) {
    searchMentors(fieldIds: $fieldIds, after: $after, first: $first) {
      pageInfo {
        hasNextPage
        endCursor
      }
      fields {
        id
        name
        sort
      }
      edges {
        id
        introduction
        experience
        verified
        active
        fields {
          id
          name
        }
        user {
          id
          username
          avatar {
            id
            uri
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SEARCH_TUTORS = gql`
  query SearchTutors(
    $fieldIds: [ID]
    $areaId: ID
    $first: Int
    $after: String
  ) {
    searchTutors(
      fieldIds: $fieldIds
      areaId: $areaId
      first: $first
      after: $after
    ) {
      fields {
        id
        name
        sort
      }
      area {
        id
        name
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        id
        introduction
        experience
        verified
        active
        fields {
          id
          name
          sort
        }
        areas {
          id
          name
        }
        user {
          id
          username
          avatar {
            id
            uri
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const SERARCH_CLASSES = gql`
  query SearchClasses(
    $fieldIds: [ID]
    $areaId: ID
    $first: Int
    $after: String
  ) {
    searchClasses(
      fieldIds: $fieldIds
      areaId: $areaId
      first: $first
      after: $after
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      area {
        id
        name
        sort
      }
      fields {
        id
        name
        sort
      }
      edges {
        id
        tutor {
          id
          introduction
          verified
          active
          areas {
            id
            name
          }
          user {
            id
            username
            avatar {
              id
              uri
            }
          }
        }
        title
        description
        duration
        coverPhoto {
          id
          uri
        }
        fields {
          id
          name
          sort
        }
        createdAt
        updatedAt
      }
    }
  }
`;
