import React from "react";
import { FlatList, RefreshControl, StyleSheet, Text, View } from "react-native";
import SearchTabTopTab from "../components/SearchTabTopTab";
import SearchConditionOverlay from "../components/SearchConditionOverlay";
import TabTitle from "../../../common/components/TabTitle";
import useSearchTabContainer from "./SearchTabContainer.hook";
import { SCREENS } from "../../../../routes/screens";
import { theme } from "../../../../utilities/theme";
import { useRoute } from "@react-navigation/native";
import LargeThumbnail from "../../../common/components/LargeThumbnail";
import SmallThumbnail from "../../../common/components/SmallThumbnail";

function SearchTabContainer() {
  const { name } = useRoute();
  const {
    models: {
      showSearchOverlay,
      fieldIds,
      area,
      loading,
      data,
      refreshing,
      fetchingMore,
    },
    operations: {
      toggleShowSearchOverlay,
      onFieldsChange,
      onAreaChange,
      onEndReached,
      onRefresh,
    },
  } = useSearchTabContainer();

  function renderItem({ item }) {
    switch (name) {
      case SCREENS.SEARCH_CLASS: {
        return <LargeThumbnail wrapperStyle={{ flex: 1 }} data={item} />;
      }
      default: {
        return (
          <SmallThumbnail
            wrapperStyle={{ flex: 1 / 2 }}
            data={item}
            isTutor={name === SCREENS.SEARCH_TUTOR}
          />
        );
      }
    }
  }

  return (
    <View style={styles.wrapper}>
      <TabTitle title="검색" />
      <SearchTabTopTab toggleShowSearchOverlay={toggleShowSearchOverlay} />
      {showSearchOverlay && (
        <SearchConditionOverlay
          fieldIds={fieldIds}
          area={area}
          onFieldsChange={onFieldsChange}
          onAreaChange={onAreaChange}
          closeSearchOverlay={() => toggleShowSearchOverlay(false)}
        />
      )}
      {!loading &&
        (data?.length > 0 ? (
          <FlatList
            style={styles.flatList}
            contentContainerStyle={styles.contentContainerStyle}
            data={data}
            keyExtractor={item => item.id}
            numColumns={name !== SCREENS.SEARCH_CLASS ? 2 : 1}
            renderItem={renderItem}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
            onEndReached={onEndReached}
            onEndReachedThreshold={1}
          />
        ) : (
          <View style={styles.noneWrapper}>
            <Text
              style={styles.noneText}
            >{`검색 결과가 없어요 💦\n다른 검색 조건으로 찾아보세요`}</Text>
          </View>
        ))}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    position: "relative",
  },
  flatList: {
    flex: 1,
    marginTop: theme.spacing.default,
  },
  contentContainerStyle: {
    paddingVertical: theme.spacing.default,
    paddingHorizontal: theme.spacing.normal,
    paddingBottom: theme.spacing.section,
  },

  noneWrapper: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  noneText: {
    fontFamily: "Pretendard-Regular",
    fontSize: theme.font.body1,
    color: theme.color.black,
    textAlign: "center",
  },
});

export default SearchTabContainer;
