import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useNavigation } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import ChatContainer from "../container/ChatContainer";

function ChatScreen() {
  return (
    <Layout>
      <ChatContainer />
    </Layout>
  );
}

export default ChatScreen;
