import { format, isValid } from "date-fns";
import React, { createContext, useContext, useState } from "react";
import { firebaseSignUp } from "../../../../lib/firebase/auth/firebaseAuth";
import { useMutation } from "@apollo/client";
import {
  GET_MY_USER_PROFILE,
  UPDATE_MY_USER_PROFILE,
} from "../../../../lib/apollo/gql/user";
import { isWeb } from "../../../../utilities/constants";
import { popToRoot } from "../../../../routes/RoutesContainer";

const defaultValue = {
  email: "",
  password: "",
  confirmPassword: "",
  username: "",
  name: "",
  usernameError: "",
  dateOfBirth: format(new Date(), "yyyy-MM-dd"),
  gender: null,
  phone: "",
  introduction: "",
  selectedFieldIds: new Set(),
  updateLoading: false,
  onInputChange: () => null,
  onBirthChange: date => null,
  onGenderChange: gender => null,
  onFieldSelect: fieldId => null,
  onSignUpPress: () => null,
};

const SignUpContext = createContext(defaultValue);

function SignUpProvider({ children }) {
  const [state, setState] = useState(defaultValue);

  const [updateLoading, setUpdateLoading] = useState(false);

  const [updateMyUserProfile] = useMutation(UPDATE_MY_USER_PROFILE, {
    refetchQueries: () => [
      {
        query: GET_MY_USER_PROFILE,
        fetchPolicy: "network-only",
      },
    ],
  });

  function onInputChange(value, key) {
    if (key === "phone" && isNaN(Number(value))) {
      return;
    }

    if (
      key === "introduction" &&
      value.replaceAll(/\s/g, "").trim().length > 20
    ) {
      return;
    }

    setState(prev => ({
      ...prev,
      [key]: value,
    }));
  }

  function onBirthChange(date) {
    if (isWeb) {
      setState(prev => ({
        ...prev,
        dateOfBirth: date,
      }));
    } else {
      setState(prev => ({
        ...prev,
        dateOfBirth: format(new Date(date), "yyyy-MM-dd"),
      }));
    }
  }

  function onGenderChange(gender) {
    setState(prev => ({
      ...prev,
      gender,
    }));
  }

  function onFieldSelect(fieldId) {
    const { selectedFieldIds } = state;

    const currentSelectedFieldIds = new Set(selectedFieldIds);

    if (currentSelectedFieldIds.has(fieldId)) {
      currentSelectedFieldIds.delete(fieldId);
      setState(prev => ({
        ...prev,
        selectedFieldIds: currentSelectedFieldIds,
      }));
    } else {
      currentSelectedFieldIds.add(fieldId);
      setState(prev => ({
        ...prev,
        selectedFieldIds: currentSelectedFieldIds,
      }));
    }
  }

  async function onSignUpPress() {
    try {
      setUpdateLoading(true);

      const {
        email,
        password,
        dateOfBirth,
        gender,
        username,
        name,
        phone,
        introduction,
        selectedFieldIds,
      } = state;
      await firebaseSignUp(state.email, state.password);

      await updateMyUserProfile({
        variables: {
          userInput: {
            email,
            username,
            name,
            gender,
            dateOfBirth,
            phone,
            introduction,
            fieldIds: Array.from(selectedFieldIds),
          },
        },
      });

      setUpdateLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <SignUpContext.Provider
      value={{
        ...state,
        updateLoading,
        onInputChange,
        onBirthChange,
        onGenderChange,
        onFieldSelect,
        onSignUpPress,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
}

export default SignUpProvider;

export function useSignUpContext() {
  return useContext(SignUpContext);
}
