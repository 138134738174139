import { useApolloClient, useMutation } from "@apollo/client";
import { DELETE_USER_PROFILE } from "../../../../lib/apollo/gql/user";
import { firebaseSignOut } from "../../../../lib/firebase/auth/firebaseAuth";
import { deleteMessagingTokenToServer } from "../../../../lib/firebase/pushNotification/pushNotificationHelper";
import { isWeb } from "../../../../utilities/constants";

const { useState } = require("react");

function useResignContainer() {
  const client = useApolloClient();

  const [reason, setReason] = useState("");

  const [deleteUserProfile, { loading }] = useMutation(DELETE_USER_PROFILE, {
    onCompleted: async ({ deleteUserProfile }) => {
      if (deleteUserProfile) {
        if (!isWeb) {
          await deleteMessagingTokenToServer(client);
        }
        await client.clearStore();
        await firebaseSignOut();
      }
    },
  });

  function onInputChange(text) {
    setReason(text);
  }

  function isSubmitDisabled() {
    return loading;
  }

  function onSubmit() {
    deleteUserProfile({
      variables: {
        reason,
      },
    });
  }

  return {
    models: {
      reason,
    },
    operations: {
      onInputChange,
      isSubmitDisabled,
      onSubmit,
    },
  };
}

export default useResignContainer;
