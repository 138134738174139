import imageCompression from "browser-image-compression";
import {
  IMAGE_COMPRESSION_OPTIONS,
  MOBILE_IMAGE_COMPRESSION_OPTIONS,
  isWeb,
} from "../../../utilities/constants";
import { ReactNativeFile } from "apollo-upload-client";

const { useMutation } = require("@apollo/client");
const { useState, useRef } = require("react");
const {
  UPDATEFILE
} = require("../../../lib/apollo/gql/board");

function useFileInput() {
  const inputRef = useRef();

  const [updateMyFile, { loading, error, data }] = useMutation(UPDATEFILE);

  async function onFilePress(setFiles) {
    if (isWeb) {
      inputRef?.current?.click();
    } else {
      const { openPicker } = await import("react-native-image-crop-picker");
      const { path, mime } = await openPicker(MOBILE_IMAGE_COMPRESSION_OPTIONS);
      const name = path.substring(path.lastIndexOf("/") + 1);
      const file = { uri: path, name, type: mime };


      const result = await updateMyFile({
        variables: {
          avatarInput: {
            fileInput: new ReactNativeFile(file),
            fileName: name,
          },
        },
      });

      setFiles([{
        fileId: result.data.uploadFile.fileId, mediaId: result.data.uploadFile.mediaId
        , fileName: name
      }])
    }
  }

  async function onInputChange(e, setFiles) {
    const { files } = e.target;

    const filePromises = Array.from(files).map((file) => {
      if (file.type.startsWith('image/')) {
        return imageCompression(file, IMAGE_COMPRESSION_OPTIONS);
      } else {
        return file;
      }
    });

    
    if (isWeb) {
      const processedFiles = await Promise.all(filePromises);

      const uploadPromises = processedFiles.map((processedFile) =>
        updateMyFile({
          variables: {
            fileInput: {
              file: processedFile,
            },
            fileName: processedFile.name,
          },
        })
      );

      const uploadResults = await Promise.all(uploadPromises);

      const uploadedFiles = uploadResults.map((result, index) => ({
        fileId: result.data.uploadFile.fileId,
        mediaId: result.data.uploadFile.mediaId,
        fileName: processedFiles[index].name,
      }));

      setFiles(uploadedFiles)

      console.log("FILEresult", uploadedFiles);
    }
  }

  return {
    refs: {
      inputRef,
    },

    operations: {
      onFilePress,
      onInputChange,
    },
  };
}

export default useFileInput;
