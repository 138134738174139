import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import { useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";
import PolicyContainer from "../container/PolicyContainer";

function PolicyScreen() {
  const { name } = useRoute();

  return (
    <Layout isDarkBackground>
      <NavigationBar
        title={
          name === SCREENS.PRIVACY_POLICY ? "개인정보 보호정책" : "이용약관"
        }
      />
      <PolicyContainer />
    </Layout>
  );
}

export default PolicyScreen;
