import { useNavigation, useRoute } from "@react-navigation/native";
import { SCREENS } from "../../../../routes/screens";

const { useQuery } = require("@apollo/client");
const { GET_RULES } = require("../../../../lib/apollo/gql/admin");

function useRequestRuleContainer() {
  const { navigate } = useNavigation();
  const { name, params } = useRoute();
  const { data, loading } = useQuery(GET_RULES);

  function onAgreePress() {
    if (name === SCREENS.REQUEST_MENTOR_RULE) {
      navigate(SCREENS.REQUEST_MENTOR_BASIC, { ...params });
    } else {
      navigate(SCREENS.REQUEST_TUTOR_BASIC, { ...params });
    }
  }

  return {
    models: {
      loading,
      data: data?.getRules,
    },
    operations: {
      onAgreePress,
    },
  };
}

export default useRequestRuleContainer;
