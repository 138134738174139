import React from "react";
import Layout from "../../../common/components/Layout";
import NavigationBar from "../../../common/components/NavigationBar";
import ChangePasswordContainer from "../container/ChangePasswordContainer";

function ChangePasswordScreen() {
  return (
    <Layout>
      <NavigationBar title="비밀번호 변경" />
      <ChangePasswordContainer />
    </Layout>
  );
}

export default ChangePasswordScreen;
