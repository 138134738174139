import React from "react";
import { StyleSheet, Text, Pressable, View } from "react-native";
import { theme } from "../../../../utilities/theme";

function SignUpQuizOption({
  wrapperStyle,
  option,
  selectedAnswer,
  index,
  answer,
  onSelect,
}) {
  const isWrong = index === selectedAnswer && selectedAnswer !== answer;
  const selected = index === selectedAnswer;
  return (
    <Pressable
      style={[styles.wrapper, wrapperStyle]}
      onPress={() => onSelect(index)}
    >
      <View
        style={[
          styles.numberWrapper,
          selected ? styles.selectedNumberWrapper : {},
          isWrong ? styles.wrongNumberWrapper : {},
        ]}
      >
        <Text style={styles.numberText}>{index}</Text>
      </View>
      <View
        style={[
          styles.optionWrapper,
          selected ? styles.selectedOptionWrapper : {},
          isWrong ? styles.wrongOptionWrapper : {},
        ]}
      >
        <Text style={styles.optionText}>{option}</Text>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
    position: "relative",
  },
  numberWrapper: {
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    borderWidth: 1,
    borderColor: theme.color.gray4,
    paddingVertical: theme.spacing.normal,
    paddingHorizontal: theme.spacing.default,
    backgroundColor: theme.color.primaryOpacity,
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  selectedNumberWrapper: {
    backgroundColor: theme.color.primary,
  },
  wrongNumberWrapper: {
    backgroundColor: theme.color.error,
  },
  numberText: {
    fontSize: theme.font.body1,
    fontFamily: "Pretendard-Bold",
    color: theme.color.black,
  },
  optionWrapper: {
    flex: 1,
    paddingVertical: theme.spacing.normal,
    paddingHorizontal: theme.spacing.small,
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: theme.color.gray4,
    backgroundColor: theme.color.white,
    borderLeftWidth: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  selectedOptionWrapper: {
    borderColor: theme.color.primary,
  },
  wrongOptionWrapper: {
    borderColor: theme.color.error,
  },
  optionText: {
    fontSize: theme.font.body1,
    lineHeight: 24,
    color: theme.color.black,
    fontFamily: "Pretendard-Bold",
  },
});

export default SignUpQuizOption;
